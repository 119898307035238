import './accountCreate.sass';
import { Divider, Button, AegroLogo, SocialButton, InputText, Tabs, Dropdown } from '../../components';
import { useState } from 'react';

function AccountCreate(props) {

    const opts_where = [
        { key: 'rural', label: 'Propriedade rural' },
        { key: 'office', label: 'Escritório Contábil' },
        { key: 'consult', label: 'Consultoria' },
        { key: 'coop', label: 'Cooperativa' },
        { key: 'company', label: 'Companhia ou grupo agrícola' },
        { key: 'resell', label: 'Revenda de insumos' },
        { key: 'research', label: 'Pesquisa ou estudos' },
    ];

    const opts_position = [
        { key: 'account', label: 'Contador(a)' },
        { key: 'consult', label: 'Consultor(a)' },
        { key: 'manager', label: 'Gerente' },
        { key: 'prod', label: 'Produtor(a)' },
        { key: 'research', label: 'Pesquisador(a) ou estudante' },
        { key: 'son', label: 'Filho(a) ou sucessor(a)' },
    ];

    const opts_cult = [
        { key: 'coffee', label: 'Cafeicultura' },
        { key: 'citrust', label: 'Citricultura' },
        { key: 'grains', label: 'Cultivo de grãos (arroz, feijão, milho, soja, trigo ...)' },
        { key: 'graincattle', label: 'Grãos e pecuária' },
        { key: 'fruit', label: 'Fruticultura e/ou horticultura' },
        { key: 'cattle', label: 'Pecuária' },
        { key: 'cane', label: 'Cultivo de cana' },
        { key: 'none', label: 'Nenhuma das anteriores' },
    ];

    const opts_area = [
        { key: '-100', label: 'Até 100 hectares' },
        { key: '101-500', label: 'Entre 101 a 500 hectares' },
        { key: '501-1500', label: 'Entre 501 a 1.500 hectares' },
        { key: '1501-3000', label: 'Entre 1.501 a 3.000 hectares' },
        { key: '3001-5000', label: 'Entre 3.001 a 5.000 hectares' },
        { key: '5001-10000', label: 'Entre 5.001 a 10.000 hectares' },
        { key: '+10000', label: 'Mais que 10.000 hectares' },
    ];

    const handleStep = (step) => {
        if (props.onStep) {
            props.onStep(step)
        }
    }

    const [email, setEmail] = useState(props.email);


    return (
        <div className='AccountCreate'>
            <div className="h6">Preencha os dados para criar sua conta</div>
            
            <div className="Box">
                <div className="content">
                    <div className="VariableContent">
                        <div className="form_line">
                            <InputText autofocus required label="Nome"/>
                            <InputText required label="Sobrenome"/>
                        </div>
                        <div className="form_line">
                            <InputText value={email} required type="email" readonly label="E-mail" onChange={(ev) => { setEmail(ev.target.value) }}/>
                            <InputText label="Telefone"/>
                        </div>
                        <div className="form_line">
                            <InputText required type="password" label="Senha"/>
                        </div>
                        <div className="form_line">
                            <Dropdown required label="Local de Trabalho" options={opts_where}/>
                            <Dropdown required label="Perfil" options={opts_position}/>
                        </div>
                        <div className="form_line">
                            <Dropdown required label="Principal atividade agrícola" options={opts_cult}/>
                            <Dropdown required label="Hectares da propriedade" options={opts_area}/>
                        </div>
                    </div>
                    <div className="ActionContent">
                        <Button full label="Criar conta" onClick={() => { handleStep('create') }}/>
                        <Button full type="text" label="Voltar" onClick={() => { handleStep('login') }}/>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default AccountCreate;