import './style.sass';
import { Divider, Button, AegroLogo, SocialButton, InputText, Tabs, Dropdown, Loader } from '../../components';
import { useState, useMemo, useEffect } from 'react';
import AccountCreate from './accountCreate';
import CodeValidation from './codeValidation';
import AccountLogin from './accountLogin';
import CodeSend from './codeSend';
import Auth from './auth';
import { useLocation, useNavigate } from 'react-router-dom';
import PasswordLogin from './passwordLogin';

function NewLogin2(props) {
    
    const navigate = useNavigate();

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => 
            new URLSearchParams(search)
        , [search]);
    }

    const query = useQuery()
    
    const [step, setStep] = useState(query.get('step') ? query.get('step') :  'login');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setStep(query.get('step') ? query.get('step') :  'login')
    }, [query]);

    const dict = {
        login : 'Software para Gestão de Fazendas',
        create: 'Criação de conta',
        'code-send': 'Acessar o Aegro',
        'code-validation': 'Código de acesso'
    }

    const [email, setEmail] = useState();

    const timeTable = {
        'login-create': 2000,
        'create-login': 0,
        'login-code-send': 1000,
        'code-send-login': 0,
        'code-send-code-validation': 2000,
        'code-validation-code-send': 0,
        'code-validation-auth': 1000,
    };

    const handleLoginStep = (stp, em) => {
        const path = `${step}-${stp}`;
        setLoading(true);
        setTimeout(() => {
            if (em) setEmail(em);
            navigate(`/login?step=${stp}&email=${em}`);
            setLoading(false);
        }, timeTable[path]);
    }

    const handleStep = (stp) => {
        const path = `${step}-${stp}`;        
        setLoading(true);
        setTimeout(() => {
            navigate(`/login?step=${stp}&email=${email}`);
            setLoading(false);
        }, timeTable[path]);
    }

    const getEmail = () => {
        return query.get('email') ? query.get('email') : email;
    }
    
    return (
        <div className='NewLogin scrollable'>
            <div className="Box">
                <div className="Header">
                    <div className="Header__Top">
                        <AegroLogo/>    
                        <div className="caption">{dict[step]}</div>
                    </div>
                    <Divider/>
                </div>

                { step === 'login' && <AccountLogin email={getEmail()} onStep={handleLoginStep}/>}
                { step === 'create' && <AccountCreate email={getEmail()} onStep={handleStep} />}
                { step === 'code-send' && <CodeSend email={getEmail()} onStep={handleStep} />}
                { step === 'code-validation' && <CodeValidation email={getEmail()} onStep={handleStep}/>}
                { step === 'password' && <PasswordLogin email={getEmail()} onStep={handleStep}/>}
                { step === 'auth' && <Auth email={getEmail()} onStep={handleStep}/>}
                    
                
            </div>
            {
                loading &&
                    <div className="LoaderWrapper">
                        <Loader/>
                    </div>
            }
        </div>
    )
}

export default NewLogin2;