import './style.sass';

function Divider(props) {

    return (
        <div className={`Divider ${props.vertical ? 'vertical' : ''} ${props.dashed ? 'dashed' : ''}`}></div>
    )
}

export default Divider;
