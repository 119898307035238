import InputText from '../InputText';
import './style.sass';
import { useState, useEffect } from 'react';

function InputCurrency(props) {

    const processValue = (value) => {
        let nValue = unprocessValue(value || '0,00');
        const formatted = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(nValue).split('R$')[1];
        return formatted;
    }

    const unprocessValue = (value) => {
        const v = value.toString();
        const nFormatted = v.replaceAll('.', '').replace(',', '').split('R$');
        return (Number(nFormatted[nFormatted.length - 1])/100).toFixed(2);
    }

    const validateKeys = (ev) => {
        if (!/[0-9]/.test(ev.key)) {
            ev.preventDefault();
        }
    }

    const handleChange = (ev) => {
        setVal(processValue(ev.target.value))
        if (props.onChangeValue) {
            props.onChangeValue({target: { value: Number(unprocessValue(ev.target.value)).toFixed(2) }});
        }
    }

    const handleFocus = (focus, ev) => {
        if (props.onChangeFocus) {
            props.onChangeFocus(focus, ev);
        } 
    }

    const [val, setVal] = useState(processValue(props.value)); 

    useEffect(() => {
        setVal(processValue(props.value));
    }, [props.value, processValue]);

    return (
        <div className={`InputCurrency`}>
            <InputText 
                look={props.look}
                disabled={props.disabled} 
                readOnly={props.readonly} 
                autoFocus={props.autofocus} 
                value={val} 
                onKeyPress={validateKeys} 
                type="currency"
                placeholder={props.placeholder} 
                label={props.label}
                required={props.required}
                description={props.description}
                onChangeValue={handleChange} 
                onFocus={(ev) => { handleFocus(true, ev) }} 
                onBlur={(ev) => { handleFocus(false, ev) }}                
                />    
        </div>
    )
}

export default InputCurrency;
