import Icon from '../Icon';
import './style.sass';

import { useId } from 'react';

function Checkbox(props) {

    const uId = useId();

    const handleChange = (ev) => {
        if (props.onChange) {
            props.onChange(ev)
        }
    }

    return (
        <div className={`Checkbox`}>
            <input type="checkbox" id={uId} name={props.name} checked={props.checked} value={props.value} onChange={handleChange}/>
            <label htmlFor={uId}>{}
                <div className="Checkbox__Box transition-out"><Icon small name="check"/></div>
                <div className="Checkbox__Label">{props.label}</div>
            </label>
        </div>
    )
}

export default Checkbox;
