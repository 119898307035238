import { AegroLogo, Button, Counter, Icon, OverlayPositioned } from '../../components';
import './style.sass';
import { useState, useRef } from 'react';

function AegroMenu(props) {

    const [currentTab, setCurrentTab] = useState('farm');

    const tabs = [
        {
            key: 'farm',
            label: 'Minha Fazenda'
        },
        // {
        //     key: 'crop',
        //     label: 'Safra jow'
        // }
    ]

    const Tab = (props) => {

        const selfRef = useRef();
        const [opened, setOpened] = useState();

        return (
            <div ref={selfRef} className={`Tab transition-out ${props.active ? 'active' : ''}`}>
                <div className="round"></div>
                {props.tab.label}
                <div className="Action" onClick={()=>{setOpened(true)}}><Icon small name="chevron-down"/></div>    
                <div className="round right"></div>
                {
                    opened && <OverlayPositioned element={selfRef.current} onClose={()=>{setOpened(false)}}>
                        <div className='AegroMenu__OverContent'>
                            <div className="AegroMenu__OverContent__Title">
                                <Icon name="home-group"/>
                                <div className="h5">Fazendas</div>
                            </div>
                            <div className="AegroMenu__OverContent__Search">
                                <Icon name="magnify"/>
                                <input type="text"/>
                            </div>
                            <div className='AegroMenu__OverContent__List'>
                                <div className="Item">
                                    <div className="Item__Title">Fazenda</div>
                                    <div className="Item__Subtitle caption">User | Porto Alegre</div>
                                </div>
                                <div className="Item">
                                    <div className="Item__Title">Fazenda</div>
                                    <div className="Item__Subtitle caption">User | Porto Alegre</div>
                                </div>
                            {                                
                                // fList(props.options) && fList(props.options).length > 0 &&
                                // fList(props.options).map((option, i) => 
                                //     <DropdownItem key={option.key+i} option={option} onSelect={handleSelect} />
                                // ) 
                            }
                            <Button label="ver mais" type="text"/>
                            </div>
                        </div>        
                    </OverlayPositioned>
                }
            </div>            
        )
    }

    return (
        <div className={`AegroMenu`}>
            <AegroLogo glyph white/>
            {
                tabs && 
                <div className={`AegroMenu__Tabs`}>
                    {
                        tabs.map((tab, i) => 
                            <Tab key={'tab'+i} tab={tab} active={tab.key === currentTab}/>
                        )
                    }
                </div>
            }
            <Button type="icon" tooltip="Cadastros" icon="book-open-page-variant" />
            <div className="spacer"></div>
            <div className={`AegroMenu__Tools`}>
                <Button type="icon" tooltip="Integrações" icon="view-grid-plus" />
                <Button type="icon" tooltip="Ajuda" icon="help-circle-outline" />
                <Button type="icon" tooltip="Notificações" icon="bell" />
                <div className="Profile">
                    U
                </div>
            </div>
            
        </div>
    )
}

export default AegroMenu;
