import { Button, Checkbox, Divider, Icon, Loader, OverlayPositioned, Tabs } from '../../components';
import './components.sass';
import { useState, useRef } from 'react';
import { find, cloneDeep } from 'lodash';

function KRView(props) {

    const getKR = (id) => {
        const kr = find(props.krs, k => k.id === id);
        return kr || { id: '', description: 'kr not found' }
    }

    return (
        <div className={`KRView`}> 
            {
                getKR(props.id).parent &&
                <div className="HoverCard transition-out">
                    { getKR(getKR(props.id).parent).description }
                </div>
            }
            <Icon name="bullseye-arrow"/>
            { getKR(props.id).description }
            {
                getKR(props.id).links.length > 0 &&
                <div className="LinkCard transition-out">
                    { 
                        getKR(props.id).links.map((link, i) => 
                            <a key={link.id} href={link.url} target="_blank" rel="noreferrer">{link.name}</a>
                        )
                    }
                </div>
            }

        </div>
    )
}


export { 
    KRView
};