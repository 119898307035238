import './style.sass';
import { useEffect } from 'react';
import { enterBubble, moveBubble, leaveBubble } from '../../libs/tooltip'

function Tooltip(props) {

    useEffect(() => {
        leaveBubble();
    }, []);

    const handleClick = () => {
        if(props.onClick) props.onClick();
    }
    const handleEnter = () => {
        enterBubble(props.text);
    }    
    const handleMove = (ev) => {
        moveBubble(ev.clientX, ev.clientY)
    }
    const handleLeave = () => {
        leaveBubble();
    }

    return (
        <div className={`Tooltip`}
            onClick={handleClick}
            onMouseEnter={handleEnter}
            onMouseMove={handleMove}
            onMouseLeave={handleLeave}>
            {props.children}
        </div>
    )
}

export default Tooltip;
