import './NFeTeam.sass';
import { Button } from '../../components';
import { useState, useEffect } from 'react';
import {  SidebarContent } from '../../composed_components';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { find } from 'lodash';

function NFeTeam(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const [currentTab, setCurrentTab] = useState('');

    useEffect(() => {
        const tab = location.pathname.split('/')[3];
        setCurrentTab(tab ? tab : 'team');
    }, [setCurrentTab,location.pathname]);
    
    const tabs = [
        { key: 'team', name: 'Equipe', label: 'Equipe' },
        { key: 'roles', name: 'Perfis', label: 'Perfis' },
    ];

    const options = {
        icon: 'account-multiple',
        title: 'Equipe',
        subtitle: 'Gerencie funcionários e permissões',
        tabs: tabs,
    };

    const handleChangeTab = (tab) => {
        navigate(tab === 'team' ?  '' : tab);
    }

    const getTitle = (tab) => {
        return find(tabs, t => t.key === (tab ? tab : 'team')).name || 'None'
    }

    return (
        <div className='NFeTeam'>
            <div className="NFe__Content">
                <SidebarContent { ...options } currentTab={currentTab} section="Equipe" contentTitle={getTitle(currentTab)} onChangeTab={handleChangeTab}>
                    <Outlet/>
                </SidebarContent>
            </div>
        </div>
    )
}

export default NFeTeam;