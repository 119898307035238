import { useState, useRef } from 'react';
import { cloneDeep } from 'lodash';
import './style.sass';

function CodeInput(props) {

    const [values, setValues] = useState(['', '', '', '', '', '']);
    const [validated, setValidated] = useState(false);


    const refs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];


    const setValuesMiddle = (vals) => {
        setValues(vals);
        if (props.onChange) {
            props.onChange(vals.join(''));
        }
    }

    const handleChange = (ev, index) => {
        const nValues = cloneDeep(values);
        nValues[index] = ev.target.value.replace(/\D/g, '');
        setValuesMiddle(nValues);

        if (props.messageType && props.clearError) {
            props.clearError()
        }

        if (ev.target.value.replace(/\D/g, '') !== '') {
            if (index + 1 < refs.length) {
                refs[index + 1].current.focus();    
            } else if (index + 1 === refs.length) {
                // refs[index].current.blur();  
            } 
        } 

    }

    const handleKeyUp = (ev, index) => {
        if (ev.code === 'Backspace') {
            if (index > 0) {
                refs[index - 1].current.focus();    
            } 
        } else if (ev.code === 'ArrowLeft') {
            if (index > 0) {
                refs[index - 1].current.focus();    
            }
        } else if (ev.code === 'ArrowRight') {
            if (index + 1 < refs.length) {
                refs[index + 1].current.focus();    
            }
        } else if (ev.code === 'Enter') {
            if (values.filter(n => n !== '').length === 6) {
                if (props.onSubmit) {
                    props.onSubmit(values.join(''));    
                }
            }
        }
    } 

    // 456 611

    const handlePaste = (ev, index) => {
        const pasteData = ev.clipboardData.getData('text');
        const slice = pasteData.split('').filter(n => n !== ' ');        

        if (Array.isArray(slice) && slice.length === 6) {
            setValidated(true);
            slice.forEach(n => {
                if (validated) {
                    setValidated(!isNaN(parseInt(n)))
                }
            });
            if (validated) {
                setValuesMiddle(slice);
            } else {
                // Código invalido
                console.log(slice);
            }

        } else {
            ev.preventDefault()
        }
    } 

    return (
        <div className={`CodeInput ${props.messageType}`}>
            <form>
                {[0 , 1 , 2].map(n => {
                    return(
                        <input 
                            className="transition-out"
                            key={n} 
                            placeholder=" "
                            ref={refs[n]} 
                            value={values[n]} 
                            type="tel" 
                            maxLength="1" 
                            tabIndex={n} 
                            onFocus={(ev) => { ev.target.select() }}
                            onPaste={(ev)=>{handlePaste(ev, n)}} 
                            onKeyUp={(ev)=>{handleKeyUp(ev, n)}} 
                            onChange={(ev) => { handleChange(ev, n) }} 
                            autoFocus={n === 0 ? "autofocus" : false}/>
                    )
                 })}
                <div className="space"></div>
                {[3 , 4 , 5].map(n => {
                    return(
                        <input 
                            className="transition-out"
                            key={n} 
                            placeholder=" "
                            ref={refs[n]} 
                            value={values[n]} 
                            type="tel" 
                            maxLength="1" 
                            tabIndex={n} 
                            onFocus={(ev) => { ev.target.select() }}
                            onPaste={(ev)=>{handlePaste(ev, n)}} 
                            onKeyUp={(ev)=>{handleKeyUp(ev, n)}} 
                            onChange={(ev) => { handleChange(ev, n) }}/>
                    )
                 })}
            </form>
            <div className={`message body2`}>
                {props.message}
            </div>
        </div>
    )
}

export default CodeInput;
