
import { Divider } from '../../../../components';
import './style.sass';


function ConciliationHeader(props) {

    return (
        <div className={`ConciliationHeader`}>     
            <div className='date body2'><b>Data</b></div>
            <div className='placeholder category'></div>
            <div className='ConciliationHeader__Transfer'>
                <div className='body2'><b>Conta</b></div>
                <div className='placeholder'></div>
                <div className='body2'><b>Origem/Destino</b></div>
            </div>
            <div className='installment body2'><b>Parcela</b></div>
            <div className='value body2'><b>Valor</b></div>
            <div className='placeholder'></div>
            <Divider/>
            <div className='placeholder'></div>
        </div>
    )
}

export default ConciliationHeader;