import Button from '../Button';
import { useState, useEffect } from 'react';
import './style.sass';

function StructurePane(props) {

    const handleClose = () => {
        if (props.onClose) props.onClose();

        setTimeout(() => {
            if (props.afterClose) props.afterClose();
        }, 400);
    }

    return (
        <div className={`StructurePane ${props.position || 'bottom'} ${props.opened ? 'opened' : ''}`}>
            <div className="Content transition-out">{props.children}</div>
            <div className="Pane transition-out">
                <Button type="icon" icon="close" onClick={handleClose}/>
                {props.pane}
            </div>
        </div>
    )
}

export default StructurePane;
