import { Checkbox } from '../../components';
import './style.sass';
import { useState } from 'react';

function DataGrid(props) {

    const handleClick = (rowIndex) => {
        if (props.onClick) props.onClick(rowIndex);
    }

    return (
        <div className={`DataGrid`}>            
            <div className="TableHeader">
                { props.data.options?.selectable && <div><Checkbox/></div> }
                {
                    props.data.header.map((col, i) => 
                        <div className="caption ellipsis" key={'header'+i}>{col.data}</div>
                    )
                }
            </div>
            <div className="TableRows">
                {
                    props.data.data.map((row, rIndex) => 
                        <div className="TableRow" onClick={() => { handleClick(rIndex) }}>
                            { props.data.options?.selectable && <div><Checkbox/></div> }
                            {
                                row.map((field, fIndex) =>
                                    <div className="caption ellipsis" key={'row'+fIndex}>{field.data}</div> 
                                )
                            }
                            {/* <ButtonDropdown icon="more-vertical" /> */}
                        </div>
                    )
                }                    
            </div>            
        </div>
    )
}

export default DataGrid;
