import { Button, ButtonDropdown, Icon, Tabs } from '../../components';
import './style.sass';
import { useState, useRef } from 'react';

function ModuleEmptyState(props) {

    const handleOpen = () => {
        if (props.onOpen) props.onOpen();
    }
    
    return (
        <div className={`ModuleEmptyState`}>
            <div className="clickable" onClick={handleOpen}></div>
            <Icon name="plus"/>
            <div className="body2">{props.text}</div>
        </div>
    )
}

export default ModuleEmptyState;
