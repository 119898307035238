import './index.sass';
import { useState, useEffect } from 'react';
import { Tabs, Button, Divider } from '../../components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getFromLocalDB } from './DBFunctions';

function OFXConc(props) {

    const location = useLocation();

    const getTabs = (count) => {
        return [
            {
                name: 'Detalhes',
                key: ''
            },{
                name: 'Importação OFX',
                key: 'import',
                count: count || 0
            },{
                name: 'Extrato Conciliado',
                key: 'statement'
            }
        ]
    }

    const getPath = (pathname) => {
        const splitPath = pathname.split('/');
        const p = splitPath[splitPath.length - 1]; 
        return p === 'ofx' ? '' : p
    }

    const navigate = useNavigate();  
    const [db, setDB] = useState([])
    const [currentTab, setCurrentTab] = useState(getPath(location.pathname));

    

    const changeTab = (tab) => {
        setCurrentTab(tab);
        navigate(tab)
    }

    useEffect(() => {
        getFromLocalDB()
            .then((db) => {
                setDB(db.initialOFX);
            })
            .catch()
    }, []);

    return (
        <div className='OFXConc'>
            <div className='OFXConc__Header'>
                <div className='OFXConc__Header__Top'>
                    <div className='h6'>Nubank</div>
                    <Button type="icon" icon="close" onClick={() => { navigate('/') }}/>
                </div>
                <Tabs tabs={getTabs(props.count || db.length)} current={currentTab} onChangeTab={changeTab}/>
            </div>
            <div className='OFXConc__CompactHeader'>
                <div className='h6'>Nubank</div>
                <Tabs tabs={getTabs(props.count || db.length)} current={currentTab} onChangeTab={changeTab}/>
                <Button type="icon" icon="close" onClick={() => { navigate('/') }}/>
            </div>
            <Divider/>
            <div style={{ flex: 1, minHeight:0 }}>
                <Outlet/>
            </div>
        </div>
    )
}

export default OFXConc;