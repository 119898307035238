import './NFeEdits.sass';

import { Button, Divider, Dropdown, HStack, Icon, InputText, OverlayPositioned, Radio, StructurePane, TextArea, VStack } from "../../../../components"
import { ClientModule, OperationModule } from "../../../../composed_components"
import { useState } from 'react';
import { cloneDeep, set } from 'lodash';

function NFeEdits(props) {

    const dict = {
        issuer: {
            title: 'Emitente'
        },
        recipient: {
            title: 'Destinatário'
        },
        operation: {
            title: 'Informações da Nota'
        },
        products: {
            title: 'Produtos'
        },
        transport: {
            title: 'Transporte'
        },
        payment: {
            title: 'Pagamento'
        },
        aditional: {
            title: 'Informações Adicionais'
        },
    }

    const updateNfeData = (path, val) => {
        const newNfeData = cloneDeep(props.nfeData);
        set(newNfeData, path, val);
        if (props.onChange) props.onChange(newNfeData);
    }

    const paymentOptions = [
        { key: 'pix', label: 'Pagamento instantâneo (PIX)' },
        { key: 'none', label: 'Sem pagamento' },
    ];

    const transportOptions = [
        {  key: 'none', label: 'Sem ocorrência de transporte'},
        {  key: 'cif', label: 'Contratação do frete por conta do emitente (CIF)'},
        {  key: 'fob', label: 'Contratação do frete por conta do destinatário (FOB)'},
        {  key: 'terc', label: 'Contratação do frente por conta de terceiros'},
        {  key: 'emit', label: 'Transporte próprio por conta do emitente'},
    ];

    return(
        <div className={`NFeEdits scrollable`}>
            <div className="h4">{dict[props.current]?.title}</div>
            {
                props.current === 'issuer' &&
                <VStack spacing="spaced">
                    <ClientModule issuer selected={props.nfeData.issuer} onSelect={(id)=>{updateNfeData('issuer', id)}}/>
                    {
                        props.nfeData.issuer && 
                        <HStack spacing="spaced">
                            <HStack>
                                <Radio/>    
                                <div>
                                    <b>Endereço de cobrança</b><br/>
                                    Av. Osvaldo Aranha - Bom Fim, , 325<br/>
                                    Porto Alegre/RS - Brasil<br/>
                                    jaguatirica - 34423253
                                </div>
                            </HStack>
                            <HStack>
                                <Radio/>    
                                <div>
                                    <b>Endereço de cobrança</b><br/>
                                    Av. Osvaldo Aranha - Bom Fim, , 325<br/>
                                    Porto Alegre/RS - Brasil<br/>
                                    jaguatirica - 34423253
                                </div>
                            </HStack>
                        </HStack>
                    }
                </VStack>
            }
            {
                props.current === 'recipient' &&
                <VStack spacing="spaced">
                    <ClientModule selected={props.nfeData.recipient} onSelect={(id)=>{updateNfeData('recipient', id)}}/>
                    {
                        props.nfeData.recipient &&
                        <HStack spacing="spaced">
                            <HStack>
                                <Radio/>    
                                <div>
                                    <b>Endereço de cobrança</b><br/>
                                    Av. Osvaldo Aranha - Bom Fim, 325<br/>
                                    Porto Alegre/RS - Brasil<br/>
                                    jaguatirica - 34423253
                                </div>
                            </HStack>
                            <HStack>
                                <Radio/>    
                                <div>
                                    <b>Endereço de cobrança</b><br/>
                                    Av. Osvaldo Aranha - Bom Fim, 325<br/>
                                    Porto Alegre/RS - Brasil<br/>
                                    jaguatirica - 34423253
                                </div>
                            </HStack>
                        </HStack>
                    }
                </VStack>
            }
            {
                props.current === 'operation' &&
                <VStack spacing="spaced">
                    <ClientModule selected={props.nfeData.recipient} onSelect={(id)=>{updateNfeData('recipient', id)}}/>
                    {
                        props.nfeData.operation &&
                        <HStack spacing="spaced">
                            <HStack>
                                <Radio/>    
                                <div>
                                    <b>Endereço de cobrança</b><br/>
                                    Av. Osvaldo Aranha - Bom Fim, 325<br/>
                                    Porto Alegre/RS - Brasil<br/>
                                    jaguatirica - 34423253
                                </div>
                            </HStack>
                            <HStack>
                                <Radio/>    
                                <div>
                                    <b>Endereço de cobrança</b><br/>
                                    Av. Osvaldo Aranha - Bom Fim, 325<br/>
                                    Porto Alegre/RS - Brasil<br/>
                                    jaguatirica - 34423253
                                </div>
                            </HStack>
                        </HStack>
                    }
                </VStack>
            }
            {
                props.current === 'transport' &&
                <VStack spacing="spaced">
                    <VStack>
                        <Dropdown look="outline" options={transportOptions} label="Tipo de frete" selected={props.nfeData.transport.method}/>
                    </VStack>
                    {
                        props.nfeData.transport.method !== 'none' &&
                        <VStack spacing="spaced">
                            {/* <VStack>
                                <div className="h6">Forma de pagamento</div>
                                <Radio name="pagamento" label="A vista" value="cash" checked={props.nfeData.payment.type === 'cash'} />
                                <Radio name="pagamento" label="A prazo" value="term" checked={props.nfeData.payment.type === 'term'} />
                            </VStack>
                            <VStack>
                                <div className="h6">Valores</div>
                                <InputText type="currency" label="Valor" required/>
                            </VStack> */}
                        </VStack>
                    }
                </VStack>
            }
            {
                props.current === 'payment' &&
                <VStack spacing="spaced">
                    <VStack>
                        <Dropdown look="outline" options={paymentOptions} label="Método de pagamento" selected={props.nfeData.payment.method}/>
                    </VStack>
                    {
                        props.nfeData.payment.method !== 'none' &&
                        <VStack spacing="spaced">
                            <VStack>
                                <div className="h6">Forma de pagamento</div>
                                <Radio name="pagamento" label="A vista" value="cash" checked={props.nfeData.payment.type === 'cash'} />
                                <Radio name="pagamento" label="A prazo" value="term" checked={props.nfeData.payment.type === 'term'} />
                            </VStack>
                            <VStack>
                                <div className="h6">Valores</div>
                                <InputText look="outline" type="currency" label="Valor" required/>
                            </VStack>
                        </VStack>
                    }
                </VStack>
            }
            {
                props.current === 'aditional' &&
                <VStack spacing="spaced">
                    {
                        props.nfeData.aditional &&
                        <VStack spacing="spaced">
                            <VStack>
                                <div className="overline">Informações Adicionais Automáticas</div>
                                <TextArea look="outline" label="Teste" placeholder="Teste" maxlength={3000} value={props.nfeData.aditional.fixed} disabled/>
                            </VStack>
                            <VStack>
                                <div className="overline">Informações Adicionais</div>
                                <TextArea look="outline" label="Teste" placeholder="Teste" maxlength={3000} value={props.nfeData.aditional.user} onChange={(val)=>{updateNfeData('aditional.user', val)}}/>
                            </VStack>
                        </VStack>
                    }
                </VStack>
            }
        </div>
    )
}

export default NFeEdits