import './style.sass';

function App(props) {

    return (
        <div className={`App`}>
            <div className='App__Content'>
                {props.children}
            </div>
            <div className='App__Overlay'></div>
            <div className='App__Tooltip'></div>
        </div>
    )
}

export default App;
