import './style.sass';
import { useState } from 'react';
import { Button, Counter, Divider, Tooltip } from '../../../../components';

import { 
    getValid
} from '../../DBFunctions';


function OFXCard(props) {

    const [remove, setRemove] = useState(false);

    const onClick = (item) => {
        if (props.onSelect && !props.readOnly) {
            props.onSelect(item);
        }
    }

    const handleJoin = (join, item) => {
        if (props.onJoin) {
            props.onJoin(join, item);
        }
    }

    const handleIgnore = (item) => {
        setRemove(true);
        setTimeout(() => {
            if (props.onIgnore) {
                props.onIgnore(item);
            }
        }, 300);
    }

    return (
        <div id={props.item.id} className={`OFXCard transition-out ${props.active ? 'active' : ''} ${props.reconcile ? 'reconcile' : ''} ${remove ? 'remove' : ''} ${props.readOnly ? 'readOnly' : ''} ${getValid(props.item) ? 'valid' : ''}`} >
            <div className='OFXCard__Top'>
                <div className='Label body2 ellipsis' title={props.item.label} onClick={() => {onClick(props.item)}}>
                    {props.item.label}
                </div>
                <div className='Action'>
                    {props.item.bills.length > 0 && <Counter type={getValid(props.item) ? '' : 'grey'} count={props.item.bills.length}/>} 
                </div>
            </div>
            <div className='OFXCard__Bottom'>
                <div className={`Value  subtitle1 ${props.item.value < 0 ? 'negative' : ''}`}  onClick={() => {onClick(props.item)}}>
                    { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.item.value)}
                </div>
                <div>
                    { props.active && <Button type='text-grey' small label='Ignorar' onClick={ () => { handleIgnore(props.item) } }/> }
                    
                    <Tooltip text='Agrupar'>
                        { !props.active && props.canJoin && <Button type='icon' icon='plus' onClick={ () => { handleJoin(true, props.item) } }/> }         
                    </Tooltip>
                </div>
            </div>
            {
                props.item.children && props.item.children.length > 0 && props.item.children.map(child =>                
                <div key={child.id} className='OFXCard__Children'>
                    <Divider dashed/>
                    <div className='OFXCard__Top'>
                        <div className='Label body2 ellipsis' title={props.item.label} onClick={() => {onClick(props.item)}}>
                            {child.label}
                        </div>
                        <div className='Action'>
                            
                        </div>
                    </div>
                    <div className='OFXCard__Bottom'>
                        <div className={`Value  subtitle1 ${child.value < 0 ? 'negative' : ''}`} onClick={() => {onClick(props.item)}}>
                            { Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(child.value)}
                        </div>     
                        <div>
                            <Tooltip text='Desagrupar'>
                                { props.active && <Button type='icon' icon='minus' onClick={ () => { handleJoin(false, child) } }/> }       
                            </Tooltip>
                        </div>        
                    </div>
                </div>
                )
            }
        </div>
    )
}

export default OFXCard;