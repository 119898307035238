import { useEffect, useState } from 'react';
import './style.sass';

function Counter(props) {

    const [count, setCount] = useState(props.count);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (count !== props.count) {
            setAnimate(true);
            setCount(props.count);
            setTimeout(() => {
                setAnimate(false);
            }, 500);
        }
    }, [props.count, count]);

    return (
        <div className={`Counter transition-out ${props.type || ''} ${animate ? 'animate': ''} ${props.small ? 'small' : ''}`}>
            <div>{count}</div>
        </div>
    )
}

export default Counter;
