import { cloneDeep } from 'lodash';
import { doc, setDoc, getDoc, getDocs, collection, getFirestore, deleteDoc, onSnapshot, updateDoc} from "firebase/firestore"; 
import { isLogged } from './firebase.core';
import { remove } from 'lodash';
import uniqid from 'uniqid';

const getLabPrototypes = () => {
    return new Promise((resolve, reject) => {
        const docRef = doc(getFirestore(), 'lab', 'prototypes');
            getDoc(docRef)
                .then(snapshot => {
                    if (snapshot.exists()) {
                        const doc = cloneDeep(snapshot.data());
                        resolve(doc);
                    } else {
                        reject(false);
                    }
                })
                .catch(err => {
                    reject(err)
                });
    });
}

const getLabData = (dataId) => {
    return new Promise((resolve, reject) => {
        if (!dataId) reject('no dataId');
        const docRef = doc(getFirestore(), 'lab', dataId);
            getDoc(docRef)
                .then(snapshot => {
                    if (snapshot.exists()) {
                        const doc = cloneDeep(snapshot.data());                    
                        const ofxData = {
                            ofx: JSON.parse(doc.ofx),
                            bills: JSON.parse(doc.bills),
                        };
                        resolve(ofxData);
                    } else {
                        reject(false);
                    }
                })
                .catch(err => {
                    reject(err)
                });
    });
}

const saveLabData = (dataId, data) => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const toSave = {
                    ofx: JSON.stringify(data.ofx),
                    bills: JSON.stringify(data.bills),
                };
                const docRef = doc(getFirestore(), 'lab', dataId);
                setDoc(docRef, toSave)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log('not logged');                    
            });
    });
} 

const updateEditing = (dataId, isEditing, id, editing) => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const docRef = doc(getFirestore(), 'lab', dataId);

                const nEditing = cloneDeep(editing);

                if (isEditing) {
                    if (nEditing.indexOf(id) < 0) nEditing.push(id);
                } else {
                    remove(nEditing, i => i === id);
                }

                const toUpdate = {
                    editing: JSON.stringify(nEditing)
                };

                updateDoc(docRef, toUpdate)
                    .then(() => {
                        resolve(nEditing);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log('not logged');                    
            });
    })
}

const listenLabData = (observer) => {
    const q = collection(getFirestore(), 'lab');
    return onSnapshot(q, (snapshot) => observer(snapshot));
}

const listenIndicadores = (observer) => {
    const q = collection(getFirestore(), 'indicadores');
    return onSnapshot(q, (snapshot) => observer(snapshot));
}

const listenIndicadoresExtra = (observer) => {
    const q = collection(getFirestore(), 'indicadores_extra');
    return onSnapshot(q, (snapshot) => observer(snapshot));
}

const getIndicadoresSettings = () => {
    return new Promise((resolve, reject) => {
        const docRef = doc(getFirestore(), 'indicadores_extra', 'settings');
            getDoc(docRef)
                .then(snapshot => {
                    if (snapshot.exists()) {     
                        updateUsers(snapshot.data().users)  
                        resolve(snapshot.data());
                    } else {
                        reject(false);
                    }
                })
                .catch(err => {
                    reject(err)
                });
    });
}

const updateUsers = (users) => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const nUsers = cloneDeep(users);
                nUsers[user.email] = {
                    name: user.displayName,
                    image: user.photoURL
                }
                const docRef = doc(getFirestore(), 'indicadores_extra', 'settings');
                updateDoc(docRef, { users: nUsers })
                    .then(() => {
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log(err, 'not logged');                    
            });
    });
}

const updateOrder = (order) => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const docRef = doc(getFirestore(), 'indicadores_extra', 'settings');
                updateDoc(docRef, { order })
                    .then(() => {
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log(err, 'not logged');                    
            });
    });
}

const saveSettings = (settings) => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const docRef = doc(getFirestore(), 'indicadores_extra', 'settings');
                setDoc(docRef, settings)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log(err, 'not logged');                    
            });
    });
} 

const createIndicador = () => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const kpi = {
                    id: uniqid(),
                    title: "",
                    owner: "",
                    view: "line",
                    period: "both",
                    datatype: "number",
                    source: "",
                    link: "",
                    data: [
                        {
                            "name": "",
                            "wdata": {},
                            "mdata": {}
                        }
                    ]
                }
                const docRef = doc(getFirestore(), 'indicadores', kpi.id);
                setDoc(docRef, kpi)
                    .then(() => {
                        resolve(doc.id);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log(err, 'not logged');                    
            });
    });
} 

const saveIndicador = (kpi) => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const docRef = doc(getFirestore(), 'indicadores', kpi.id);
                setDoc(docRef, kpi)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log(err, 'not logged');                    
            });
    });
} 

const deleteIndicador = (id) => {
    return new Promise((resolve, reject) => {
        isLogged()
            .then((user) => {
                const docRef = doc(getFirestore(), 'indicadores', id);
                deleteDoc(docRef)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(err => {
                        reject(err)
                    });
                
            })
            .catch((err) => {
                console.log(err, 'not logged');                    
            });
    });
} 

export {
    getLabData,
    saveLabData,
    listenLabData,
    updateEditing,
    listenIndicadores,
    createIndicador,
    saveIndicador,
    deleteIndicador,
    listenIndicadoresExtra,
    getIndicadoresSettings,
    saveSettings,
    updateOrder
}