import { useState, useRef } from 'react';
import './style.sass';
import ModuleEmptyState from '../ModuleEmptyState';
import ModuleList from '../ModuleList';
import ModuleSelected from '../ModuleSelected';
import ModuleListItem from '../ModuleListItem';

import { find } from 'lodash';

import operations from './operations';

function OperationModule(props) {

    const selfRef = useRef();
    const [opened, setOpened] = useState(false);
    const [searchText, setSearchText] = useState('');

    const getSelectedById = (id) => {
        return find(operations, c => c.id === id);
    }

    const handleSelect = (sel) => {
        if (props.onSelect) props.onSelect(sel)
    }
    
    return (
        <div className={`OperationModule`} ref={selfRef}>
            { 
                !props.selected && 
                <ModuleEmptyState text={`Escolher operação`} onOpen={()=>{setOpened(true)}}/> 
            }
            { 
                props.selected &&
                <ModuleSelected
                    icon="soja"
                    title={getSelectedById(props.selected).name || 'Nada'}
                    subtitle={'CFOP: ' + getSelectedById(props.selected).cfop || 'Nada'}
                    onRemove={()=>{handleSelect(null)}}
                    onOpen={()=>{setOpened(true)}}
                />
            }
            { 
                opened && 
                    <ModuleList 
                        elRef={selfRef.current} 
                        onText={(ev) => {setSearchText(ev.target.value)}}
                        onClose={()=>{setOpened(false)}}
                        new={{ icon: 'plus', text: searchText ? `Adicionar '${searchText}'` : `Adicionar nova operação` }}
                    >
                        <div className="overline">Operações</div>
                        {
                            operations.map((op) =>
                                <ModuleListItem key={op.id} iconLeft={op.icon} title={op.name} subtitle={'CFOP: ' + op.cfop} onClick={()=>{handleSelect(op.id)}}/>
                            )
                        }

                    </ModuleList>
            }
        </div>
    )
}

export default OperationModule;
