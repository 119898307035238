import './DataInput.sass';

function DataInput(props) {
    
    const handleInput = (ev) => {
        if (props.onChange) props.onChange(ev);
    }

    return (
        <div className="DataInput">
            {/* <label className="caption ellipsis">{props.label}</label> */}
            <input type="number" defaultValue={props.value} onChange={handleInput}/>
        </div>
    )
}

export default DataInput;