import './style.sass';

import { nfeStatuses, nfeEvents } from '../../database'
import { Button, ButtonMulti, Divider, Dropdown, HStack, Icon, InputCurrency, InputDate, InputText, Loader, OverlayPositioned, Radio, StatusTag, StructurePane, Tabs, VStack } from "../../../../components"
import { useState, useEffect } from 'react';

import PDFViewer from './PDFViewer';
import { set, cloneDeep } from 'lodash'; 

function ViewNfe(props) {
    
    const tabTabs = [
        { key: 'danfe', name: 'DANFe' },
        { key: 'simplified', name: 'Resumo Simplificado' },
    ];

    const handleClose = () => {
        if (props.onClose) props.onClose();
    }

    const updateNfeData = (path, val) => {
        const newNfeData = cloneDeep(props.nfeData);
        set(newNfeData, path, val);
        if (props.onChange) props.onChange(newNfeData);
        passNfeData(newNfeData);
    }

    const passNfeData = (newNfeData) => {
        if (props.onChange) props.onChange(newNfeData);
    }

    const EventCard = (props) => {
    
        return (
            <div className={`EventCard ${props.event.status}`}>
                { 
                    props.event.status !== 'working' &&
                        <div className={`TimeHeader caption`}>
                            <div>{new Intl.DateTimeFormat('pt-BR').format(new Date(props.event.date))}</div>     
                            <div>{new Intl.DateTimeFormat('pt-BR', { hourCycle: 'h23', hour: 'numeric', minute: 'numeric' }).format(new Date(props.event.date))}</div>     
                        </div>
                }
                <div className={`card ${props.event.action}`}>
                    <div className="body1">{nfeEvents[props.event.action][props.event.status].text}</div>
                    { props.event.status === 'working' && <Loader small/> }
                </div>                
                { props.event.status !== 'working' && <div className="body2">desci</div> }
                { 
                    props.event.status !== 'working' && nfeEvents[props.event.action][props.event.status].action && 
                        <Button type="outline" small icon={nfeEvents[props.event.action][props.event.status].action.icon} label={nfeEvents[props.event.action][props.event.status].action.label}/>
                }
            </div>
        )
    }

    const nfeOptions = [
        { key: 'correct', label: 'Carta de correção' },
        { key: 'nfe', label: 'Cancelar NFe' },
    ];

    const cycleStatus = (ev) => {
        const states = ['writing','processing','ready','authorized','cancelled','denied','rejected'];       
        if (ev.metaKey) {
            const i = states.indexOf(props.nfeData.status);
            updateNfeData('status', states[(i + 1) % states.length])
        } 
    }

    return (
        <div className={`ViewNFe ${props.popup ? 'popup' : ''}`}>
            {
                !props.popup &&
                [<div className="Header">
                    <div className="h5">Nova emissão de NFe</div>
                    <Button type="icon" icon="close" onClick={handleClose}/>
                </div>,
                <Divider/>]
            }
            { props.popup && <Button type="icon" icon="close" onClick={handleClose}/> }
            <div className="ViewNFe__Content">
                <div className="Preview">
                    <Tabs type="no-case" tabs={tabTabs} current="danfe"/>
                    <div className="PDFPreview">
                        <PDFViewer/>
                    </div>
                </div>
                <div className="Tools">
                    <div className="Header">
                        <div className="Subheader" onClick={cycleStatus}>
                            <div className="overline">Status</div>
                            <StatusTag status={nfeStatuses[props.nfeData?.status][1]}>{nfeStatuses[props.nfeData?.status][0]}</StatusTag>
                        </div>
                        {/* <div className="h5">NFe está processando</div> */}

                    </div>
                    <Divider/>
                    <div className="Events scrollable">
                        <div className="h6">Eventos</div>
                        <div className="EventsList">
                            {
                                props.nfeData?.history.map((event, i) =>
                                    <EventCard key={'event'+i} event={event}/>
                                )
                            }
                        </div>
                    </div>
                    <Divider/>
                    <div className="Actions">
                        <Button full type="outline-grey" icon="whatsapp" label="Compartilhar"/>
                        <Button full type="outline-grey" icon="download" label="Pré-visualização da danfe"/>
                        {
                            ['rejected'].indexOf(props.nfeData.status) > -1 &&
                                <Button full label="Reenviar"/>
                        }
                        {
                            ['writing', 'ready'].indexOf(props.nfeData.status) > -1 &&
                                <Button full loading={props.nfeData.status === 'processing'} disabled={props.nfeData.status !== 'ready'} label="Enviar"/>
                        }
                        {   
                            props.nfeData.status === 'authorized' &&
                                <ButtonMulti look="more" label="Mais ações" options={nfeOptions}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewNfe;