import './codeValidation.sass';
import { Divider, Button, AegroLogo, SocialButton, InputText, Tabs, Dropdown, CodeInput } from '../../components';
import { useState, useEffect, useRef } from 'react';

function CodeValidation(props) {

    const maskEmail = (email) => {
        const n = email.split('@');
        const nN = [];
        const n0 = n[0].split('');
        const nN0 = [];
        n0.forEach((l, i) => {
            if (i < 2) nN0.push(l);
            else nN0.push('*')
        });
        nN.push(nN0.join(''));
        nN.push(n[1])
        return nN.join('@');
    }

    let tim = useRef();
    let countDownDate = useRef();

    const resendWaitTimeSeconds = 60

    const [timer, setTimer] = useState('Reenviar código ');

    const startTimer = () => {
        const lastTokenDate = localStorage.getItem('lastTokenDate');
        let dateToCount = null;

        if (lastTokenDate) {
            dateToCount = lastTokenDate;
        } else {
            const newDate = new Date()
            newDate.setSeconds(newDate.getSeconds() + resendWaitTimeSeconds);
            localStorage.setItem('lastTokenDate', newDate)
            dateToCount = newDate;
        }
        
        countDownDate.current = new Date(dateToCount).getTime();
    }

    function timerLogic() { 
        const now = new Date().getTime();
        const distance = countDownDate.current - now;
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimer(`${seconds}s Reenviar código`);

        if (seconds <= 0) {
            clearInterval(tim.current);
            localStorage.removeItem('lastTokenDate');
            setTimer('Reenviar código');
        }
    }

    const resetTimer = () => {
        startTimer();
        tim.current = setInterval(timerLogic, 1000);
    }

    useEffect(() => {     
        startTimer();
        tim.current = setInterval(timerLogic, 1000);
    }, []);

    const [message, setMessage] = useState();
    const [messageType, setMessageType] = useState();
    const [code, setCode] = useState();

    const handleSubmit = (cd) => {
        if (`${code}` === '123456') {
            handleStep('auth')
        } else {
            setMessage('Código inválido. Verifique se é o mesmo enviado para seu email.');
            setMessageType('error');
        }
    }

    const handleStep = (step) => {
        if (props.onStep) {
            props.onStep(step)
        }
    }

    const handleClear = () => {
        setMessage('');
        setMessageType('');
    }

    const handleChangeCode = (code) => {
        setCode(code);
    }

    return (
        <div className='CodeValidation'>
            <div className="h6">Digite o código que foi enviado para seu email</div>
            <div className="body1">Verifique a caixa de entrada ou spam de {maskEmail(props.email)}</div>
            
            <div className="Box">
                <div className="content">
                    <div className="VariableContent">
                        <CodeInput onChange={handleChangeCode} onSubmit={handleSubmit} message={message} messageType={messageType} clearError={handleClear}/>
                    </div>
                    <div className="ActionContent">
                        <Button full label="acessar o aegro" loading={props.loading} onClick={handleSubmit}/>
                        <Button full type="outline" disabled={timer !== 'Reenviar código'} label={timer} onClick={resetTimer}/>
                        <Button full type="text" label="Voltar" onClick={() => { handleStep('code-send') }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CodeValidation;