import './codeSend.sass';
import { Divider, Button, AegroLogo, SocialButton, InputText, Tabs, Dropdown, CodeInput, Radio, Icon } from '../../components';
import { useState, useEffect } from 'react';

function CodeSend(props) {

    const [method, setMethod] = useState('email');

    const maskEmail = (email) => {
        const n = email.split('@');
        const nN = [];
        const n0 = n[0].split('');
        const nN0 = [];
        n0.forEach((l, i) => {
            if (i < 2) nN0.push(l);
            else nN0.push('*')
        });
        nN.push(nN0.join(''));
        nN.push(n[1])
        return nN.join('@');
    }

    const SendOption = (props) => {

        const dict = {
            sms: ['SMS', 'message-outline', 'Receber código no telefone (51) ...-6168'],
            whatsapp: ['Whatsapp', 'whatsapp-outline', 'Receber código no telefone (51) ...-6168'],
            email: ['E-mail', 'mail-outline', 'Receber código no email '],
        }

        return(
            <div className="SendOption" onClick={() => { setMethod(props.method) }}>
                <Radio checked={props.checked} name="Method"/>
                <div className="SendOption__Info">
                    <div className="SendOption__Info__Title">
                        <Icon name={dict[props.method][1]}/>
                        <div className="h6">{dict[props.method][0]}</div>
                    </div>
                    <div className="body2">
                        {dict[props.method][2]}
                        {props.email && maskEmail(props.email)}
                    </div>
                </div>
            </div>
        )
    }
    
    const handleStep = (step) => {
        if (props.onStep) {
            props.onStep(step)
        }
    }

    return (
        <div className='CodeSend'>
            <div className="h6">Escolha como você quer receber o código para acessar o Aegro:</div>
            
            
            <div className="Box">
                <div className="content">
                    <div className="VariableContent">
                        <SendOption checked={method === "sms"} method="sms"/>
                        <SendOption checked={method === "whatsapp"} method="whatsapp"/>
                        <SendOption email={props.email} checked={method === "email"} method="email"/>
                    </div>
                    <div className="ActionContent">
                        <Button loading={props.loading} full label="Enviar Código" onClick={() => { handleStep('code-validation') }}/>                    
                        <div className="section">
                            <Divider/>
                            <span>ou</span>
                            <Divider/>
                        </div>
                        <Button full type="outline" label="Acessar com senha" onClick={() => { handleStep('password') }}/>
                        <Button full type="text" label="Voltar" onClick={() => { handleStep('login') }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CodeSend;