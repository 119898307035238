import './style.sass';

function LCPDLogo() {

    return (
        <svg viewBox="0 0 126 24" className="LCPDLogo" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.6039 7.15091L19.6162 8.99091C19.2462 8.23091 18.7117 7.47091 18.0539 6.83091C17.3962 6.19091 16.6562 5.71091 15.8339 5.31091L17.725 1.39091C13.2028 -0.649094 7.69393 0.110906 3.95282 3.75091C-0.774957 8.39091 -0.774957 15.9109 3.99393 20.5109C8.76282 25.1509 16.4506 25.1509 21.2195 20.5109C24.9195 16.9109 25.7006 11.5109 23.6039 7.15091ZM18.0539 17.4709C15.0117 20.4309 10.1195 20.4309 7.11838 17.4709C4.07615 14.5109 4.07615 9.75091 7.11838 6.83091C9.50282 4.51091 12.9973 4.03091 15.875 5.31091L12.5862 12.1509L19.6162 8.95091C20.9317 11.7509 20.4384 15.1909 18.0539 17.4709Z" fill="white"/>
            <path d="M35.2507 18.5V6.13639H37.8647V16.3448H43.1652V18.5H35.2507Z" fill="white"/>
            <path d="M44.8479 18.5V9.2273H47.4197V18.5H44.8479ZM46.1399 8.03198C45.7575 8.03198 45.4295 7.90521 45.1558 7.65166C44.8862 7.39408 44.7514 7.0862 44.7514 6.72801C44.7514 6.37384 44.8862 6.06998 45.1558 5.81643C45.4295 5.55885 45.7575 5.43007 46.1399 5.43007C46.5222 5.43007 46.8482 5.55885 47.1178 5.81643C47.3915 6.06998 47.5283 6.37384 47.5283 6.72801C47.5283 7.0862 47.3915 7.39408 47.1178 7.65166C46.8482 7.90521 46.5222 8.03198 46.1399 8.03198Z" fill="white"/>
            <path d="M58.1247 9.2273L54.8828 18.5H51.9851L48.7433 9.2273H51.4599L53.3857 15.8619H53.4823L55.402 9.2273H58.1247Z" fill="white"/>
            <path d="M59.4407 18.5V9.2273H61.934V10.8452H62.0306C62.1996 10.2697 62.4833 9.83501 62.8818 9.54122C63.2802 9.2434 63.739 9.09448 64.2582 9.09448C64.387 9.09448 64.5258 9.10253 64.6747 9.11863C64.8236 9.13473 64.9544 9.15687 65.0671 9.18504V11.467C64.9464 11.4308 64.7794 11.3986 64.5661 11.3704C64.3528 11.3422 64.1576 11.3281 63.9805 11.3281C63.6022 11.3281 63.2641 11.4107 62.9663 11.5757C62.6725 11.7366 62.4391 11.962 62.266 12.2518C62.097 12.5416 62.0125 12.8756 62.0125 13.2539V18.5H59.4407Z" fill="white"/>
            <path d="M70.1548 18.6811C69.217 18.6811 68.4061 18.4819 67.7219 18.0835C67.0417 17.681 66.5165 17.1216 66.1462 16.4052C65.776 15.6848 65.5908 14.8497 65.5908 13.8999C65.5908 12.942 65.776 12.1049 66.1462 11.3885C66.5165 10.6681 67.0417 10.1087 67.7219 9.71025C68.4061 9.30779 69.217 9.10656 70.1548 9.10656C71.0925 9.10656 71.9015 9.30779 72.5816 9.71025C73.2658 10.1087 73.793 10.6681 74.1633 11.3885C74.5335 12.1049 74.7187 12.942 74.7187 13.8999C74.7187 14.8497 74.5335 15.6848 74.1633 16.4052C73.793 17.1216 73.2658 17.681 72.5816 18.0835C71.9015 18.4819 71.0925 18.6811 70.1548 18.6811ZM70.1668 16.6889C70.5934 16.6889 70.9496 16.5682 71.2354 16.3267C71.5211 16.0812 71.7364 15.7472 71.8813 15.3246C72.0302 14.902 72.1047 14.4211 72.1047 13.8818C72.1047 13.3425 72.0302 12.8615 71.8813 12.4389C71.7364 12.0164 71.5211 11.6823 71.2354 11.4368C70.9496 11.1913 70.5934 11.0686 70.1668 11.0686C69.7362 11.0686 69.374 11.1913 69.0802 11.4368C68.7904 11.6823 68.5711 12.0164 68.4222 12.4389C68.2773 12.8615 68.2048 13.3425 68.2048 13.8818C68.2048 14.4211 68.2773 14.902 68.4222 15.3246C68.5711 15.7472 68.7904 16.0812 69.0802 16.3267C69.374 16.5682 69.7362 16.6889 70.1668 16.6889Z" fill="white"/>
            <path d="M91.2825 10.4649H88.6383C88.59 10.1228 88.4914 9.81892 88.3425 9.55329C88.1936 9.28364 88.0024 9.05424 87.769 8.86508C87.5356 8.67592 87.2659 8.53104 86.9601 8.43042C86.6582 8.32981 86.3302 8.2795 85.9761 8.2795C85.3361 8.2795 84.7787 8.43847 84.3038 8.75642C83.8289 9.07034 83.4607 9.52914 83.1991 10.1328C82.9375 10.7325 82.8067 11.461 82.8067 12.3182C82.8067 13.1996 82.9375 13.9401 83.1991 14.5398C83.4647 15.1395 83.835 15.5922 84.3099 15.8981C84.7848 16.204 85.3341 16.3569 85.9579 16.3569C86.3081 16.3569 86.6321 16.3106 86.9299 16.2181C87.2317 16.1255 87.4994 15.9907 87.7328 15.8136C87.9662 15.6325 88.1594 15.4131 88.3123 15.1556C88.4693 14.898 88.578 14.6042 88.6383 14.2742L91.2825 14.2862C91.2141 14.8537 91.0431 15.4011 90.7694 15.9283C90.4997 16.4515 90.1355 16.9204 89.6767 17.3349C89.2219 17.7454 88.6786 18.0714 88.0467 18.3129C87.4189 18.5503 86.7085 18.6691 85.9157 18.6691C84.8129 18.6691 83.8269 18.4195 82.9576 17.9205C82.0923 17.4214 81.4081 16.699 80.905 15.7532C80.406 14.8074 80.1564 13.6624 80.1564 12.3182C80.1564 10.97 80.41 9.82294 80.9171 8.87716C81.4242 7.93137 82.1124 7.21096 82.9817 6.71593C83.8511 6.21688 84.829 5.96735 85.9157 5.96735C86.6321 5.96735 87.2961 6.06797 87.9079 6.2692C88.5236 6.47043 89.069 6.76423 89.5439 7.15059C90.0188 7.53293 90.4052 8.0018 90.703 8.5572C91.0048 9.1126 91.198 9.74848 91.2825 10.4649Z" fill="white"/>
            <path d="M95.7529 18.6751C95.1612 18.6751 94.634 18.5725 94.1712 18.3672C93.7084 18.1579 93.3421 17.85 93.0725 17.4436C92.8068 17.0331 92.674 16.5219 92.674 15.9102C92.674 15.395 92.7686 14.9624 92.9578 14.6122C93.1469 14.2621 93.4045 13.9804 93.7305 13.7671C94.0565 13.5538 94.4267 13.3928 94.8413 13.2841C95.2598 13.1755 95.6985 13.099 96.1573 13.0547C96.6966 12.9984 97.1313 12.946 97.4613 12.8978C97.7913 12.8454 98.0308 12.769 98.1797 12.6683C98.3286 12.5677 98.4031 12.4188 98.4031 12.2216V12.1854C98.4031 11.8031 98.2823 11.5072 98.0409 11.298C97.8034 11.0887 97.4653 10.984 97.0267 10.984C96.5638 10.984 96.1956 11.0867 95.9219 11.2919C95.6482 11.4932 95.4671 11.7467 95.3786 12.0526L93 11.8594C93.1208 11.296 93.3582 10.809 93.7124 10.3985C94.0665 9.98392 94.5233 9.66598 95.0828 9.44463C95.6462 9.21925 96.2982 9.10656 97.0387 9.10656C97.5539 9.10656 98.0469 9.16693 98.5178 9.28767C98.9927 9.40841 99.4133 9.59555 99.7795 9.8491C100.15 10.1027 100.442 10.4286 100.655 10.8271C100.868 11.2215 100.975 11.6944 100.975 12.2458V18.5H98.5359V17.2142H98.4634C98.3145 17.5039 98.1153 17.7595 97.8658 17.9808C97.6163 18.1982 97.3164 18.3692 96.9663 18.494C96.6161 18.6147 96.2117 18.6751 95.7529 18.6751ZM96.4894 16.9002C96.8677 16.9002 97.2017 16.8258 97.4915 16.6769C97.7813 16.5239 98.0087 16.3187 98.1737 16.0611C98.3387 15.8035 98.4212 15.5117 98.4212 15.1857V14.2017C98.3407 14.254 98.23 14.3023 98.0892 14.3466C97.9523 14.3869 97.7974 14.4251 97.6243 14.4613C97.4513 14.4935 97.2782 14.5237 97.1051 14.5519C96.9321 14.576 96.7751 14.5982 96.6343 14.6183C96.3324 14.6625 96.0688 14.733 95.8434 14.8296C95.618 14.9262 95.443 15.057 95.3182 15.222C95.1934 15.383 95.1311 15.5842 95.1311 15.8257C95.1311 16.1758 95.2578 16.4434 95.5114 16.6286C95.769 16.8097 96.095 16.9002 96.4894 16.9002Z" fill="white"/>
            <path d="M102.97 18.5V9.2273H105.542V18.5H102.97ZM104.262 8.03198C103.88 8.03198 103.552 7.90521 103.278 7.65166C103.008 7.39408 102.873 7.0862 102.873 6.72801C102.873 6.37384 103.008 6.06998 103.278 5.81643C103.552 5.55885 103.88 5.43007 104.262 5.43007C104.644 5.43007 104.97 5.55885 105.24 5.81643C105.514 6.06998 105.65 6.37384 105.65 6.72801C105.65 7.0862 105.514 7.39408 105.24 7.65166C104.97 7.90521 104.644 8.03198 104.262 8.03198Z" fill="white"/>
            <path d="M109.751 9.2273L111.453 12.4691L113.198 9.2273H115.836L113.15 13.8637L115.909 18.5H113.283L111.453 15.2944L109.654 18.5H106.998L109.751 13.8637L107.095 9.2273H109.751Z" fill="white"/>
            <path d="M119.958 18.6751C119.366 18.6751 118.839 18.5725 118.376 18.3672C117.913 18.1579 117.547 17.85 117.278 17.4436C117.012 17.0331 116.879 16.5219 116.879 15.9102C116.879 15.395 116.974 14.9624 117.163 14.6122C117.352 14.2621 117.61 13.9804 117.936 13.7671C118.262 13.5538 118.632 13.3928 119.046 13.2841C119.465 13.1755 119.904 13.099 120.362 13.0547C120.902 12.9984 121.336 12.946 121.666 12.8978C121.996 12.8454 122.236 12.769 122.385 12.6683C122.534 12.5677 122.608 12.4188 122.608 12.2216V12.1854C122.608 11.8031 122.487 11.5072 122.246 11.298C122.008 11.0887 121.67 10.984 121.232 10.984C120.769 10.984 120.401 11.0867 120.127 11.2919C119.853 11.4932 119.672 11.7467 119.584 12.0526L117.205 11.8594C117.326 11.296 117.563 10.809 117.917 10.3985C118.272 9.98392 118.728 9.66598 119.288 9.44463C119.851 9.21925 120.503 9.10656 121.244 9.10656C121.759 9.10656 122.252 9.16693 122.723 9.28767C123.198 9.40841 123.618 9.59555 123.985 9.8491C124.355 10.1027 124.647 10.4286 124.86 10.8271C125.073 11.2215 125.18 11.6944 125.18 12.2458V18.5H122.741V17.2142H122.669C122.52 17.5039 122.32 17.7595 122.071 17.9808C121.821 18.1982 121.522 18.3692 121.171 18.494C120.821 18.6147 120.417 18.6751 119.958 18.6751ZM120.694 16.9002C121.073 16.9002 121.407 16.8258 121.697 16.6769C121.986 16.5239 122.214 16.3187 122.379 16.0611C122.544 15.8035 122.626 15.5117 122.626 15.1857V14.2017C122.546 14.254 122.435 14.3023 122.294 14.3466C122.157 14.3869 122.002 14.4251 121.829 14.4613C121.656 14.4935 121.483 14.5237 121.31 14.5519C121.137 14.576 120.98 14.5982 120.839 14.6183C120.537 14.6625 120.274 14.733 120.049 14.8296C119.823 14.9262 119.648 15.057 119.523 15.222C119.399 15.383 119.336 15.5842 119.336 15.8257C119.336 16.1758 119.463 16.4434 119.716 16.6286C119.974 16.8097 120.3 16.9002 120.694 16.9002Z" fill="white"/>
        </svg>
    )
}

export default LCPDLogo;
