const clients = [
    {
        id: '734g698764g1892364g9',
        profile: 'pj',
        doc: '12.123.123/0001-32',
        ie: '123123123',
        fantasyName: 'Teste',
        name: 'Test MEI'
    }
];

export default clients;