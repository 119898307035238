import Counter from '../Counter';
import DropdownItem from '../DropdownItem';
import Icon from '../Icon';
import Loader from '../Loader';
import OverlayPositioned from '../OverlayPositioned/OverlayPositioned';
import Tooltip from '../Tooltip';
import './style.sass';

import { useRef, useState } from 'react';

function ButtonMulti(props) {

    const[opened, setOpened] = useState(false);
    const selfRef = useRef(null);

    const handleOpen = () => {
        if (!props.readOnly) setOpened(!opened);
    }

    const handleSelect = (option) => {
        if (option) {
            if (props.onSelect) props.onSelect(option);
        }    
        handleOpen();
    }

    return (
        <button ref={selfRef} className={`ButtonMulti ${props.look} ${props.small ? 'small' : ''} ${props.large ? 'large' : ''} ${props.full ? 'full' : ''} ${props.loading ? 'loading' : ''}`} 
            disabled={props.disabled} 
            onClick={handleOpen}>
            {
                props.tooltip && 
                <Tooltip text={props.tooltip}>
                    <div className="label">  
                        {props.count && <Counter type={props.counterType} count={props.count}/>}
                        {props.label}
                        <Icon small={props.small} name={props.look === 'more' ? 'dots-horizontal' : 'menu-down'}/>
                    </div>
                </Tooltip>
            }
            {
                !props.tooltip && <div className="label">  
                    {props.count && <Counter type={props.counterType} count={props.count}/>}
                    {props.label}
                    <Icon small={props.small} name={props.look === 'more' ? 'dots-horizontal' : 'menu-down'}/>
                </div>
            }
            {props.loading && <Loader/>}
            {
                opened && 
                <OverlayPositioned element={selfRef.current} onClose={handleOpen}>
                    <div className='Card scrollable fix'>
                        {
                            props.options && props.options.length > 0 &&
                            props.options.map((option, i) => 
                                <DropdownItem key={option.key} option={option} onSelect={handleSelect} />
                            ) 
                        }
                    </div>        
                </OverlayPositioned>
            }
        </button>
    )
}

export default ButtonMulti;
