import './style.sass';

function InputText(props) {

    const handleKeyPress = (ev) => {
        if (props.onKeyPress) props.onKeyPress(ev);
    }

    const handleChange = (ev) => {
        if (props.onChangeValue) {
            props.onChangeValue(ev);
        }
    }

    const handleFocus = (focus, ev) => {
        if (props.onChangeFocus) {
            props.onChangeFocus(focus, ev);
        } 
    }


    return (
        <div className={`InputText ${props.look || ''}`}>
            <input
                className="body1 ellipsis transition-out"
                disabled={props.disabled} 
                readOnly={props.readonly} 
                autoFocus={props.autofocus} 
                value={props.value} 
                type={props.type || "text"} 
                placeholder={props.placeholder || ' '} 
                onKeyPress={handleKeyPress} 
                onChange={handleChange} 
                onFocus={(ev) => { handleFocus(true, ev) }} 
                onBlur={(ev) => { handleFocus(false, ev) }}/>    
            <label className="caption transition-out">{props.label}{props.required ? '*' : ''}</label>
            <div className="description">{props.description}</div>
        </div>
    )
}

export default InputText;
