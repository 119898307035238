const getBubble = () => {
    return document.getElementsByClassName('App__Tooltip')[0];
}

const enterBubble = (text) => {
    const bubble = getBubble()
    if (bubble && text && text.length > 0) {
        bubble.innerHTML = text;
        bubble.classList.add('visible');
    }

}

const moveBubble = (x, y) => {
    const bubble = getBubble()
    if (bubble && bubble.innerHTML) {
        bubble.style['top'] = `${y}px`;         
        bubble.style['left'] = `${x}px`;         

        const wW = window.innerWidth;
        const wH = window.innerHeight;

        let horizontal = 'middle';
        let vertical = 'middle';

        if (x <= wW * (1/3)) horizontal = 'left';
        if (x > wW * (1/3) && x <= wW * (2/3)) horizontal = 'middle';
        if (x > wW * (2/3)) horizontal = 'right';

        if (y <= wH * (1/3)) vertical = 'top';
        if (y > wH * (1/3) && y <= wH * (2/3)) vertical = 'middle';
        if (y > wH * (2/3)) vertical = 'bottom';

        bubble.classList.remove(...bubble.classList)
        bubble.classList.add('App__Tooltip')
        bubble.classList.add('transition-out')
        bubble.classList.add('visible')
        bubble.classList.add(`${vertical}-${horizontal}`);

    }
}
const leaveBubble = () => {
    const bubble = getBubble()
    if (bubble) {
        bubble.innerHTML = '';
        bubble.classList.remove(...bubble.classList)
        bubble.classList.add('App__Tooltip')
    }
}

export {
    enterBubble,
    moveBubble,
    leaveBubble
}