import './Simulador.sass';
import { Divider, Button, OverlayPositioned, InputText, Tabs, Dropdown, Icon, ButtonDropdown, Radio } from '../../components';
import { useState, useRef } from 'react';

function Simulador(props) {

    const opts1 = [
        { key: 'resultado', label: 'Pelo resultado' },
        { key: 'receita', label: '20% sobre a receita bruta' },
    ];

    const opts2 = [
        { key: '0.075', label: '7,5%' },
        { key: '0.15', label: '15%' },
        { key: '0.225', label: '22,5%' },
        { key: '0.275', label: '27,5%' },
    ];

    const getAliquota = (value) => {
        if (value <= 22847.76) {

        } else if (value >= 22847.77 && value <= 33919.80) {
            return opts2[0];
        } else if (value >= 33919.81 && value <= 45012.60) {
            return opts2[1];
        } else if (value >= 45012.61 && value <= 55976.16) {
            return opts2[2];
        } else if (value > 55976.16) {
            return opts2[3];
        }
    }

    const [opened, setOpened] = useState(0);
    const [saldo, setSaldo] = useState(0.00);
    const [difReceita, setDifReceita] = useState(0);
    const [difDespesa, setDifDespesa] = useState(0);
    const [typeReceita, setTypeReceita] = useState('plus');
    const [typeDespesa, setTypeDespesa] = useState('plus');
    const [forma, setForma] = useState(opts1[0]);
    const [aliquota, setAliquota] = useState(opts2[0]);

    const selfRef = useRef(null);
    const receitaRef = useRef(null);
    const despesaRef = useRef(null);

    const setVal = (val, state) => {
        if (state === 'forma') setForma(val);
        if (state === 'aliquota') setAliquota(val);
    }

    const setDif = (val) => {
        if (opened === 3) setDifReceita(val);
        if (opened === 2) setDifDespesa(val);
    }

    const handleOpen = (number) => {
        if (!props.readOnly) setOpened(number);
    }

    const handleOpts = (ev) => {
        const type = ev.target.name;
        const value = ev.target.value;
        if (type === 'receita') setTypeReceita(value);
        if (type === 'despesa') setTypeDespesa(value); 
    }

    const getCurrency = (value) => {
        return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }

    const getTributavel = () => {
        const dRec = difReceita * (typeReceita === 'minus' ? -1 : 1);
        const dDesp = difDespesa * (typeDespesa === 'minus' ? -1 : 1);
        return (props.receita + dRec) - (props.despesa + dDesp);
    }

    const getResult = () => {
        let toBeReturned = null;
        
        if (forma.key === 'resultado') {
            const resultado = getTributavel();
            toBeReturned = (resultado - saldo) * Number(aliquota.key);
        } else {
            const dRec = difReceita * (typeReceita === 'minus' ? -1 : 1);
            toBeReturned = ((props.receita + dRec) * 0.2) * Number(aliquota.key);
        }

        return toBeReturned
    }
    

    return (
        <div className='Simulador'>
            <div className="Simulador__Header">
                <div>
                    <div className="h5">Simulação de Imposto de Renda *</div>
                    <div className="subtitle2">
                        Exercício {Number(props.year) + 1}  •  Ano-calendário {props.year}
                    </div>
                </div>
                <Button type="icon" icon="printer"/>
            </div>
            <div className="Simulador__Fields">
                <div className="Simulador__Fields__Field">
                    <div>Alteração de receita</div>
                    <div className="editable" ref={receitaRef}>
                        {typeReceita === "plus" ? '+ ' : '- '}{getCurrency(difReceita)}
                        <Button type="icon" small icon="pencil" onClick={() => handleOpen(3) }/>
                    </div>
                    { opened === 3 && 
                        <OverlayPositioned element={receitaRef.current} onClose={() => {handleOpen(0)}}>
                            <div className='Card scrollable fix'>                    
                                <div className="EditSaldo">
                                    <div className="Opts">
                                        <Radio label="Somar" value="plus" checked={typeReceita === 'plus'} name="receita" onChange={handleOpts}/>    
                                        <Radio label="Subtrair" value="minus" checked={typeReceita === 'minus'} name="receita" onChange={handleOpts}/> 
                                    </div>
                                    <InputText type="currency" value={opened === 3 ? difReceita : difDespesa} label="Alteração de receita" onChangeValue={(ev) => { setDif(ev.target.value) }}/>
                                </div>
                            </div>        
                        </OverlayPositioned>
                    }
                </div>
                <Divider/>
                <div className="Simulador__Fields__Field">
                    <div>Alteração de despesa</div>
                    <div className="editable" ref={despesaRef}>
                        {typeDespesa === "plus" ? '+ ' : '- '}{getCurrency(difDespesa)}
                        <Button type="icon" small icon="pencil" onClick={() => handleOpen(2) }/>
                    </div>
                    { opened === 2 && 
                        <OverlayPositioned element={despesaRef.current} onClose={() => handleOpen(0) }>
                            <div className='Card scrollable fix'>                    
                                <div className="EditSaldo">
                                    <div className="Opts">
                                        <Radio label="Somar" value="plus" checked={typeDespesa === 'plus'} name="despesa" onChange={handleOpts}/>    
                                        <Radio label="Subtrair" value="minus" checked={typeDespesa === 'minus'} name="despesa" onChange={handleOpts}/> 
                                    </div>
                                    <InputText type="currency" value={opened === 3 ? difReceita : difDespesa} label="Alteração de despesa" onChangeValue={(ev) => { setDif(ev.target.value) }}/>
                                </div>
                            </div>        
                        </OverlayPositioned>
                    }
                </div>
                <Divider/>
                <div className="Simulador__Fields__Field">
                    <div>Saldo de prejuízo(s) anterior(es)</div>
                    <div className="editable" ref={selfRef}>
                        {getCurrency(saldo)}
                        <Button type="icon" small icon="pencil" onClick={() => handleOpen(1) }/>
                    </div>
                    { opened === 1 && 
                        <OverlayPositioned element={selfRef.current} onClose={() => {handleOpen(0)}}>
                            <div className='Card scrollable fix'>                    
                                <div className="EditSaldo">
                                    <InputText value={saldo} type="currency" label="Saldo de prejuízo(s) anterior(es)" onChangeValue={(ev) => { setSaldo(Number(ev.target.value)) }}/>
                                </div>
                            </div>        
                        </OverlayPositioned> 
                    }
                </div>
                <Divider/>
                <div className="Simulador__Fields__Field">
                    <div><b>Valor tributável</b></div>
                    <div><b>{getCurrency(getTributavel())}</b></div>
                </div>
                <Divider/>
                <div className="Simulador__Fields__Field">
                    <div>Limite de 20% sobre a receita bruta total</div>
                    <div>{getCurrency(props.receita * 0.2)}</div>
                </div>
                <Divider/>
                <div className="Simulador__Fields__Field">
                    <div>Forma de apuração</div>
                    <div className="editable">
                        {forma?.label}
                        <ButtonDropdown options={opts1} type="icon" small icon="menu-down" onSelect={(s) => { setVal(s, 'forma') }}/>
                    </div>
                </div>
                <Divider/>
                <div className="Simulador__Fields__Field">
                    <div>Alíquota aplicável</div>
                    <div className="editable">
                        {aliquota ? aliquota?.label : getAliquota(getResult())?.label}
                        <ButtonDropdown options={opts2} type="icon" small icon="menu-down" onSelect={(s) => { setVal(s, 'aliquota') }}/>
                    </div>
                </div>
                <Divider/>
                <div className="Simulador__Fields__Field">
                    <div><b>RESULTADO TRIBUTÁVEL</b></div>
                    <div><b>{getResult() < 0 ? 'Isento' : getCurrency(getResult())}</b></div>
                </div>
                { getResult() < 0 && [
                    <Divider key="1"/>,
                    <div key="2" className="Simulador__Fields__Field">
                        <div>Saldo de prejuízo(s) a compensar</div>
                        <div>{getCurrency(saldo + (getTributavel() * -1))}</div>
                    </div>
                    ]
                }
            </div>
            <div className="caption">* Os dados partem de uma simulação e podem apresentar diferenças do IRPF.</div>
        </div>
    )
}

export default Simulador;