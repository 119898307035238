import { Button, ButtonDropdown, Icon, Tabs } from '../../components';
import './style.sass';
import { useState, useRef } from 'react';

function SidebarContent(props) {

    const getSidebarLocal = () => {
        return localStorage.getItem('sidebar_hidden') === 'true';
    }

    const setSidebarLocal = (value) => {
        return localStorage.setItem('sidebar_hidden', `${value}`);
    }

    const [closed, setClosed] = useState(getSidebarLocal);

    const handleSelectSide = (option) => {
        if (option.key === 'hide') {
            setClosed(true)
            setSidebarLocal('true')
        } else {
            
        }
        
    }

    const handleSelect = (option) => {
        if (option.key === 'show') {
            setClosed(false)
            setSidebarLocal('false')
        } else {
            handleChangeTab(option.key)
        }
        
    }

    const handleChangeTab = (tab) => {
        if (props.onChangeTab) props.onChangeTab(tab)
    }

    const options = [
        { key: 'hide', label: 'Esconder barra lateral' }
    ];

    return (
        <div className={`SidebarContent ${closed ? 'closed' : ''}`}>
            <div className="SidebarWrapper transition-out">
                <div className="Sidebar transition-out">
                    <Icon name={props.icon}/>
                    <ButtonDropdown icon="more-vertical" options={options} onSelect={handleSelectSide}/>
                    <div className="Header">
                        <div className="h3">{props.title}</div>
                        <div className="body">{props.subtitle}</div>
                    </div>
                    <Tabs tabs={props.tabs} type="floater2-vertical" current={props.currentTab} onChangeTab={handleChangeTab}/>

                </div>
            </div>
            <div className="Sidecontent">
                <div className="Bar">
                    {
                        closed && 
                            <div className="Title">
                                <ButtonDropdown icon="menu" options={[...props.tabs, { key: 'show', label: 'Mostrar barra lateral' }]} onSelect={handleSelect}/>
                                <div className="h5">{props.section} / {props.contentTitle}</div>
                            </div> 
                    }
                    {
                        !closed &&
                            <div className="h4">
                                {props.contentTitle}
                            </div>
                    }
                </div>
                {props.children}
            </div>
        </div>
    )
}

export default SidebarContent;
