import './style.sass';
import { Button, Dialog, Divider, Dropdown, Icon, Loader, ResizableContent, Tabs } from '../../components';
import { useState, useEffect, useRef } from 'react';
import IndGraph from './IndGraph';
import { cloneDeep, findIndex, sortBy } from 'lodash';

import uniqid from 'uniqid';

import DataInput from './DataInput';

import { generateDates, translateDate, dateToKey, hasDatePassed, replaceKpiData } from './libs'

import { listenIndicadores, updateOrder, getIndicadoresSettings, createIndicador, saveIndicador, deleteIndicador } from '../../services/docFunctions'

function Indicadores(props) {

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState('2024-01-01');
    const [endDate, setEndDate] = useState('2024-12-31');
    const [editing, isEditing] = useState(false);
    const [loading, setLoading] = useState(true);

    const [users, setUsers] = useState([]);

    const orderData = (_data, order) => {
        const sortedCollection = sortBy(_data, (item) => {
            return order.indexOf(item.id) !== -1 ? order.indexOf(item.id) : _data.length;
        });

        return sortedCollection;
    }
    
    useEffect(() => {

        //Data Listener
        setLoading(true);
        const listener = listenIndicadores((snapshot) => {
            const iData = [];
            snapshot.forEach((doc) => {
                iData.push(doc.data());
            });    

            getIndicadoresSettings()
                .then((settings) => {

                    const nUsers = []
                    Object.keys(settings.users).forEach(email => {
                        nUsers.push({ key: email, label: settings.users[email].name })
                    });
                    setUsers(nUsers);

                    setData(orderData(iData, settings.order))
                    if (savedData.current.length === 0) savedData.current = orderData(iData, settings.order);
                    if (!dataCheck.current) dataCheck.current = orderData(iData, settings.order);
                })
                .catch()
                .finally(() => {
                    setLoading(false);
                })

        });

        return () => {
            listener();
        }
    }, []);

    const addNewIndicador = () => {
        setLoading(true);
        createIndicador()
            .then((id) => {
                setLoading(true)
                const nOrder = data.map(k => k.id)
                nOrder.push(id)
                updateOrder(nOrder)
                    .then()
                    .finally(() => {
                        setLoading(false)
                    });
            })
            .catch(() => {

            })
            .finally(() => {
                setLoading(false);
            })
    }

    const [dragging, setDragging] = useState(true);
    const [hovered, setHovered] = useState(null);

    const handleDragStart = (ev, id) => {
        setDragging(id);
    }
    const handleDragEnd = (ev, id) => {
        setDragging(null);
        setHovered(null);
    }
    const handleDragEnter = (id, side) => {
        setTimeout(() => {
            setHovered(id+'-'+side);
        }, 1);
    }
    const handleDragLeave = () => {
        // setHovered(null);
    }
    const handleDrop = (target, side) => {

        const nData = cloneDeep(data);
        const idxMoving = findIndex(nData, d => d.id === dragging);

        const idxDict = {
           up: 0,
           down: 1 
        }
        const movingObj = nData.splice(idxMoving, 1)[0]
        const idxTarget = findIndex(nData, d => d.id === target);

        nData.splice(idxTarget + idxDict[side], 0, movingObj);

        setData(nData);
        dataCheck.current = nData;

        setLoading(true)
        const nOrder = nData.map(k => k.id)
        updateOrder(nOrder)
            .then()
            .finally(() => {
                setLoading(false)
            });

        setDragging(null);
        setHovered(null);
    }
    const handleDragOver = (ev) => {
        ev.preventDefault();
        return false;
    }

    const KPIAction = (props) => {
        const focusOn = () => {
            if (!isHidden) {
                const el = document.getElementById(props.kpi.id);
                if (el) el.scrollIntoView({behavior: 'smooth', block: 'center'})    
            }
        }
        const isHidden = currentTimeView !== props.kpi.period && props.kpi.period !== 'both';
        return (
            <div className={`KPIAction ${isHidden ? 'hidden' : ''}`} onDragStart={(ev)=>{handleDragStart(ev, props.kpi.id)}}  onDragEnd={(ev)=>{handleDragEnd(ev, props.kpi.id)}} draggable onClick={focusOn}>
                <div
                    key={`${props.kpi.id}-up`}
                    className={`DropZone__Up ${hovered === `${props.kpi.id}-up` ? 'over': ''}`} 
                    dropzone="move" 
                    onDragEnter={()=>{handleDragEnter(props.kpi.id, 'up')}} 
                    onDragLeave={()=>{handleDragLeave()}} 
                    onDragOver={handleDragOver} 
                    onDrop={()=>{handleDrop(props.kpi.id, 'up')}}></div>
                <div
                    key={`${props.kpi.id}-down`}
                    className={`DropZone__Down ${hovered === `${props.kpi.id}-down` ? 'over': ''}`} 
                    dropzone="move" 
                    onDragEnter={()=>{handleDragEnter(props.kpi.id, 'down')}} 
                    onDragLeave={()=>{handleDragLeave()}} 
                    onDragOver={handleDragOver} 
                    onDrop={()=>{handleDrop(props.kpi.id, 'down')}}></div>
                <div className="KPIAction__Content">
                    <Icon small name="drag-vertical"/>
                    {props.kpi.title || `Sem título`}
                </div>
            </div>
        )
    }

    const getDataIndex = (id) => {
        return findIndex(data, d => d.id === id);
    }

    const timeViewDict = {
        month: 'mdata',
        week: 'wdata'
    };

    const timeViewOptions = [
        { key: 'week', name: 'Semanal' },
        { key: 'month', name: 'Mensal' }
    ];

    const [currentTimeView, setCurrentTimeView] = useState('week');

    const [trafficLight, setTrafficLight] = useState('green')

    const dataCheck = useRef(null);
    const savedData = useRef([]);

    const updateData = (id) => {
        setTimeout(() => {
            if (JSON.stringify(savedData.current[getDataIndex(id)]) !== JSON.stringify(dataCheck.current[getDataIndex(id)])) {
                if (trafficLight === 'green') {
                    saveData(id)
                }
            }
        }, 0);
    }

    const saveData = (id) => {
        setTrafficLight('red');
        setLoading(true)
        saveIndicador(dataCheck.current[getDataIndex(id)])
            .then(() => {
                savedData.current = dataCheck.current;
                const nOrder = dataCheck.current.map(k => k.id)
                updateOrder(nOrder)
                    .then()
                    .finally(() => {
                        setLoading(false)
                    });
            })
            .catch()
            .finally(() => {
                setLoading(false);
                setTimeout(() => {
                    setTrafficLight('green');
                    updateData(id)
                }, 3000);
            })
    }

    const removeIndicador = (id) => {
        setLoading(true)
        deleteIndicador(id)
            .then(() => {
            })
            .catch()
            .finally(() => {
                setLoading(false);
            })
    }

    const handleEvents = (event, data) => {
        if (event === 'edit') isEditing(data.id === editing ? false : data.id);
        if (event === 'remove') removeIndicador(data.id);
    }
    
    const changeData = (index, key, ev) => {
        const nData = cloneDeep(data);
        const idx = findIndex(nData, d => d.id === editing);
        nData[idx].data[index][timeViewDict[currentTimeView]][key] = Number(ev.target.value || 0);
        setData(nData);
        dataCheck.current = nData;
        updateData(editing);
    }

    const handleGraphChanges = (key, _data, id) => {
        const nData = cloneDeep(data);
        const idx = findIndex(nData, d => d.id === id);
        if (key === 'title' || key === 'link' || key === 'period' || key === 'datatype') {
            nData[idx][key] = _data.target.value;
        } else if (key === 'view' || key === 'source' || key === 'owner') {
            nData[idx][key] = _data.key;
        } 
        setData(nData);
        dataCheck.current = nData;
        updateData(id);
    }

    const handleGroupName = (ev, i) => {
        const nData = cloneDeep(data);
        const idx = findIndex(nData, d => d.id === editing);
        nData[idx].data[i].name = ev.target.value;
        setData(nData);
        dataCheck.current = nData;
        updateData(editing);
    }

    const handleGroups = (action, index) => {
        const nData = cloneDeep(data);
        const idx = findIndex(nData, d => d.id === editing);

        if (action === 'add') {
            nData[idx].data.push({
                id: uniqid(),
                name: '',
                wdata: {},
                mdata: {}
            });
        }
        if (action === 'remove') {
            nData[idx].data.splice(index, 1)
        }

        setData(nData);
        dataCheck.current = nData;
        updateData(editing);
    }

    const [showRemoveGroupDialog, setRemoveGroupDialog] = useState(false);
    const [groupName, setGroupName] = useState("");
    const groupIndexToRemove = useRef();

    const handleRemoveGroupChoice = (choice) => {
        if (choice) handleGroups('remove', groupIndexToRemove.current);
        setRemoveGroupDialog(false);
    }

    return (
        <div className='Indicadores'>  
            <ResizableContent
                min={300}
                max={500}
                a={
                    <div className="Indicadores__Menu">
                        <div className="Indicadores__Menu__Top">
                            KPIS
                            <Button type="icon" icon="plus" onClick={addNewIndicador}/>
                        </div>
                        <Divider/>
                        <div className="Indicadores__Menu__Bottom scrollable">
                            { data.map(kpi => <KPIAction key={kpi.id} kpi={kpi}/>) }
                        </div>
                    </div>
                }
                b={
                    <div className="Indicadores__Content">
                        <div className="Indicadores__Content__Top">
                            {/* <Button type="icon" icon="arrow-left"/>
                            <Button type="icon" icon="arrow-right"/> */}
                            <div>{ loading && <Loader/>}</div>
                            <Tabs type="floater" current={currentTimeView} tabs={timeViewOptions} onChangeTab={(tab)=>{setCurrentTimeView(tab)}}/>
                        </div>
                        <Divider/>
                        <div className="Indicadores__Content__Graphs scrollable">
                            { 
                                data.filter(k => currentTimeView === k.period || k.period === 'both').map(kpi => 
                                    <IndGraph key={kpi.id} startDate={startDate} endDate={endDate} period={currentTimeView} kpi={replaceKpiData(startDate, endDate, kpi, currentTimeView)} users={users} onChange={handleGraphChanges} onEvent={handleEvents}/>
                                ) 
                            }
                        </div>
                    </div>
                }
            />
            <div className={`DataEditor ${editing ? 'editing' : ''} transition-out`}>
                <div className="DataEditor__Header">
                    <div className="h6">{editing && data[getDataIndex(editing)].title || 'Sem título'}</div>
                    <Button type="icon" icon={'close'} onClick={()=>{isEditing(false)}}/>
                </div>
                <Divider/>
                <div className="DataEditor__Content scrollable">
                    <div className="DataEditor__Content__EditLabels">
                        <div className="empty"></div>
                        {
                            editing && data[getDataIndex(editing)].data.map((group,i) => (
                                <div key={group.id+1} className="label">
                                    <Button small icon="close" type="icon" onClick={()=>{setRemoveGroupDialog(true); setGroupName(group.name || `Series ${i+1}`); groupIndexToRemove.current = i}}/>
                                    <input key={group.id+1} type="text" defaultValue={group.name} placeholder={`Series ${i+1}`} onChange={(ev)=>{handleGroupName(ev, i)}}/>
                                </div>
                            ))
                        }
                        { showRemoveGroupDialog && 
                            <Dialog
                                message={`Deseja remover a série '${groupName}'?`}
                                submessage="Esta ação é irreversível"
                                primaryLabel="Sim"
                                onChoice={handleRemoveGroupChoice}
                            /> }
                        <Button icon="plus" small type="icon" onClick={()=>{handleGroups('add')}}/>
                    </div>
                    {
                        editing && generateDates(undefined, undefined, currentTimeView).map((date, i1) => (
                            hasDatePassed(date) && 
                                <div key={translateDate(date)} className="DataEditor__Content__EditGroup">
                                    <div className="label">{translateDate(date)}</div>
                                    {
                                        data[getDataIndex(editing)].data.map((group, i2) => (
                                            <DataInput key={group.id+translateDate(date)} label={date} value={data[getDataIndex(editing)].data[i2][timeViewDict[currentTimeView]][dateToKey(date)] || undefined} onChange={(ev) => changeData(i2, dateToKey(date), ev)}/>
                                        ))
                                    }
                                </div>        
                            
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Indicadores;