import Icon from '../Icon';
import './style.sass';

function FlashSwitch(props) {

    const handleClick = () => {
        if (props.onChangeActive) props.onChangeActive(!props.active)
    }

    return (
        <div className={`FlashSwitch ${props.active ? 'active' : ''}`} onClick={handleClick}>
            <div className='FlashSwitch__Handler transition-out'>
                <Icon small name='flash'/>
            </div>
        </div>
    )
}

export default FlashSwitch;
