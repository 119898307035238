import { useRef, useState } from 'react';
import { DropdownItem, InputText, OverlayPositioned, Icon, Divider, Button } from '..';
import { cloneDeep, kebabCase } from 'lodash';
import './style.sass';

function Dropdown(props) {

    const getSelectedOptionFromKey = (key) => {
        return props.options.filter(n => n.key === key)[0]
    }

    const [opened, setOpened] = useState(false);
    const [current, setCurrent] = useState(getSelectedOptionFromKey(props.selected));
    const selfRef = useRef(null);

    const handleOpen = () => {
        // console.log('hey');
        if (!props.readOnly) setOpened(!opened);
    }

    const handleFocus = (focus) => {
        if (focus) setOpened(focus)
        else setTimeout(() => {
            setOpened(focus)
        }, 100);
    }

    const OptionList = () => {

        const [filter, setFilter] = useState();

        const filterList = (ev) => {
            setFilter(ev.target.value);
        }
    
        const fList = (list) => {
            const nList = cloneDeep(list);
            if (filter && filter.length) {
                return nList.filter(i => kebabCase(i.label).includes(kebabCase(filter)));
            }
            return list
        }

        const handleSelect = (option) => {
            if (option) {
                setFilter(option.label)
                setCurrent(option)
                if (props.onSelect) props.onSelect(option);
            }    
            handleOpen();
        }

        return (
            <OverlayPositioned element={selfRef.current} onClose={handleOpen}>
                <div className='DropdownCard Card fix'>
                    <div className="DropdownSearchBar">
                        <Icon small name="magnify"/>
                        <input type="text" placeholder="Buscar" onChange={filterList}/>
                    </div>
                    <Divider/>
                    <div className='DropdownList'>
                    {
                        fList(props.options) && fList(props.options).length > 0 &&
                        fList(props.options).map((option, i) => 
                            <DropdownItem key={option.key+i} option={option} onSelect={handleSelect} />
                        ) 
                    }
                    </div>
                </div>        
            </OverlayPositioned>
        )
    }

    return (
        <div className={`Dropdown ${props.look || ''}`} ref={selfRef}>
            <div onClick={()=>{handleFocus(true)}}>
                <Button type="icon" icon="menu-down"/>
                <InputText look={props.look} disabled required={props.required} value={current?.label} label={props.label}/>
            </div>
            { opened && <OptionList/>}
        </div>
    )
}

export default Dropdown;
