import { Button, ButtonDropdown, Checkbox, Divider, Icon, SearchBar, Tabs } from '../../components';
import { useState } from 'react';
import { AegroMenu, SidebarContent } from '../../composed_components';

function NFeTeamRoles(props) {
    
    return (
        <div className='NFeTeamRoles'>        
            <div>
                <div className="overline">Proprietário</div>
                <p className="body2">
                    Acesso total à propriedade e ao administrativo. Pode adicionar usuários, alterar perfis e controlar assinaturas.
                </p>
                <div className="overline">Gerente</div>
                <p className="body2">
                    Tem acesso total à propriedade. Pode adicionar usuários e alterar perfis.
                </p>
                <div className="overline">Agronômico</div>
                <p className="body2">
                    Pode editar áreas, safras, atividades, colheita, observações, orçamentos, abastecimentos e manutenções.
                </p>
                <div className="overline">Financeiro</div>
                <p className="body2">
                    Pode editar as contas e orçamentos, patrimônio, abastecimentos e colheita.
                </p>
                <div className="overline">Estoque</div>
                <p className="body2">
                    Pode editar patrimônio, estoque e movimentações.
                </p>
                <div className="overline">Operacional</div>
                <p className="body2">
                    Apenas visualiza áreas e safras. Pode adicionar observações e realizações de atividades mas não pode alterar o seu planejamento.
                </p>
            </div>   
        </div>
    )
}

export default NFeTeamRoles;