import Counter from '../Counter';
import Icon from '../Icon';
import Loader from '../Loader';
import Tooltip from '../Tooltip';
import './style.sass';

function Button(props) {

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    return (
        <button className={`Button ${props.type || 'default'} ${props.small ? 'small' : ''} ${props.large ? 'large' : ''} ${props.full ? 'full' : ''} ${props.loading ? 'loading' : ''}`} 
            disabled={props.disabled} 
            onClick={handleClick}>
            {
                props.tooltip && 
                <Tooltip text={props.tooltip}>
                    <div className="label">  
                        {props.count && <Counter type={props.counterType} count={props.count}/>}
                        {props.icon && <Icon small={props.small} name={props.icon}/>}
                        {props.label}
                    </div>
                </Tooltip>
            }
            {
                !props.tooltip && <div className="label">  
                    {props.count && <Counter type={props.counterType} count={props.count}/>}
                    {props.icon && <Icon small={props.small} name={props.icon}/>}
                    {props.label}
                </div>
            }
            {props.loading && <Loader/>}
        </button>
    )
}

export default Button;
