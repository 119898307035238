import { Button, Icon, Tabs } from '../../components';
import { find } from 'lodash';
import './JobsToBeDone.sass';


function JobsToBeDone(props) {

    const getTeam = (id) => {
        const team = find(props.other.teams, t => t.id === id);
        return team || { id: '', name: 'team not found' }
    }

    const getTeams = (deliverables) => {
        if (!deliverables) return "Sem time";        
        const allTeamIds = deliverables.map(d => d.teamId);
        const nTeams = [...new Set(allTeamIds)];
        const names = []
        nTeams.forEach(id => names.push(getTeam(id).name))
        return names.join(',');
    }

    const getKR = (id) => {
        const kr = find(props.other.krs, k => k.id === id);
        return kr || { id: '', description: 'kr not found' }
    }

    const getJTBD = (id) => {
        const JTBD = find(props.other.JTBDs, j => j.id === id);
        return JTBD || { id: '', description: 'JTBD not found' }
    }
 
    const getPosition = (dateStart) => {
        const start = dateStart.split('-');
        const monthPos = (Number(start[1]) - 1) * props.lengths.month;
        const dayPos = (Number(start[2]) - 1) * props.lengths.day;
        return monthPos + dayPos;
    }

    const getWidth = (deliverable) => {
        const start = deliverable.upstream.start.split('-');
        const end = deliverable.downstream.end.split('-');

        const monthStart = (Number(start[1]) - 1) * props.lengths.month;
        const dayStart = (Number(start[2]) - 1) * props.lengths.day;
        const monthEnd = (Number(end[1]) - 1) * props.lengths.month;
        const dayEnd = (Number(end[2]) - 1) * props.lengths.day;

        return (monthEnd + dayEnd) - (monthStart + dayStart);
    }

    const handleOpen = (deliverable) => {
        if (props.onOpen) {
            props.onOpen(deliverable);
        }
    } 

    const handleClickBg = (ev) => {
        if (props.onStartDrag) {
            props.onStartDrag(ev);
        }
    }

    const hasIntersection = (data, index) => {
        const allItems = [];

        data.deliverables.forEach(d => {
            allItems.push([getPosition(d.upstream.start), getWidth(d)])
        });        
        
        if (allItems.length === 0) return 0;
        if (allItems.length === 1) return 0;

        const inters = [];

        allItems.forEach((item, i) => {
            if (i !== index) {
                if (i < index) {
                    inters.push(allItems[index][0] < (item[0]+item[1]))
                } else if (i > index) {
                    inters.push(item[0] < (allItems[index][0]+allItems[index][1]))
                }
            } else {
                // inters.push('hey')
            }
        });

        const uniq = [...new Set(inters)];
 
        return { intersect: uniq[0] === true || uniq.length > 1, count: inters.length + 1 };
    }

    const getStyle = (deliverable, i) => {
        const intersct = hasIntersection(props.data, i);

        const stl = { 
            left: getPosition(deliverable.upstream.start), 
            width: getWidth(deliverable), 
        }

        if (intersct.intersect) {
            stl.height = `calc(${100/intersct.count}% - ${1/intersct.count}rem)`;
            if (i === 0) {

            } else {
                stl.top = `calc((${(100/intersct.count)}% - ${1/intersct.count}rem) * ${i} + .5rem)` ;
            }
        }

        return stl
    }
    

    return (
        <div className={`JobsToBeDone ${props.type}`} style={{ height: props.vScale }}>  
            { props.type === 'title' &&
                <div className="JobsToBeDone__Title">
                    <Icon name='flag-checkered'/>
                    <div className="Info">
                        <div className="caption">{getTeams(props.data.deliverables)}</div>
                        <div className="subtitle"><b>{props.data.description}</b></div>
                    </div>
                </div>
            }
            { props.type === 'gantt' && 
                <div className={`JobsToBeDone__Gantt`}>
                    <div className='clickBg' onMouseDown={handleClickBg}></div>
                    {
                        props.data.deliverables && props.data.deliverables.map((deliverable, i) =>
                            <div key={i} className={`deliverable ${hasIntersection(props.data, i).intersect ? 'intersect' : ''} transition-out`} style={getStyle(deliverable, i)} onClick={ () => { handleOpen(deliverable) }}>
                                <div className={`statusbar ${deliverable.status}`}></div>
                                <div className="label ellipsis">{props.data.description}</div>
                                <div className="stage ellipsis">{deliverable.name}</div>
                            </div>
                    )}
                </div>
            }
        </div>
    )
}

export default JobsToBeDone;