import { Button, Divider, Dropdown, HStack, Icon, InputCurrency, InputDate, InputText, Loader, OverlayPositioned, Radio, StatusTag, StructurePane, Tabs, VStack } from "../../../../components"
import { useState, useEffect, useRef } from 'react';

import * as pdfjsLib from "pdfjs-dist";

function PDFViewer(props) {

    const canvasRef = useRef();

    useEffect(() => {

        pdfjsLib.GlobalWorkerOptions.workerSrc = '../../../../../node_modules/pdfjs-dist/build/pdf.worker.mjs';

        const loadingTask = pdfjsLib.getDocument('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf');
        loadingTask.promise
            .then((pdf) => {
                console.log('pdf loaded', pdf);

                pdf.getPage(1)
                    .then((page) => {
                        console.log('page loaded');

                        let scale = 1.5;
                        let viewport = page.getViewport({scale});

                        const canvas = canvasRef.current;
                        const canvasContext = canvas.getContext('2d');
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;

                        const renderTask = page.render({
                            canvasContext,
                            viewport,
                        });

                        renderTask.promise
                            .then(() => {
                                console.log('renderend');
                            });
                        
                    })
            }, (rejectReason) => {
                console.error(rejectReason);
            })
    },  []);

    return (
        <div className={`PDFViewer`}>
            <canvas ref={canvasRef}></canvas>
        </div>
    )
}

export default PDFViewer;