// Carta de correção
// Rejeição
// Autorização
// Cancelamento
// Extra: Loading do enviando nota (não é evento mas aparecia ali)

const nfeStatuses = {
    writing: ['Em digitação', 'neutral-low'],
    processing: ['Em processamento', 'neutral'],
    ready: ['Pronta para envio', 'success-low'],
    authorized: ['Autorizada', 'success'],
    cancelled: ['Cancelada', 'disabled'],
    denied: ['Denegada', 'disabled'],
    rejected: ['Rejeitada', 'error'],
}

const nfeEvents = {
    correct: {
        done: {
            text: 'Carta de correção enviada.',
            action: {
                label: 'Baixar',
                icon: 'printer',
                link: ''
            }
        },
        working: {
            text: 'Enviando carta de correção.',
        },
        error: {
            text: 'Erro ao enviar carta de correção.',
        },
    },
    reject: {
        done: {
            text: 'Nota rejeitada.',
        },
        working: {
            text: 'Rejeitando nota.',
        },
        error: {
            text: 'Erro ao rejeitar nota.',
        },
    },
    authorize: {
        done: {
            text: 'Nota autorizada.',
        },
        working: {
            text: 'Autorizando nota.',
        },
        error: {
            text: 'Erro ao autorizar nota.',
        },
    },
    cancel: {
        done: {
            text: 'Nota cancelada.',
        },
        working: {
            text: 'Cancelando nota.',
        },
        error: {
            text: 'Erro ao cancelar nota.',
        },
    }
}

const nfeModel = {
    id: '38274g8327f6487236g4823',
    issuer: null,
    recipient: null,
    status: 'authorized',
    history: [
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'correct',
            status: 'done',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'correct',
            status: 'working',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'correct',
            status: 'error',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'reject',
            status: 'done',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'reject',
            status: 'working',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'reject',
            status: 'error',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'reject',
            status: 'done',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'authorize',
            status: 'done',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'authorize',
            status: 'working',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'authorize',
            status: 'error',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'cancel',
            status: 'done',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'cancel',
            status: 'working',
            visible: true
        },
        {
            date: '2024-04-22T18:43:02.157Z',
            action: 'cancel',
            status: 'error',
            visible: true
        },
    ],
    info: {
        operation: '',
        date1: '',
        date2: ''
    },
    products: null,
    transport: {
        method: ''
    },
    payment: {
        method: 'pix',
        type: 'cash'
    },
    aditional: {
        fixed: 'FUNRURAL: 1,5% R$ 300,00 | SENAR: 1,5% R$ 300,00 | ICMS Diferido conforme Lei 52',
        user: null
    }
};

const gridData = {
    options: {
        selectable: false
    },
    header: [
        { data: 'Status' },
        { data: 'Data de emissão' },
        { data: 'Série' },
        { data: 'Emitente' },
        { data: 'Destinatário' },
        { data: 'Valor' },
    ],
    data: [
        [
            { data: '388.108.598.269', type: 'text' },
            { data: '07/01/2019', type: 'date' },
            { data: 'Agropecuária Andrade', type: 'client' },
            { data: '90', type: 'number' },
            { data: '5101', type: 'number' },
            { data: 'R$ -3.0000,00', type: 'currency' },
        ],
        [
            { data: '388.108.598.269', type: 'text' },
            { data: '07/01/2019', type: 'date' },
            { data: 'Agropecuária Andrade', type: 'client' },
            { data: '90', type: 'number' },
            { data: '5101', type: 'number' },
            { data: 'R$ -3.0000,00', type: 'currency' },
        ],
    ]
};

export { nfeModel, gridData, nfeStatuses, nfeEvents }