import { Button, ButtonDropdown, Checkbox, Divider, Icon, SearchBar, Tabs } from '../../components';
import { useState } from 'react';
import { AegroMenu, SidebarContent } from '../../composed_components';

function NFeTeamManage(props) {
    const filter = {
        by: 'user',
        view: 'monthly',
        date: { startDate: new Date().toISOString(), endDate: new Date().toISOString()},
        tags: [
            {
                key: 'bank-account',
                label: 'Conta bancária',
                icon: 'bank',
                type: 'multi',
                added: new Date().getTime(),
                options: [
                    { key: 'nubank', label: 'Nubank' },
                    { key: 'bradesco', label: 'Bradesco' },
                ],
                active: [{ key: 'nubank', label: 'Nubank' }]
            },
            {
                key: 'value-range',
                label: 'Valor',
                icon: 'currency',
                type: 'range',
                added: new Date().getTime(),
                min: 0.00,
                max: 10000000.00,
                step: 0.01,
                active: null,
            }
        ]
    }

    const ProfileCard = () => {
        return (
            <div className="Profile">
                <div className="Profile__User">F</div>
                <div className="Profile__Name body">Nome</div>
                <div className="Profile__Email body2">E-mail</div>
                <div className="Profile__Roles caption">Papeis</div>
            </div>
        )
    }


    return (
        <div className='NFeTeamManage'>
            <SearchBar filters={filter}/>
            <div className="overline">Proprietários</div>
            <div className="Profiles">
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
            </div>
            <div className="overline">Gerentes</div>
            <div className="Profiles">
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
            </div>
            <div className="overline">Equipe</div>
            <div className="Profiles">
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
                <ProfileCard/>
            </div>
        </div>
    )
}

export default NFeTeamManage;