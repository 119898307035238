import { useState, useRef, useEffect, useCallback } from 'react';
import './style.sass';
import Icon from '../Icon';
import Divider from '../Divider';

function Accordion(props) {

    let initialSize = 51;

    const [isOpened, setIsOpened] = useState(props.opened);
    const [size, setSize] = useState(initialSize);

    const headerRef = useRef(null);
    const contentRef = useRef(null);

    const setHeight = useCallback(() => {
        const header = headerRef?.current?.clientHeight;
        const content = contentRef?.current?.clientHeight;
        if (!isOpened) {
            setSize(header + 1);
        } else {
            setSize(header + 1 + content);
        }
    }, [isOpened])

    const HandleOpen = () => {
        setHeight();
        setIsOpened(!isOpened);
        if (props.onOpen) props.onOpen();
    }

    useEffect(() => {
        
        const observer = new ResizeObserver(() => {
            setHeight();
        })

        observer.observe(contentRef.current);
        setHeight();

        return () => {
            observer.disconnect();
        }

    }, [setHeight])

    return (
        <div className={`Accordion transition-out ${props.full ? 'full' : ''}`} style={{height:size}}>
            <div className='Header' ref={headerRef}>
                {props.header}
                <div className='Handler' onClick={HandleOpen}>
                    <Icon name={props.icon || `chevron-${ isOpened ? 'down' : 'up'}`}/>
                </div>                
            </div>
            <Divider/>
            <div className='Content' ref={contentRef}>{props.children}</div>
        </div>
    )
}

export default Accordion;