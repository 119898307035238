import { useEffect, useRef, useState } from 'react';
import './reconciliation.sass';
import ResizableContent from '../../components/ResizableContent';
import OFXCard from './components/OFXCard';

import ConciliationCart from './components/ConciliationCart';
import ConciliationRow from './components/ConciliationRow';
import SearchBar from '../../components/SearchBar';
import { cloneDeep, find, findIndex, groupBy, lowerCase, orderBy, remove } from 'lodash';
import ConciliationHeader from './components/ConciliationHeader';

import { Button, Divider, Loader, Icon } from '../../components';

import { 
    getFromLocalDB, 
    saveToLocalDB, 
    resetLocalDB,
    getInitalFiltersForOFX, 
    getSmartValueRange,
    processDate,
    handleReconciliation,
    handleJoining,
    getValid,
    getPlural,
    getTotalValue
} from './DBFunctions';
import Counter from '../../components/Counter';


function Reconciliation(props) {

    let baseOFX = useRef(null);
    let baseBILLS = useRef(null);
    let initialOFX = useRef(null);
    let initialBILLS = useRef(null);
    let initialReconcileTable = useRef(null);

    let matchMade = useRef([]);

    const [loaded, setLoaded] = useState(false);

    const [OFXlist, setOFXList] = useState(null);
    const [BILLSlist, setBILLSlist] = useState(null);
    const [connections, setConnections] = useState([]);
    const [currentItem, setCurrentItem] = useState(null); 
    const [preReconcileItem, setPreReconcileItem] = useState(null);

    const [filters, setFilters] = useState(null);
    const [smartFilters, setSmartFilters] = useState(false);
    const [textSearch, setTextSearch] = useState(null);

    const [floater, setFloater] = useState('');

    // OnMount
    useEffect(() => {
        if (!loaded) {
            getFromLocalDB()
                .then((db) => {
                    setInitials(db);
                })
                .catch();
        }
    }, []);  

    // DB Functions
    const saveData = (bOFX, bBILLS, iOFX, iBILLS, iRTable) => {
        const db = saveToLocalDB(bOFX, bBILLS, iOFX, iBILLS, iRTable);
        if (props.onChangeOFX) props.onChangeOFX(db.initialOFX.length);
    }
    const fakeImport = () => {
        const db = resetLocalDB();
        setTimeout(() => {
            setInitials(db);
        }, 0);
    }  

    // Initialization
    const setInitials = (db) => {
        if (props.onChangeOFX) props.onChangeOFX(db.initialOFX.length); 
        // Base
        baseOFX.current = db.baseOFX;
        baseBILLS.current = db.baseBILLS;
        //Initials and States
        initialOFX.current = db.initialOFX;
        initialBILLS.current = db.initialBILLS;
        initialReconcileTable.current = db.initialReconcileTable;        
        setBILLSlist(cloneDeep(initialBILLS.current));
        setOFXList(cloneDeep(initialOFX.current)); 
        //Handle basic logic
        setFilters(getInitalFiltersForOFX(initialOFX.current[0]))
        setCurrentItem(initialOFX.current[0]);
        setBILLSlist(applyTagFiltersToList(getInitalFiltersForOFX(initialOFX.current[0]))); 
        // Set Loaded
        setLoaded(true);
    }

    // Filter Functions
    const handleType = (string) => {
        setTextSearch(string);
        // const nBills = cloneDeep(initialBILLS.current);
        const nBills = applyTagFiltersToList(filters);
        setBILLSlist(nBills.filter(b => lowerCase(JSON.stringify(b)).indexOf(lowerCase(string)) > -1))
    }
    const handleChangeSmartFilter = (active) => {
        setSmartFilters(active);
        if (active) setSmartFilter(currentItem);
    }
    const handleChangeFilters = (fltrs) => {
        setFilters(fltrs);
        // setSmartFilters(fltrs.by === 'smart');
        setBILLSlist(applyTagFiltersToList(fltrs)); 
    }
    const setSmartFilter = (OFX) => {
        const nFilters = cloneDeep(filters);
        nFilters.by = 'smart';
        if (OFX) {
            nFilters.view = 'custom';
            nFilters.tags[0].active = [{ key: 'nubank', label: 'Nubank' }];
            nFilters.tags[1].active = [getSmartValueRange(OFX)];
            nFilters.date = { startDate: OFX.date, endDate: OFX.date};
        }
        handleChangeFilters(nFilters);
    }
    const applyTagFiltersToList = (fltrs) => {
        let newBILLSlist = cloneDeep(initialBILLS.current);
        const f1 = fltrs.tags[0].active;
        const f2 = fltrs.tags[1].active

        if (f1 && f1.length) {
            newBILLSlist = newBILLSlist.filter(b => JSON.stringify(f1).indexOf(b.account) > -1);
        }
        if (f2 && f2.length) {
            newBILLSlist = newBILLSlist.filter(b => b.value >= f2[0].min && b.value <= f2[0].max );
        } 
        if (fltrs.date) {
            const dateMin = new Date(fltrs.date.startDate).getTime();
            const dateMax = new Date(fltrs.date.endDate).getTime();
            newBILLSlist = newBILLSlist.filter(b => new Date(b.date).getTime() >= dateMin && new Date(b.date).getTime() <= dateMax);
        }       
        return newBILLSlist;
    }
    const clearFilters = () => {
        const fltrs = cloneDeep(filters);
        fltrs.by = 'user'
        fltrs.tags[0].active = null;
        fltrs.tags[1].active = null;
        fltrs.view = 'monthly';
        handleChangeFilters(fltrs);
    }
    const hasActiveFilters = (filts) => {
        const fltrs = cloneDeep(filts);
        const tgs = fltrs.tags.map(t => t.active).filter(t => !!t);
        return tgs.length > 0;
    }

    // Selection Functions
    const changeCurrentItem = (item) => {
        setCurrentItem(item);
        handleScroll(item);
        if (smartFilters) {
            setTextSearch('');
            setSmartFilter(item);
        }        
    }
    const checkUsedBill = (id) => {
        return JSON.stringify(connections).indexOf(id) > 0;
    }
    const checkLinked = (billId) => {
        let f = false;
        if (currentItem) f = find(connections, c => (c.ofx === currentItem.id && c.bill === billId))
        return !!f;
    }
    const lookToOFX = (OFXid) => {
        const el = document.getElementById(OFXid);
        if (el) el.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
    const lookOFXfromBill = (id) => {
        const conn = find(connections, c => c.bill === id);
        const ofxId = conn.ofx;
        const item = find(OFXlist, o => o.id === ofxId);
        setCurrentItem(item);
        lookToOFX(ofxId);
    }

    // BILL Functions
    const addBillToOFX = (id) => {
        const billIndex = findIndex(BILLSlist, b => b.id === id);
        const nCurrentItem = cloneDeep(currentItem);
        nCurrentItem.bills.push(BILLSlist[billIndex]);
        setCurrentItem(nCurrentItem);

        const nOFXList = cloneDeep(OFXlist);   
        const ofxIndex = findIndex(nOFXList, o => o.id === currentItem.id);
        nOFXList[ofxIndex] = nCurrentItem;
        setOFXList(nOFXList);

        const nConn = cloneDeep(connections)
        nConn.push({
            bill: id,
            ofx: currentItem.id
        });
        setConnections(nConn);   
    }
    const removeBillFromOFX = (id) => {
        const nConn = cloneDeep(connections);
        remove(nConn, c => c.bill === id)
        setConnections(nConn);   
        
        const nCurrentItem = cloneDeep(currentItem);
        remove(nCurrentItem.bills, b => b.id === id);
        setCurrentItem(nCurrentItem);

        const nOFXList = cloneDeep(OFXlist);   
        const ofxIndex = findIndex(nOFXList, o => o.id === currentItem.id);
        nOFXList[ofxIndex] = nCurrentItem;
        setOFXList(nOFXList);
    }

    // Reconcile, Ignore and Join Functions
    const handlePreReconcile = () => {
        setPreReconcileItem(currentItem.id);
    }
    const handleReconcile = (ignore) => {
        setPreReconcileItem(null);
        const recon = handleReconciliation(ignore, currentItem, connections, baseOFX.current, OFXlist, initialOFX.current, initialBILLS.current, initialReconcileTable.current);
        recon.entry.bills.forEach(billId => { removeBillFromOFX(billId) })
        baseOFX.current = recon.baseOFX;
        initialOFX.current = recon.initialOFX;
        initialBILLS.current = recon.initialBILLS;
        initialReconcileTable.current = recon.initialReconcileTable;   
        setConnections(recon.connections);
        setOFXList(recon.OFXList);
        saveData(baseOFX.current, baseBILLS.current, recon.initialOFX, recon.initialBILLS, recon.initialReconcileTable);
        setBILLSlist(applyTagFiltersToList(filters)); 
        changeCurrentItem(recon.OFXItemToFocus);
        if (recon.OFXItemToFocus) lookToOFX(recon.OFXItemToFocus.id);

    }
    const handleJoin = (join, item) => {
        const joined = handleJoining(join, item, currentItem, connections, initialOFX.current, baseOFX.current);
        setConnections(joined.connections);
        baseOFX.current = joined.baseOFX;
        initialOFX.current = joined.initialOFX;
        saveData(joined.baseOFX, baseBILLS.current, joined.initialOFX, initialBILLS.current, initialReconcileTable.current);
        setOFXList(orderBy(joined.initialOFX, ['date'], ['asc']));
        changeCurrentItem(joined.OFXItemTarget);
    }

    // Match making
    useEffect(() => {
        if (filters && filters.by === 'smart' && BILLSlist.length === 1) {
            if (currentItem && getTotalValue(currentItem) === BILLSlist[0].value) {                
                if (currentItem.date === BILLSlist[0].date) {
                    const hasBill = find(currentItem.bills, b => b.id === BILLSlist[0].id);
                    const wasMatched = matchMade.current.indexOf(BILLSlist[0].id) > -1;
                    if (!hasBill && !wasMatched) {
                        console.log('possible match');
                        matchMade.current.push(BILLSlist[0].id);
                        addBillToOFX(BILLSlist[0].id);
                    }
                }
            }
        }
    }, [currentItem, BILLSlist, filters]);


    const handleScroll = (item) => {
        const scroller = document.getElementById('scroller');
        const el = document.getElementById(item ? item.id : currentItem.id);
        if (el && scroller) {
            const scrollPosition  = scroller.scrollTop + 51 + 51;
            const scrollH = scroller.clientHeight;
            const parentOffset = el.offsetParent.offsetTop;
            const cardH = el.clientHeight;
            const cardTop = parentOffset + el.offsetTop; 
            const cardBottom = parentOffset + el.offsetTop + cardH; 

            const isTop = scrollPosition >= cardBottom - 10;
            const isBottom = cardTop >= scrollPosition + scrollH - 51 - 10;

            if (isTop) {
                setFloater('top');
            } else if (isBottom) {
                setFloater('bottom');
            } else {
                setFloater('');
            }
        }
    }


    return (
        <div className='OFXImport'>
            {
                !loaded && <div className='OFXImport__Loading'><Loader/></div>
            }
            { 
                loaded && (!OFXlist || OFXlist.length) === 0 &&
                <div className='OFXImport__Message'>
                    <Button label='Importe um arquivo OFX' onClick={fakeImport}/>
                </div> 
            }
            {   loaded && OFXlist && OFXlist.length > 0 &&
                <ResizableContent min={300}
                    a={
                        <div className='OFXArea'>
                            <div className='OFXTitle subtitle1'>
                                Extrato
                                <Button type='icon' icon='file-import'/>
                            </div>
                            <Divider/>
                            <div className='OFXList scrollable' id='scroller' onScroll={ () => { handleScroll() }}>
                                { floater === 'top' &&  <div className={`OFXList__Floater top ${getValid(currentItem) ? 'valid' : ''}`} onClick={() => { lookToOFX(currentItem.id) }}><Counter type={getValid(currentItem) ? '' : 'grey'} count={currentItem.children ? currentItem.children.length + 1 : 1}/>{ getPlural(currentItem.children ? currentItem.children.length + 1 : 1, 'Item selecionado', 'Itens selecionados')}<Icon name='arrow-up'/></div> }
                                { 
                                    Object.keys(groupBy(OFXlist, 'date')).map((d, i) => {
                                        const date = processDate(d);
                                        const dt = new Intl.DateTimeFormat('pt-BR', {dateStyle: 'long'}).format(date);
                                        return (
                                            <div className='OFXList__Group' key={d}>
                                                <div className='OFXList__Group__date'>{dt}</div>
                                                {
                                                    groupBy(OFXlist, 'date')[d].map(item => {
                                                        return(<OFXCard key={item.id} canJoin={true} item={item} reconcile={preReconcileItem === item.id} active={currentItem ? currentItem.id === item.id : false} onSelect={ (item) => { changeCurrentItem(item) }} onJoin={handleJoin} onIgnore={ () => { handleReconcile(true) }}/>)
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                )}
                                { floater === 'bottom' &&  <div className={`OFXList__Floater ${getValid(currentItem) ? 'valid' : ''}`} onClick={() => { lookToOFX(currentItem.id) }}><Counter type={getValid(currentItem) ? '' : 'grey'} count={currentItem.children ? currentItem.children.length + 1 : 1}/>{ getPlural(currentItem.children ? currentItem.children.length + 1 : 1, 'Item selecionado', 'Itens selecionados')}<Icon name='arrow-down'/></div> }
                            </div>
                        </div>
                    } 
                    b={
                        <div className='BILLlist'>
                            <SearchBar placeholder='Buscar por conta, origem, destino, valor...' startOpened hasSmart smart={smartFilters} filters={filters} textSearch={textSearch} onType={handleType} onChangeFilters={(fltrs)=> {handleChangeFilters(fltrs, null)}} onChangeSmartFilter={handleChangeSmartFilter}/>
                            { BILLSlist && BILLSlist.length > 0 && 
                                <div className='content scrollable'>
                                    <ConciliationHeader/>
                                    { BILLSlist.map(bill => (
                                        <ConciliationRow key={bill.id} bill={bill} selected={checkUsedBill(bill.id)} linked={checkLinked(bill.id)} matched={getValid(currentItem)} onCart={addBillToOFX} onLook={lookOFXfromBill} onRemoveCart={removeBillFromOFX}/>
                                    )) }                                
                                </div>
                            }
                            { BILLSlist && BILLSlist.length === 0 && 
                                <div className='content scrollable'>
                                    <div className='BILLlist__Message'>
                                        <div className='BILLlist__Message__Box'>
                                            <div className='h6'>Nenhuma parcela encontrada</div>
                                            { smartFilters && <div className='body2'>A busca automática não encontrou parcelas compatíveis. </div> }
                                            { !smartFilters && !hasActiveFilters(filters) && <div className='body2'>Verifique a data ou ligue a busca automática</div> }
                                            { !smartFilters && hasActiveFilters(filters) && <div className='body2'>Verifique a data e os filtros aplicados ou ligue a busca automática</div> }
                                            <div className='buttons'>
                                                { !smartFilters && hasActiveFilters(filters) && <Button type='outline' label='Ver todas as parcelas do mês' onClick={ () => { clearFilters() } }/> }
                                                { !smartFilters && <Button label='Ligar busca automática' onClick={ () => { handleChangeSmartFilter(true) } }/> }
                                                { smartFilters && <Button label='Procurar no mês' onClick={ () => { clearFilters() } }/> }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                            <ConciliationCart item={currentItem} onRemoveCart={removeBillFromOFX} onPreReconcile={() => {handlePreReconcile()}} onReconcile={ () => { handleReconcile(false) }}/>
                        </div>
                }/>
            }
        </div>
        
    )
}

export default Reconciliation;