import { useEffect, useRef, useState } from 'react';
import { Button, ButtonDropdown, Divider, Icon, OverlayPositioned, Tooltip } from '..';
import './style.sass';

function DatePicker(props) {

    const monthRef = useRef();
    const yearRef = useRef();

    const dateOptions = [
        { key: 'monthly', label: 'Mensal' },
        { key: 'annual', label: 'Anual' },
        { key: 'custom', label: 'Personalizado' },
    ];

    useEffect(() => {
        // if (props.custom) setDateOption({ key: 'custom', label: 'Personalizado' });
        if (props.view) handleSelect({ key: props.view })
    }, [props.view]);
    

    const [opened, setOpened] = useState(false);
    const [currentDate, setCurrentDate] = useState(null);
    const [customStart, setCustomStart] = useState(props.customDate.startDate);
    const [customEnd, setCustomEnd] = useState(props.customDate.endDate);
    const [dateOption, setDateOption] = useState(props.view);


    const handleOpen = () => {
        setOpened(!opened)
    }

    const onChangeDate = (date, dateOpt) => {
        const lastDay = (y, m) => {
            return  new Date(y, m +1, 0).getDate();
        }

        if (props.onChangeDate) {

            let startDate;
            let endDate;
            const current = new Date(date);       

            if (dateOpt === 'monthly') {
                const year = current.getFullYear();
                const month = current.getMonth();
                startDate = `${year}-${date.split('-')[1]}-01`;
                endDate = `${year}-${date.split('-')[1]}-${lastDay(year, month)}`;
                setCustomStart(startDate);
                setCustomEnd(endDate);
                props.onChangeDate(startDate, endDate);
            }

            if (dateOpt === 'annual') {
                const year = current.getFullYear();
                startDate = `${year}-01-01`;
                endDate = `${year}-12-31`;
                setCustomStart(startDate);
                setCustomEnd(endDate);
                props.onChangeDate(startDate, endDate);
            }
        }
    }

    const handleCustomChange = (ev, key) => {
        if (key === 'start') {
            setCustomStart(ev.target.value);
            if (customStart && customEnd && props.onChangeDate) {
                props.onChangeDate(ev.target.value, customEnd);
            }
        } else {
            setCustomEnd(ev.target.value);
            if (customStart && customEnd && props.onChangeDate) {
                props.onChangeDate(customStart, ev.target.value);
            }
        }        
    }

    const handleMonth = (months) => {
        const date = new Date(currentDate);
        const nDate = new Date(date.setMonth(date.getMonth()+months));
        const finalDate = nDate.toISOString().split('T')[0];
        setCurrentDate(finalDate);
        onChangeDate(finalDate, dateOption);
    }

    const handleYear = (years) => {
        const date = new Date(currentDate);
        const nDate = new Date(date.setFullYear(date.getFullYear()+years));
        const finalDate = nDate.toISOString().split('T')[0];
        setCurrentDate(finalDate);
        onChangeDate(finalDate, dateOption);
    }

    const handleMonthChange = (date) => {
        setCurrentDate(date);
        onChangeDate(date, dateOption);
    }

    const handleYearChange = (year) => {
        setCurrentDate(`${year}-01-01`);
        onChangeDate(`${year}-01-01`, dateOption);
    }

    const handleSelect = (dateOpt) => {
        setDateOption(dateOpt.key);
        if (!currentDate) {
            setCurrentDate(customStart);
            onChangeDate(customStart, dateOpt.key);
            setYearWindow(getYearWindowByDate(customStart))
        } else {
            onChangeDate(currentDate, dateOpt.key);
            setYearWindow(getYearWindowByDate(currentDate))
        }
    }
    
    const renderMonths = () => {
        const render = []

        for (let index = 1; index < 13; index++) {    
            const year = currentDate.split('-')[0];
            const month = `${index<10 ? '0' : ''}${index}`;    
            const oDate = new Date(year, month-1, 1);
            const date = oDate.toISOString();    
            render.push(
                <div key={year+month} className={`${month === currentDate.split('-')[1] ? 'selected' : ''}`} onClick={ () => { handleMonthChange(date) }}>
                    {oDate.toLocaleString('pt-BR', { month: 'short' }).split('.')[0]}
                </div>
            )            
        }
        return render;
    }


    const getYearAsNumber = (date) => {
        return Number(date.split('-')[0]);
    } 

    const getYearWindowByDate = (date) => {
        const currentYear = getYearAsNumber(date);
        return [currentYear - 5, currentYear + 6];
    }

    const getLabelFromOption = (key) => {
        return  dateOptions.filter(o => o.key === key)[0].label;
    }

    const [yearWindow, setYearWindow] = useState(null);

    const changeYearWindow = (modifier) => {
        if (modifier < 0) {
            setYearWindow([ yearWindow[0]-12, yearWindow[0]-1 ])
        } else {
            setYearWindow([ yearWindow[1]+1, yearWindow[1]+12 ])
        }
    }

    const renderYears = () => {
        const render = []

        for (let year=yearWindow[0]; year <= yearWindow[1]; year++) {    
            render.push(
                <div key={year} className={`${year === Number(currentDate.split('-')[0]) ? 'selected' : ''}`} onClick={ () => { handleYearChange(year) }}>
                    {year}
                </div>
            )            
        }
        return render;
    }

    return (
        <div className={`DatePicker`}>
            <ButtonDropdown tooltip={getLabelFromOption(dateOption)} type='icon' icon='calendar' options={dateOptions} onSelect={handleSelect}/>
            { 
                !props.custom && dateOption === 'annual' &&  
                <div className='DatePicker__Handler' ref={yearRef}>
                    <Button type='icon' icon='chevron-left' onClick={ () => { handleYear(-1) } }/>
                    <Button type='text-grey' label={new Date(currentDate.split('-')[0], 0, 1).toLocaleString('pt-BR', { year: 'numeric' })} onClick={handleOpen}/>
                    <Button type='icon' icon='chevron-right' onClick={ () => { handleYear(1) } }/>
                </div>
            }
            { 
                !props.custom && dateOption === 'monthly' &&  
                <div className='DatePicker__Handler' ref={monthRef}>
                    <Button type='icon' icon='chevron-left' onClick={ () => { handleMonth(-1) } }/>
                    <Button type='text-grey' label={new Date(currentDate).toLocaleString('pt-BR', { year: 'numeric', month:'short' })} onClick={handleOpen}/>
                    <Button type='icon' icon='chevron-right' onClick={ () => { handleMonth(1) } }/>
                </div>
            }
            {
                (props.custom || (!props.custom && dateOption === 'custom')) && 
                <div className='DatePicker__Custom'>
                    <div className='DatePicker__Custom__Field'>
                        <input type='date' value={props.customDate.startDate} onChange={ (ev) => { handleCustomChange(ev, 'start') } }/>
                    </div>
                    <Icon small name='arrow-right'/>
                    <div className='DatePicker__Custom__Field'>
                        <input type='date' value={props.customDate.endDate} onChange={ (ev) => { handleCustomChange(ev, 'end') } }/>
                    </div>
                </div>
            }
            {
                opened && dateOption === 'monthly' &&
                    <OverlayPositioned element={monthRef.current} onClose={handleOpen}>
                        <div className='Card'>
                            <div className='DatePicker__Selector'>
                                <div className='DatePicker__Selector__Top'>
                                    <Button type='icon' icon='chevron-left' onClick={ () => { handleYear(-1) } }/>
                                        {new Date(currentDate).toLocaleString('pt-BR', { year: 'numeric' })}
                                    <Button type='icon' icon='chevron-right' onClick={ () => { handleYear(1) } }/>
                                </div>
                                <Divider/>
                                <div className='DatePicker__Selector__Bottom'>
                                    { renderMonths() }
                                </div>
                            </div>
                        </div>        
                    </OverlayPositioned>
            }
            {
                opened && dateOption === 'annual' &&
                    <OverlayPositioned element={yearRef.current} onClose={handleOpen}>
                        <div className='Card'>
                            <div className='DatePicker__Selector'>
                                <div className='DatePicker__Selector__Top'>
                                    <Button type='icon' icon='chevron-left' onClick={ () => { changeYearWindow(-1) } }/>
                                    { getYearWindowByDate(currentDate)[0] } - { getYearWindowByDate(currentDate)[1] }
                                    <Button type='icon' icon='chevron-right' onClick={ () => { changeYearWindow(1) } }/>
                                </div>
                                <Divider/>
                                <div className='DatePicker__Selector__Bottom'>
                                    {renderYears()}
                                </div>
                            </div>
                        </div>        
                    </OverlayPositioned>
            }
        </div>
    )
}

export default DatePicker;
