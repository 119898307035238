import './accountLogin.sass';
import { Divider, Button, AegroLogo, SocialButton, InputText, Tabs, Dropdown } from '../../components';
import { useState } from 'react';

function AccountLogin(props) {

    const [email, setEmail] = useState(props.email);

    const handleEmail = (ev) => {
        setEmail(ev.target.value);
    } 

    const handleSubmit = (ev) => {
        ev.preventDefault();        
        if (props.onStep && email) {
            if (email === 'existe@email.com') {
                props.onStep('code-send', email);
            } else {
                props.onStep('create', email);
            }
        }
    }

    return (
        <div className='AccountLogin'>
            <div className="h6">Acessar com outro serviços</div>
            
            <div className="Box">
                <div className="content">
                        <div className="social">
                            <SocialButton type="alternative" full media="google" label="Continuar com"/>
                            <SocialButton type="alternative" full media="fieldview" label="Continuar com"/>
                        </div>
                        <div className="section">
                            <Divider/>
                            <span>ou</span>
                            <Divider/>
                        </div>
                        <div className="h6">Acessar com e-mail</div>
                        <form onSubmit={handleSubmit}>
                            <InputText autofocus value={email} type="email" required label="E-mail" onChangeValue={handleEmail} />
                            <Button loading={props.loading} full label="Entrar"/>
                        </form>
                    </div>
                <div className="fineline">
                    <Divider/>
                    Ao entrar com o Google ou com o Climate FieldView™ e não for usuário do Aegro, você será cadastrado e estará aceitando os <a href="https://aegro.com.br/termos-de-uso">Termos e Condições</a> da Aegro e a <a href="https://aegro.com.br/politica-de-privacidade">Política de Privacidade</a>
                </div>
            </div>
        </div>
    )
}

export default AccountLogin;