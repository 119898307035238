import './passwordLogin.sass';
import { Divider, Button, AegroLogo, SocialButton, InputText, Tabs, Dropdown } from '../../components';
import { useState } from 'react';

function PasswordLogin(props) {

    const [password, setPassword] = useState();

    const handlePassword = (ev) => {
        setPassword(ev.target.value);
    } 

    const handleSubmit = (ev) => {
        ev.preventDefault();        
        if (props.onStep && password) {
            if (password === 'existe@email.com') {
                props.onStep('code-send', password);
            } else {
                props.onStep('create', password);
            }
        }
    }

    const handleStep = (step) => {
        if (props.onStep) {
            props.onStep(step)
        }
    }

    return (
        <div className='PasswordLogin'>
            <div className="h6">Acessar com senha</div>
            
            <div className="Box">
                <div className="content">
                        <form onSubmit={handleSubmit}>
                            <InputText readonly value={props.email} type="email" required label="E-mail"/>
                            <InputText autofocus value={password} type="password" required label="Senha" onChangeValue={handlePassword} />
                            <Button loading={props.loading} full label="Entrar"/>
                            <Button full type="text" label="Voltar" onClick={() => { handleStep('code-send') }}/>
                        </form>
                    </div>
            </div>
        </div>
    )
}

export default PasswordLogin;