
import './style.sass';
import { Icon, ButtonDropdown } from '../../../../components';
import cat_icons from '../../../../data/categories_icon.json';

function ReconciledRow(props) {

    const options = [
        { key: 'view', label: 'Visualizar' },
    ];

    return (
        <div className={`ReconciledRow ${props.bill?.value<0 ? 'negative' : ''} ${props.selected ? 'selected' : ''} ${props.cart ? 'cart' : ''}  ${props.linked ? 'linked' : ''}`}>
            <Icon name={cat_icons[props.bill.category]}/>
            <div className='ReconciledRow__Transfer'>
                <div className='body2 ellipsis'>{props.bill.category}</div>
                <Icon name={props.bill?.value<0 ? 'arrow-right': 'arrow-left'}/>
                <div className='body2 ellipsis'>{props.bill.part}</div>
            </div>
            <div className='installment body2'>
                {props.bill.installment}
            </div>
            <div className='value body2'>
                {Intl.NumberFormat('pt-BR', {style:'currency', currency: 'BRL'}).format(props.bill?.value)}
            </div>
            <ButtonDropdown icon='more-vertical' options={options}/>   
        </div>
    )
}

export default ReconciledRow;