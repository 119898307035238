import { Button, Icon, Loader, OverlayPositioned, Tabs, Tooltip } from '../../components';
import './DeliverableDetails.sass';
import { useEffect, useState } from 'react';
import { find, cloneDeep } from 'lodash';
import Gallery from './Gallery';
import { KRView } from './components';

function DeliverableDetails(props) {

    const [currentTab, setCurrentTab] = useState('details');

    const statusDict = {
        backlog: 'Backlog',
        production: 'Em produção',
        delivered: 'Entregue',
        canceled: 'Cancelado'
    }

    const tabs = [
        { key: 'details', name: 'Detalhes' },
        { key: 'prototype', name: 'Protótipo' },
    ];

    const handleTabs = () => {
        const nTabs = cloneDeep(tabs);
        if (!props.data.prototype) {
            nTabs.splice(1, 1);
        }
        return nTabs;
    }

    const getTeam = (id) => {
        const team = find(props.other.teams, t => t.id === id);
        return team || { id: '', name: 'team not found' }
    }

    const getJTBD = (id) => {
        const JTBD = find(props.other.JTBDs, j => j.id === id);
        return JTBD || { id: '', description: 'JTBD not found' }
    }

    const handleClose = () => {
        if (props.onClose) {
            props.onClose()
        }
    }

    const calcWeeks = (dateWindow) => {
        const weeksBetween = (d1, d2) => {
            return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
        }
       return (weeksBetween(new Date(dateWindow.start), new Date(dateWindow.end)));
    }

    const platDict = {
        web: 'monitor',
        mobile: 'cellphone'
    };

    return (
        <OverlayPositioned blur>
            <div className={`DeliverableDetails ${props.type}`}>  
                <div className="Header">
                    <Button type="icon" icon="close" onClick={handleClose}/>
                    <div className="team body">Jornada/Time {getTeam(props.data.teamId).name}</div>
                    <div className="line">
                        <div className="name h6">{props.data.name}</div>
                        <div className={`status ${props.data.status}`}>{statusDict[props.data.status]}</div>
                    </div>
                    <div className="line">
                        <div className="duration body">Upstream: {calcWeeks(props.data.upstream)} semanas  •  Downstream: {calcWeeks(props.data.downstream)} semanas</div>
                        <div className="platform">
                            { props.data.platform.map((plat, i) => <Tooltip key={'platform'+i} text={plat}><Icon name={platDict[plat]}/></Tooltip>) }
                        </div>
                    </div>

                    <Tabs tabs={handleTabs()} current={currentTab} onChangeTab={(tab)=> {setCurrentTab(tab)}}/>
                </div>
                {
                    currentTab === 'details' &&
                        <div className="Content scrollable">
                            <div className="Content__Left">
                                {
                                    props.data.jtbd.length > 0 &&
                                    <div className="jobstobedone">
                                        <div className="overline">Jobs To Be Done</div>
                                        { 
                                            props.data.jtbd.map((jtbdId, i) => 
                                                <div key={'jtbd'+i} className="JTBDItem">
                                                    <Icon name="flag-checkered"/>
                                                    { getJTBD(jtbdId).description }
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                                {
                                    props.data.kr.length > 0 && 
                                    <div className="krs">
                                        <div className="overline">Key Results</div>
                                        { 
                                            props.data.kr.map((krId, i) => 
                                                <KRView key={'kr'+i} id={krId} krs={props.other.krs}/>
                                            )
                                        }
                                    </div>
                                }
                                {
                                    props.data.description &&
                                    <div className="description">
                                        <div className="overline">Descrição</div>
                                        {props.data.description.split('\n').map((str, i) => <p key={i}>{str}</p>)}
                                    </div>
                                }
                                {
                                    props.data.links.length > 0 &&  
                                    <div className="links">
                                        <div className="overline">Links</div>
                                        { 
                                            props.data.links.map((link, i) => 
                                                <a target="_blank" rel="noreferrer" href={link.url} key={'link'+i} className="LinkItem">
                                                    <Icon name="link"/>
                                                    { link.title }
                                                </a>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                            <div className="Content__Right">
                                <Gallery pics={props.data.images} />
                                {
                                    props.data.images.length > 0 &&
                                    <div className="disclaimer">
                                        <Icon name="alert-circle"/>
                                        As imagens são meramente ilustrativas e não refletem a entrega final da funcionalidade.
                                    </div>
                                }
                            </div>
                        </div>
                }
                {
                    currentTab === 'prototype' &&
                        <div className="Content">  
                            <Loader/>
                            <iframe className="FigmaFrame" title="Protótipo" src={props.data.prototype} allowfullscreen></iframe>
                        </div>
                }
            </div>
        </OverlayPositioned>
    )
}

export default DeliverableDetails;