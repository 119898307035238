import './style.sass';

function Badge(props) {

    const tps = {
        new: 'novo',
        beta: 'beta',
        pro: 'pro'
    }

    return (
        <div className={`Badge ${props.type}`}>
            {props.type ? tps[props.type] : 'novo'}
        </div>
    )
}

export default Badge;
