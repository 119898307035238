const teams = [
    {
        id: '38y4g132786g981234',
        name: 'Fiscal',
        members: []
    },
    {
        id: '9823457263g598276g5',
        name: 'Custos',
        members: []
    }
];

const krs = [
    {
        id: 'y76g39487g694872g4981',
        description: 'Objetivo 1: Simplificar a jornada de gestão rural para garantir o máximo engajamento no produto (mais retenção)',
        links: [],
        parent: null,
    },
    {
        id: '74g598236g598263g49872',
        description: 'Objetivo 2: Gerar demanda que garanta a consolidação absoluta do mercado de 500 a 5000ha e aumente a penetração no mercado de 100 a 500ha',
        links: [],
        parent: null,
    },
    {
        id: '764g987326418372946g1832',
        description: 'Aumentar percentual de visitantes no plano NFe que emitem nota com autonomia.',
        links: [{ id: '3y4298g7y44', name: 'mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards#id=5656452&editor-card-id=%22report-49247936%22' }],
        parent: 'y76g39487g694872g4981',
    },
    {
        id: '293485g79823764592834',
        description: 'Aumentar percentual de visitantes da página de registro que chegam ao evento de valor com autonomia em seu primeiro dia de acesso.',
        links: [{ id: '3y4298g7y44', name: 'mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards#id=5656452&editor-card-id=%22report-49247923%22' }],
        parent: 'y76g39487g694872g4981',
    },
    {
        id: '8g7623485976g24958235',
        description: 'Aumentar percentual de clientes engajados no 3o mês de uso após a contratação.',
        links: [],
        parent: 'y76g39487g694872g4981',
    },
    {
        id: '89236g4589234g587345gt2983',
        description: 'Aumentar percentual de clientes engajados 12 meses depois da contratação.',
        links: [{ id: '3y4298g7y44', name: 'mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards/#id=5656452&editor-card-id=%22report-49261377%22' }],
        parent: 'y76g39487g694872g4981',
    },
    {
        id: '0394872y4987t2g3894t134',
        description: 'Aumentar o número de clientes fazendo livro caixa no Aegro.',
        links: [{ id: 'h23178482hh4', name: 'mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards?discover=1#id=5575444&edited-bookmark=EE5nZT6E6xX5' }],
        parent: '74g598236g598263g49872',
    },
    {
        id: '9783g2498723g149829837',
        description: 'Gerar oportunidades para vendas através do plano emissor gratuito.',
        links: [],
        parent: '74g598236g598263g49872',
    },
    {
        id: '8974236g5982346g592387',
        description: 'Criar um plano básico para produtores de baixa maturidade. Novos clientes pagantes nesse plano.',
        links: [],
        parent: '74g598236g598263g49872',
    }
];

const JTBDs = [
    {
        id: '83yh419083y2h491872h4', 
        description: 'Devo conseguir emitir notas, inclusive quando a Sefaz estiver com instabilidade'
    },
    {
        id: '23497g6529376g5345', 
        description: 'Preciso calcular os impostos para emissão da nota (MUST HAVE, concorrentes fazem)'
    },
    {
        id: '937yg498723tg49827634g5', 
        description: 'Preciso fazer os cadastros necessários para emissão de nota, e compartilhar posterior com meu fornecedor'
    },
    {
        id: '3468934274524y723y4h', 
        description: 'Preciso fazer meu planejamento tributário para pagar menos imposto de renda'
    },
    {
        id: '29843h598472gy53543', 
        description: 'Preciso emitir o manifesto de trasporte MDFe.'
    },
    {
        id: '29h387g4y923876g2879345t', 
        description: 'Me perco nos campos na hora de emitir uma nota'
    },
    {
        id: '2834h5y92378y5892345', 
        description: 'Devo conseguir editar minha nota rejeitada pela Sefaz'
    },
    {
        id: '329874g598273645982345', 
        description: 'Necessito de acessibilidade para emissão de notas pelo celular'
    },
    {
        id: '2873g4t892376t4fg923764t', 
        description: 'Meu contador não quer utilizar o Livro caixa do Aegro por que tem retrabalho'
    },
    {
        id: '28376g487264243434234', 
        description: 'Preciso fazer os cadastros necessário para emissão de nota, e compartilhar posterior com meu fornecedor'
    }
];

const deliverables = [
    {
        id: '9u132y50h9132yrv0b9qe',
        name: 'Emissão de notas em contingência',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['83yh419083y2h491872h4'],
        platform: ['web'],
        images: [
            { 
                id: '723g6549827g5',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F1-contingencia.png?alt=media&token=05c2855e-73de-4822-9096-9cb3a9b9ae21',
                title: 'Contingência'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F0v050BFUMOQEUDKnH10zw1%2F%255BWeb%255D-NFEs-em-Conting%25C3%25AAncia%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DjmJZsJDBFuxu6tpN-1',
        description: 'Essa funcionalidade é projetada para oferecer suporte a empresas em momentos de desafios técnicos, permitindo que continuem operando sem interrupções. Com a capacidade de emitir automaticamente notas em contingência em situações como falhas na conexão com a internet ou problemas nos servidores fiscais, o Aegro irá garantir a continuidade do processo de emissão de notas fiscais. Esta funcionalidade não apenas mantém as operações em andamento, mas também ajuda as empresas a cumprir suas obrigações fiscais, evitando atrasos e possíveis penalidades. \nAlém disso, estamos comprometidos em fornecer recursos integrados para facilitar a transmissão das notas em contingência assim que a situação for normalizada, garantindo uma gestão fiscal eficiente e sem contratempos para nossos usuários.',
        status: 'delivered',
        upstream: {
            start: '2024-01-01',
            end: '2024-02-15',
        },
        downstream: {
            start: '2024-01-01',
            end: '2024-02-15',
        },
        links: [
            { id: '', title: 'teste', url: '' }
        ],
    },{
        id: '927364v832764g9831',
        name: 'Nova experiência de emissão de notas',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['23497g6529376g5345'],
        platform: ['web'],
        images: [
            { 
                id: '243975yg925',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F3-nova-emissao.png?alt=media&token=ecce56c6-6647-4e18-96b9-b4b13f505979',
                title: 'Modelo de nova emissão'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2iCF7JR13PTz5lsIFePXHR%2F%255BWeb%255D-Emiss%25C3%25A3o-de-NFe-2.0%3Fpage-id%3D230%253A32327%26type%3Ddesign%26node-id%3D230-32626%26viewport%3D-2197%252C422%252C0.29%26t%3DufF6kALVfkhezgJH-1%26scaling%3Dscale-down%26starting-point-node-id%3D230%253A32626%26show-proto-sidebar%3D1%26mode%3Ddesign',
        description: 'Atualmente, a experiência de emissão de notas está integrada ao mesmo fluxo de lançamento financeiro em nosso sistema. No entanto, essa abordagem tem gerado diversos problemas de usabilidade, pois mistura duas jornadas de usuários distintas. Por esse motivo, decidimos desenvolver uma nova experiência exclusivamente focada na emissão de notas. Essa mudança nos permitirá simplificar o processo de emissão, concentrando-nos em cada detalhe e oferecendo aos usuários uma experiência mais intuitiva e eficiente.',
        status: 'backlog',
        upstream: {
            start: '2024-02-01',
            end: '2024-02-28',
        },
        downstream: {
            start: '2024-09-01',
            end: '2024-12-31',
        },
        links: [],
    },{
        id: '298376g49827g342189',
        name: 'Buscas automáticas CEP, CNPJ, Inscrição Estadual',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['937yg498723tg49827634g5'],
        platform: ['web'],
        images: [
            { 
                id: '283746289376492',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F14-cnpjcpf-automatico.png?alt=media&token=8df2052f-e803-42ba-ad6b-e11988e812df',
                title: 'CNPJ CPF Automático'
            }
        ],
        prototype: null,
        description: 'Essa funcionalidade simplifica o processo de inserção de informações em documentos fiscais e formulários, proporcionando economia de tempo e reduzindo erros. Com o fornecimento do CEP, o sistema completa automaticamente os campos de endereço, cidade e estado, enquanto o CNPJ e a inscrição estadual de clientes ou fornecedores acionam a busca de dados cadastrais correspondentes, simplificando o processo de cadastro e mantendo a precisão das informações.',
        status: 'backlog',
        upstream: {
            start: '2024-02-01',
            end: '2024-02-28',
        },
        downstream: {
            start: '2024-02-01',
            end: '2024-02-28',
        },
        links: [],
    },{
        id: '298376g49827g342189',
        name: 'Atalho Livro Caixa na tela principal',
        teamId: '38y4g132786g981234',
        kr: ['0394872y4987t2g3894t134'],
        jtbd: ['3468934274524y723y4h'],
        platform: ['web'],
        images: [
            { 
                id: '94y598237tg523554',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F4-livrocaixa.png?alt=media&token=451b6af1-22e6-4a7e-b4a4-71213589f4f3',
                title: 'Livro Caixa'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FIPzkvoRtP5OUKjubxC6mgl%2FWeb%253A-Livro-Caixa-v2.0%3Ftype%3Ddesign%26node-id%3D3794%253A6759%26mode%3Ddesign%26t%3Dhi3APVaEreeUeO6H-1',
        description: 'Os objetivos da funcionalidade são: 1) facilitar o acesso ao Livro Caixa; 2) apresentar o livro para os usuários que ainda não conhecem ou não utilizam, colocando um botão no financeiro. Para aqueles que já possuem o Livro Caixa, o botão os levará diretamente para o recurso adicional. Para os que ainda não possuem, serão direcionados para a landing page para mais detalhes.',
        status: 'backlog',
        upstream: {
            start: '2024-02-01',
            end: '2024-02-28',
        },
        downstream: {
            start: '2024-02-01',
            end: '2024-02-28',
        },
        links: [],
    },{
        id: '923478h5g9836g',
        name: 'Emissão MDFe',
        teamId: '38y4g132786g981234',
        kr: ['9783g2498723g149829837'],
        jtbd: ['29843h598472gy53543'],
        platform: ['web'],
        images: [
            { 
                id: '9843762498723424c23vr',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F19-mdfe.png?alt=media&token=39703aa1-c1d1-469a-9b91-e2a337597db4',
                title: 'MDFe'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FzvpayvadMq3RjNmnTWVhF8%2F%255BWeb%255D-MDFe%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-1020%26viewport%3D1034%252C823%252C0.38%26t%3DgIQTUvjn3zKvYUX2-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D1%253A1020%26mode%3Ddesign',
        description: 'MDFe é uma das funcionalidades bem pedida por usuários (possui 14 pedidos no mural de sugestões). Além disso, é um requisito e um passo importante para usuários que não usam a emissão de notas do Aegro hoje começarem a utilizar. Com o MDFe integrado ao nosso sistema, os produtores rurais podem facilmente registrar e acompanhar o transporte de suas cargas, seja para venda, distribuição ou movimentação interna além da sua obrigatoriedade fiscal.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-04-15',
        },
        downstream: {
            start: '2024-07-01',
            end: '2024-10-01',
        },
        links: [],
    },{
        id: '3298475g4298534',
        name: 'Cálculo automático de tributos na tela atual',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['23497g6529376g5345'],
        platform: ['web'],
        images: [
            { 
                id: '2934765g9827g9823765',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F6-calculos.png?alt=media&token=ee7fe79b-35c0-417b-bbec-b79f1c43ce55',
                title: 'Cálculos'
            }
        ],
        prototype: null,
        description: 'Esta funcionalidade simplifica significativamente o processo de preenchimento dos valores fiscais durante a emissão de notas, calculando automaticamente a base de cálculo e a alíquota. Atualmente, no Aegro, essas tarefas são realizadas manualmente, o que aumenta consideravelmente o risco de erros.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        links: [],
    },{
        id: '2309746g5924g765',
        name: 'Clique nas pendências leva para o campo que precisa ser preenchido',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['29h387g4y923876g2879345t'],
        platform: ['web'],
        images: [
            { 
                id: '9437265492876529',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F12-pendencias.png?alt=media&token=0db2665a-79e8-4b2e-b9ed-2f919480a7db',
                title: 'Pendências'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0ymLOqsaysh4sqPOqCvmGm%2F%255BWeb%255D-Lan%25C3%25A7amento%253A-Nota-Fiscal-e-Livro-Caixa%3Fpage-id%3D2264%253A27325%26type%3Ddesign%26node-id%3D2264-27326%26viewport%3D780%252C472%252C0.66%26t%3DIT1fN97rNIRjrj9s-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D2264%253A27326%26mode%3Ddesign',
        description: 'Atualmente, nosso mecanismo de pendências facilita o preenchimento da NFe, mas é pouco interativo e pode dificultar o acesso às informações pendentes. Por isso, estamos desenvolvendo uma funcionalidade que permitirá aos usuários clicar em cada pendência para serem direcionados diretamente a elas, agilizando o processo de preenchimento e ajuste.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        links: [],
    },{
        id: 'dhu9sduh0as9hguhsangosagnojlvc',
        name: 'Ajustes de nomenclatura',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['29h387g4y923876g2879345t'],
        platform: ['web'],
        images: [
            { 
                id: 'faskmfkpsajfkpamfacxz',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F10-nomenclatura.png?alt=media&token=01a04652-a98e-415a-b89b-0f28c2e11fe2',
                title: 'Mudanças de nomenclatura para Lançamento e tipo de operação foram alteradas'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F0ymLOqsaysh4sqPOqCvmGm%2F%255BWeb%255D-Lan%25C3%25A7amento%253A-Nota-Fiscal-e-Livro-Caixa%3Fpage-id%3D2219%253A2821%26type%3Ddesign%26node-id%3D2219-2822%26viewport%3D635%252C489%252C0.55%26t%3DPcRCphtohaCgYsbb-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D2219%253A2822%26mode%3Ddesign',
        description: 'Atualmente, no processo de emissão de notas, alguns campos e nomes de elementos podem parecer fora de contexto dentro do fluxo geral. Nossa meta com essa tarefa é corrigir esses problemas, simplificando o entendimento do usuário e garantindo uma experiência mais intuitiva e coesa.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        links: [
            { id: 'fash0fsahu9fhsahonk', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards#id=5656452&editor-card-id=%22report-49247936%22' }
        ],
    },{
        id: 'hfusahfsa90h9ghsu9ojncm',
        name: 'Permitir editar nota com pagamento a vista',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['2834h5y92378y5892345'],
        platform: ['web'],
        images: [
            { 
                id: '0ifjc-x0j9h9u0dhjnklnlcx',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F13-permitir-pago.png?alt=media&token=ec939325-063c-4d0f-b355-3f1993e7d2e1',
                title: 'Dentro de um lançamento financeiro, poder editá-lo mesmo com a parcela paga'
            }
        ],
        prototype: null,
        description: 'Hoje não é possível editar, diretamente, lançamentos financeiros com pagamento a vista e isso causa confusão e retrabalho para os usuários. A funcionalidade visa permitir esse compartamento.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        links: [
            { id: 'fash0fsahu9fhsahonk', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards#id=5656452&editor-card-id=%22report-49247936%22' }
        ],
    },{
        id: 'dsafhsajhfosahfusa8917',
        name: 'Visão desktop no mobile',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['329874g598273645982345'],
        platform: ['web', "mobile"],
        images: [
            { 
                id: 'dsaufhsuah9fsa9ufh9usahmvcx',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F15-desktop-mobile.png?alt=media&token=f5b9b950-8772-43bc-93ec-66211092486a',
                title: 'Tela do Aegro em um navegador acessado pelo celular'
            }
        ],
        prototype: null,
        description: 'O Aegro hoje não é adaptado para funcionar em um navegador no celular. Com o plano Emissor NFe, temos quase 50% das visitas oriundas do celular. Nosso objetivo é testar se, mesmo uma visão mais simples do Aegro (desktop) no celular, pode fomentar o uso dos usuários até o evento de valor.',
        status: 'backlog',
        upstream: {
            start: '2024-02-01',
            end: '2024-03-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        links: [
            { id: 'das9fsauhfsadsafxzb', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards#id=5656452&editor-card-id=%22report-49247936%22' }
        ],
    },{
        id: 'IJJFI0dj0saif9sahfs9a0',
        name: 'Simulador de imposto de renda resumo mensal com totalizadores LC NFe',
        teamId: '38y4g132786g981234',
        kr: ['0394872y4987t2g3894t134'],
        jtbd: ['3468934274524y723y4h'],
        platform: ['web'],
        images: [
            { 
                id: 'AHSFOJHSAJFHSJAhjldhdsa90',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F8.1-simulador.png?alt=media&token=36cc35e7-0ab5-4f05-946a-a3cc40ce6b24',
                title: 'Tela do Simulador de Imposto de Renda'
            }
        ],
        prototype: 'https://lab.aegro.design/ir/pedro',
        description: 'Nosso compromisso em oferecer uma experiência de usuário de alta qualidade e manter-nos competitivos no mercado levou-nos a decidir aprimorar nossa plataforma com a capacidade de simular o imposto de renda. Reconhecemos que muitos de nossos concorrentes já oferecem essa funcionalidade e entendemos a importância de nos equipararmos com as expectativas do mercado. Além disso, estamos focados em incentivar o uso do Livro Caixa entre nossos clientes, visando ao aumento de sua satisfação e fidelidade. O simulador utilizará os dados precisos e confiáveis do Aegro para fornecer informações essenciais para a tomada de decisão sobre o valor do imposto a pagar, garantindo transparência e segurança aos usuários em suas operações financeiras.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-03-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        links: [
            { id: 'fd0iuhaufhsau90hfsgn', title: 'Protótipo implementado', url: 'https://lab.aegro.design/ir/pedro' },
            { id: 'fsa9h9ufshaofua90fhsa', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards?discover=1#id=5575444&edited-bookmark=EE5nZT6E6xX5' }
        ],
    },{
        id: 'LPKCPKXZPKCZXPOK1SA712',
        name: 'Exportar resumo mensal com totalizadores LC NFe',
        teamId: '38y4g132786g981234',
        kr: ['0394872y4987t2g3894t134'],
        jtbd: ['2873g4t892376t4fg923764t'],
        platform: ['web'],
        images: [
            { 
                id: 'fashu9hfsaf0sa7fhsa97hfas90h',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F9-totalizador-lc.png?alt=media&token=102fb28f-f547-4a95-8d36-66a0f1b0c2f0',
                title: 'Relatório com totalizadores do Livro Caixa'
            }
        ],
        prototype: 'https://lab.aegro.design/ir/pedro',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        links: [
            { id: 'fd0iuhaufhsau90hfsgn', title: 'Protótipo implementado', url: 'https://lab.aegro.design/ir/pedro' }
        ],
    },{
        id: 'pojdfasd0hdsaghsad9hg0s0',
        name: 'Compartilhar NFe',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['937yg498723tg49827634g5'],
        platform: ['web'],
        images: [
            { 
                id: 'h0h0a9sufgh09sadg7mplm',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F7-compartilhar-nfe.png?alt=media&token=5ff5ddf6-be5f-496a-a996-8cb7afd21e95',
                title: 'Tela de pré-visualização da DANFe com botão de compartilhar via WhatsApp'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2iCF7JR13PTz5lsIFePXHR%2F%255BWeb%255D-Emiss%25C3%25A3o-de-NFe-2.0%3Fpage-id%3D230%253A32327%26type%3Ddesign%26node-id%3D1058-5927%26viewport%3D-2197%252C422%252C0.29%26t%3DufF6kALVfkhezgJH-1%26scaling%3Dscale-down%26starting-point-node-id%3D1058%253A5927%26show-proto-sidebar%3D1%26mode%3Ddesign',
        description: 'Estamos planejando implementar uma funcionalidade que facilitará o processo de compartilhamento de esboços de notas fiscais via WhatsApp diretamente do nosso sistema. Reconhecendo a necessidade comum dos usuários de enviar esses esboços para seus contadores a fim de verificar sua precisão, nossa equipe está trabalhando para tornar esse processo mais eficiente e conveniente. Essa adição futura visa otimizar a comunicação entre os usuários e seus contadores, promovendo uma colaboração mais eficiente e garantindo a conformidade dos documentos fiscais.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        links: [
            { id: 'dhsa9fhsa9uhfsau9h', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards#id=5656452&editor-card-id=%22report-49247936%22' }
        ],
    },{
        id: '9hudfhu9fdsa9hugu9hsad0gsdhg9ap',
        name: 'Modelo de dados tributários',
        teamId: '38y4g132786g981234',
        kr: ['764g987326418372946g1832'],
        jtbd: ['83yh419083y2h491872h4'],
        platform: ['web'],
        images: [
            { 
                id: '723g654982asif0sajf',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F16-template-tributario.png?alt=media&token=d95756f7-9ba4-46e1-bfe3-6e9292015460',
                title: 'Edição e criação de um modelo tributário'
            },
            { 
                id: '3128312jsakxzudsah',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F16.2-template-tributario.png?alt=media&token=c1200257-9f8d-4022-bcb6-8510f4bf5d89',
                title: 'Durante o processo de emissão, o usuário poderá escolher o modelo'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2iCF7JR13PTz5lsIFePXHR%2F%255BWeb%255D-Emiss%25C3%25A3o-de-NFe-2.0%3Fpage-id%3D13%253A5891%26type%3Ddesign%26node-id%3D180-21608%26viewport%3D63%252C949%252C0.47%26t%3DN1HFGh2ZNKUB2enZ-1%26scaling%3Dscale-down%26starting-point-node-id%3D180%253A21608%26show-proto-sidebar%3D1%26mode%3Ddesign',
        description: 'No processo de emissão de notas, os usuários enfrentam a necessidade de lidar com diversos detalhes de preenchimento. Com o intuito de simplificar essa tarefa e evitar erros comuns, estamos desenvolvendo uma funcionalidade que permitirá aos usuários criar modelos de preenchimento personalizados. Esses modelos pré-configurados poderão ser preparados pelo contador, garantindo maior precisão e evitando equívocos, como preenchimento incorreto de alíquotas e valores de impostos durante a emissão das notas.',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-06-31',
        },
        links: [
            { id: 'sfjs0aifsafhjlxzbczx', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards#id=5656452&editor-card-id=%22report-49247936%22' }
        ],
    },{
        id: '39876g42398756g293847',
        name: 'Plano Básico',
        teamId: '9823457263g598276g5',
        kr: ['8974236g5982346g592387'],
        jtbd: [],
        platform: ['web'],
        images: [],
        prototype: null,
        description: '',
        status: 'backlog',
        upstream: {
            start: '2024-01-01',
            end: '2024-03-31',
        },
        downstream: {
            start: '2024-01-01',
            end: '2024-03-31',
        },
        links: [],
    },{
        id: '39876g42398756g293847',
        name: 'Ativação tardia',
        teamId: '9823457263g598276g5',
        kr: ['293485g79823764592834'],
        jtbd: [],
        platform: ['web'],
        images: [],
        prototype: null,
        description: '',
        status: 'backlog',
        upstream: {
            start: '2024-01-01',
            end: '2024-06-15',
        },
        downstream: {
            start: '2024-01-01',
            end: '2024-06-15',
        },
        links: [],
    },{
        id: '29734g65938276314g9824',
        name: 'Jornada de custos (passo-a-passo)',
        teamId: '9823457263g598276g5',
        kr: ['293485g79823764592834', '8g7623485976g24958235'],
        jtbd: [],
        platform: ['web'],
        images: [
            { 
                id: '9874362398765g49832652',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F26-custos-passo-a-passo.png?alt=media&token=83066515-3941-4c48-aedc-69d28c4a1261',
                title: 'Passo a passo'
            }
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FVhkhyFKbOKDv5tPcA6ie7M%2F%255BWEB%255D-Produto-Financeiro%3Ftype%3Ddesign%26node-id%3D634%253A1690%26mode%3Ddesign%26t%3DTDNjOSvdI59pLAZP-1',
        description: '',
        status: 'backlog',
        upstream: {
            start: '2024-03-15',
            end: '2024-06-31',
        },
        downstream: {
            start: '2024-03-15',
            end: '2024-06-31',
        },
        links: [],
    },{
        id: '87346g582376g5298476g529',
        name: 'Extrato conciliado OFX / Ignorar registro OFX',
        teamId: '9823457263g598276g5',
        kr: ['89236g4589234g587345gt2983'],
        jtbd: [],
        platform: ['web'],
        images: [
            { 
                id: '8723645f9287465f2943523',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F25-conciliacao-extrato-ignorado.png?alt=media&token=3a94e9f4-410d-47a5-91cb-27e6ec97a89e',
                title: 'Extrato Conciliado'
            }
        ],
        prototype: 'https://lab.aegro.design/ofx/statement',
        description: 'Dentro da nossa solução de conciliação bancária, os usuários têm a capacidade de ignorar certos lançamentos presentes no arquivo OFX do banco que não são relevantes para serem registrados no Aegro. No entanto, atualmente, não há um método para reverter esse processo, e a visibilidade do que já foi conciliado não é muito clara. Portanto, é crucial adicionar um meio, como uma tela dedicada, para que os usuários possam visualizar de forma clara o trabalho que foi conciliado, proporcionando uma melhor compreensão e controle do processo de conciliação.',
        status: 'production',
        upstream: {
            start: '2024-02-15',
            end: '2024-03-15',
        },
        downstream: {
            start: '2024-02-15',
            end: '2024-03-15',
        },
        links: [
            { id: '8743tg5984237g5t53', title: 'Protótipo implementado', url: 'https://lab.aegro.design/ofx/statement' }
        ],
    },{
        id: '87346g582376g5298476g529',
        name: 'Conciliação automática OFX/Conciliação N para N',
        teamId: '9823457263g598276g5',
        kr: ['89236g4589234g587345gt2983'],
        jtbd: [],
        platform: ['web'],
        images: [
            { 
                id: '987435tg298475gt9428',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F24-conciliacaonn.png?alt=media&token=018461e7-67b9-43ce-90d7-3e493ff40ef7',
                title: 'Conciliação'
            }
        ],
        prototype: 'https://lab.aegro.design/ofx/import',
        description: 'Dentro da conciliação bancária, é comum encontrar situações em que N registros do extrato bancário correspondem a N lançamentos existentes no Aegro. Portanto, é necessário adicionar essa funcionalidade à nossa solução de conciliação, para garantir que os usuários possam efetivamente reconciliar todas as transações entre o extrato bancário e os registros do sistema.',
        status: 'delivered',
        upstream: {
            start: '2024-01-01',
            end: '2024-02-31',
        },
        downstream: {
            start: '2024-01-01',
            end: '2024-02-31',
        },
        links: [
            { id: '8743tg5984237g5t53', title: 'Protótipo implementado', url: 'https://lab.aegro.design/ofx/import' }
        ],
    },{
        id: 'fsanfosanfsanfsanfla',
        name: 'Categorias financeiras',
        teamId: '9823457263g598276g5',
        kr: ['89236g4589234g587345gt2983'],
        jtbd: [],
        platform: ['web', 'mobile'],
        images: [
            { 
                id: 'safhsajfhajhfajkhfjsahfsa',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F23-categorias.png?alt=media&token=8e6ff797-936d-477e-9129-b47662d97452',
                title: 'Possibilidade de gerenciar as categorias financeiras'
            },
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F0ymLOqsaysh4sqPOqCvmGm%2F%255BWeb%255D-Lan%25C3%25A7amento%253A-Nota-Fiscal-e-Livro-Caixa%3Ftype%3Ddesign%26node-id%3D2274%253A1189%26mode%3Ddesign%26t%3DGA62dJcVkxMzaIG5-1',
        description: 'Uma das funcionalidades mais solicitadas pelos usuários, o tão requisitado Plano de Contas, em breve estará disponível. Com essa adição planejada, os usuários terão a capacidade de gerenciar as categorias financeiras de forma personalizada, adaptando o sistema para atender a uma ampla variedade de necessidades de gerenciamento. Além de fornecer flexibilidade e controle aos usuários sobre suas finanças, o Plano de Contas também facilitará a organização e a análise dos dados contábeis, permitindo uma tomada de decisão mais informada e estratégica.',
        status: 'backlog',
        upstream: {
            start: '2024-01-01',
            end: '2024-03-1',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-06-1',
        },
        links: [
            { id: 'dsafbsakjbfksabfkjsafsa', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards/#id=5656452&editor-card-id=%22report-49261377%22' }
        ],
    },{
        id: 'fsahu9fsahfajahfkahjkchz',
        name: 'Dashboard financeiro mobile',
        teamId: '9823457263g598276g5',
        kr: ['293485g79823764592834'],
        jtbd: [],
        platform: ['mobile'],
        images: [
            { 
                id: 'dsahfsafsajfhsakfhjhfas',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F22-dashboard-financeiro.png?alt=media&token=0ee3c5cd-7c8d-4362-b38d-840cda2db717',
                title: 'Fluxo de Caixa no aplicativo mobile'
            },
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FMfHkEpNftaJL8m3itezzgM%2FMobile%253A-GF_Dashboard_Financeiro_V1%3Fpage-id%3D1790%253A14665%26type%3Ddesign%26node-id%3D1891-18386%26viewport%3D670%252C469%252C0.24%26t%3DCWH3H6l67DZfzfp7-1%26scaling%3Dscale-down%26starting-point-node-id%3D1891%253A18386%26show-proto-sidebar%3D1%26mode%3Ddesign',
        description: 'No aplicativo Aegro Campo, temos poucas funcionalidades que apresentam dados gerenciais para os usuários. Dessa forma, foi decidido criarmos visualizações financeiras dentro do celular para que o usuários possam checar a saúde financeira da fazenda a qualquer momento, remotamente',
        status: 'backlog',
        upstream: {
            start: '2024-01-01',
            end: '2024-03-1',
        },
        downstream: {
            start: '2024-01-01',
            end: '2024-03-1',
        },
        links: [
            { id: 'dsasafafasxzfzxs', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards/#id=5656452&editor-card-id=%22report-49261377%22' }
        ],
    },{
        id: 'safhsa9hfusahfoasjhcx08',
        name: 'Bloqueio financeiro',
        teamId: '9823457263g598276g5',
        kr: ['89236g4589234g587345gt2983'],
        jtbd: [],
        platform: ['web'],
        images: [
            { 
                id: 'asdasuhfu9ashfusahjfhxz',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F20-bloqueio.png?alt=media&token=9a4c880f-3096-4d46-8533-4a5752c8ca01',
                title: 'Possibilidade de fechar um período de uma conta bancária em específico'
            },
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FUbtbyXcfYvDzeL1Nh9aa1m%2FBloqueio-de-Lan%25C3%25A7amentos%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D2-3038%26viewport%3D12588%252C2462%252C0.65%26t%3Dgv02DK29iCPupo3k-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D2%253A3038%26mode%3Ddesign',
        description: 'Após o fechamento de um período financeiro, se faz importante bloquear o mesmo para que não haja lançamentos erroneos',
        status: 'backlog',
        upstream: {
            start: '2024-01-01',
            end: '2024-03-1',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-05-1',
        },
        links: [
            { id: 'dsasafafasxzfzxs', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards/#id=5656452&editor-card-id=%22report-49261377%22' }
        ],
    },{
        id: 'fs0adf-sdahghdikoapjkcxjv',
        name: 'Importar notas da Sefaz para o pedido de compras',
        teamId: '9823457263g598276g5',
        kr: ['89236g4589234g587345gt2983'],
        jtbd: [],
        platform: ['web'],
        images: [
            { 
                id: 'sf0ahfu9sahf9usahf9usahuhdsak',
                src: 'https://firebasestorage.googleapis.com/v0/b/aegro-design.appspot.com/o/roadmap%2Fpictures%2F21-adicionar-pedido-de-compra.png?alt=media&token=4bf95a7e-0924-4e51-803f-01a37b82d542',
                title: 'Possibilidade de adicionar o lançamento a um pedido de compra'
            },
        ],
        prototype: 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FtNotdlnJYZeacwyW2EC5NE%2F%255BWeb%255D-Pedido-de-Compra%3Ftype%3Ddesign%26node-id%3D5929%253A12431%26mode%3Ddesign%26t%3DPjw6M2X5bvvi1atS-1',
        description: 'A funcionalidade de Importação Sefaz é amplamente utilizada no Aegro atualmente. No entanto, não era possível anteriormente adicionar diretamente as notas recebidas da Sefaz a um Pedido de Compra existente, o que resultava em retrabalho para os usuários. Com essa melhoria, os usuários poderão agora integrar as notas da Sefaz diretamente aos Pedidos de Compra existentes, proporcionando maior eficiência e eliminando a necessidade de trabalho duplicado',
        status: 'backlog',
        upstream: {
            start: '2024-03-01',
            end: '2024-05-1',
        },
        downstream: {
            start: '2024-03-01',
            end: '2024-05-1',
        },
        links: [
            { id: 'fasufhas0d9fhsd', title: 'Mixpanel', url: 'https://mixpanel.com/project/2148679/view/303039/app/boards/#id=5656452&editor-card-id=%22report-49261377%22' }
        ],
    }
];

export {
    teams, 
    krs,
    JTBDs,
    deliverables
}