import './index.sass';
import { Button, ButtonDropdown, Checkbox, Divider, Icon, OverlayPositioned, SearchBar, Tabs } from '../../components';
import { useState, useEffect } from 'react';
import { AegroMenu } from '../../composed_components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NFeUpsellCrop from './NFeUpsellCrop';

function NFe(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const [currentTab, setCurrentTab] = useState('');
    const [upsell, setUpsell] = useState(false);

    useEffect(() => {
        const tab = location.pathname.split('/')[2];
        setCurrentTab(tab ? tab : 'home');
    }, [setCurrentTab,location.pathname]);

    const tabs = [
        { key: 'home', name: 'Início' },
        { key: 'fiscal', name: 'Fiscal' },
        { key: 'team', name: 'Equipe' },
        { key: 'crop', name: 'Safras', badge: 'pro' },
        { key: 'things', name: 'Patrimônio', badge: 'pro' },
        { key: 'finance', name: 'Financeiro', badge: 'pro' },
    ];
    const tabs2 = [
        { key: 'nfe', name: 'NF-e' },
        { key: 'mdfe', name: 'MDF-e' },
        { key: 'sefaz', name: 'Importação Sefaz' },
        { key: 'lcdpr', name: 'Livro Caixa' },
    ];

    const filter = {
        by: 'user',
        view: 'monthly',
        date: { startDate: new Date().toISOString(), endDate: new Date().toISOString()},
        tags: [
            {
                key: 'bank-account',
                label: 'Conta bancária',
                icon: 'bank',
                type: 'multi',
                added: new Date().getTime(),
                options: [
                    { key: 'nubank', label: 'Nubank' },
                    { key: 'bradesco', label: 'Bradesco' },
                ],
                active: [{ key: 'nubank', label: 'Nubank' }]
            },
            {
                key: 'value-range',
                label: 'Valor',
                icon: 'currency',
                type: 'range',
                added: new Date().getTime(),
                min: 0.00,
                max: 10000000.00,
                step: 0.01,
                active: null,
            }
        ]
    }

    const handleTab = (tab) => {
        if (['home', 'fiscal', 'team'].indexOf(tab) > -1) {
            navigate(tab === 'home' ? '' : tab);
        } else {
            setUpsell(tab);
        }
    }

    return (
        <div className='NFe'>
          <AegroMenu/>
          <div className="NFe__Content scrollable">
            <div className="NFe__Submenu">
                <Tabs current={currentTab} tabs={tabs} type="floater2" onChangeTab={handleTab}/>
            </div>
            <Divider/>
            <Outlet/>
          </div>
          {
            upsell && 
                <OverlayPositioned blur>
                    <div className="Upsell">
                        { upsell === 'crop' && <NFeUpsellCrop onClose={() => { setUpsell(false) }}/> }
                        { upsell === 'things' && <NFeUpsellCrop onClose={() => { setUpsell(false) }}/> }
                        { upsell === 'finance' && <NFeUpsellCrop onClose={() => { setUpsell(false) }}/> }
                    </div>
                </OverlayPositioned>
          }
        </div>
    )
}

export default NFe;