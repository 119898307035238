import './Overlay.sass'
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';


const Overlay = (props) => {

    const [container] = useState(document.createElement('div'))
    container.classList.add('Overlay');

    useEffect(() => {
        const modalRoot = document.body.querySelector('.App__Overlay');
        modalRoot.appendChild(container)
        return () => {
            modalRoot.removeChild(container)
        }
    }, [container])
    
    return ReactDOM.createPortal(props.children, container,);
} 

export default Overlay;