import './style.sass';
import { Button, Icon, Divider, ButtonDropdown, Tooltip } from '../../../../components';
import cat_icons from '../../../../data/categories_icon.json';


function ConciliationRow(props) {

    const options = [
        { key: 'edit', label: 'Editar' },
        { key: 'view', label: 'Visualizar' },
    ];

    const handleCartInteractionFull = (id) => {
        if (props.selected && !props.cart) {
            if (props.linked) {
                if (props.onRemoveCart) props.onRemoveCart(id);      
            }
        }
        if (!props.selected && !props.cart) {
            if (props.onCart) props.onCart(id);
        }
    }

    const handleCartInteraction = (id) => {
        if (props.selected && !props.cart) {
            if (!props.linked) {
                if (props.onLook) props.onLook(id);
            } else {
                if (props.onRemoveCart) props.onRemoveCart(id);
            }            
        }
        if (!props.selected && !props.cart) {
            if (props.onCart) props.onCart(id);
        }
        if (props.cart) {
            if (props.onRemoveCart) props.onRemoveCart(id);
        }
    }

    const processDate = (isoDate) => {
        const sDate = isoDate.split('-');
        return new Date(sDate[0], sDate[1]-1, sDate[2]);
    }

    return (
        <div className={`ConciliationRow ${props.bill?.value<0 ? 'negative' : ''} ${props.selected ? 'selected' : ''} ${props.cart ? 'cart' : ''}  ${props.linked ? 'linked' : ''}  ${props.linked && props.matched ? 'matched' : ''}`}>
            <div className='interactable'></div>
            <div className='date body2' onClick={() => {handleCartInteractionFull(props.bill.id)}}>
                {processDate(props.bill.date).toLocaleDateString('pt-BR')}
            </div>
            <div className='body2 category' onClick={() => {handleCartInteractionFull(props.bill.id)}}>
                <Tooltip text={props.bill.category}>
                    <Icon small name={cat_icons[props.bill.category]}/>
                </Tooltip>
                <div className='ellipsis'>{props.bill.category}</div>
            </div>
            <div className='ConciliationRow__Transfer' onClick={() => {handleCartInteractionFull(props.bill.id)}}>
                <div className='body2 ellipsis'>{props.bill.account}</div>
                <Icon name={props.bill?.value<0 ? 'arrow-right': 'arrow-left'}/>
                <div className='body2 ellipsis'>{props.bill.part}</div>
            </div>
            <div className='installment body2' onClick={() => {handleCartInteractionFull(props.bill.id)}}>
                {props.bill.installment}
            </div>
            <div className='value body2' onClick={() => {handleCartInteractionFull(props.bill.id)}}>
                {Intl.NumberFormat('pt-BR', {style:'currency', currency: 'BRL'}).format(props.bill?.value)}
            </div>
            <ButtonDropdown icon='more-vertical' options={options}/>            
            <div className='interaction'>
                { !props.cart &&  
                    <Tooltip text={props.selected && !props.linked ? 'Parcela já selecionada para outro lançamento. Clique para ver' : null}>
                        <Button type='icon' icon={props.selected ? (props.linked ? 'checkbox-outline' : 'receipt'): 'checkbox-blank-outline'} onClick={() => {handleCartInteraction(props.bill.id)}}/>
                    </Tooltip>
                }
                { props.cart &&  <Button type='icon' icon={props.selected ? 'checkbox-outline': 'checkbox-blank-outline'} onClick={() => {handleCartInteraction(props.bill.id)}}/>}
            </div>
            <Divider/>            
        </div>
    )
}

export default ConciliationRow;