import { Button, ButtonDropdown, Icon, Tabs } from '../../components';
import './style.sass';
import { useState, useRef } from 'react';

function ModuleListItem(props) {

    const handleClick = () => {
        if (props.onClick) props.onClick();
    }

    return (
        <div className={`ModuleListItem`} onClick={handleClick}>
            { props.iconLeft && <Icon name={props.iconLeft}/> }
            <div className="left">
                <div className="body title">{props.title}</div>
                <div className="body2 subtitle">{props.subtitle}</div>
            </div>            
            { props.iconRight && <Icon name={props.iconRight}/> }
        </div>
    )
}

export default ModuleListItem;
