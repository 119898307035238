import { Button, Checkbox, Divider, Icon, Loader, OverlayPositioned, Tabs } from '../../components';
import './KRSelector.sass';
import { useState, useRef } from 'react';
import { findIndex, cloneDeep } from 'lodash';

function KROptions(props) {

    const processKRs = (krs) => {
        const result = [];

        krs.filter(k => !k.parent).forEach(kr => {
            result.push({
                ...kr,
                children: []
            })
        });

        krs.filter(k => k.parent).forEach(kr => {
            const idx = findIndex(result, r => r.id === kr.parent);
            result[idx].children.push(kr);
        })

        return result;
    }

    const handleKRChange = (kr) => {
        const nChecked = cloneDeep(props.checked);
        const idx = nChecked.indexOf(kr.id);

        if (idx > -1) {
            nChecked.splice(idx, 1);
        } else {
            nChecked.push(kr.id)
        }

        if (props.onChange) {
            props.onChange(nChecked);
        }
    }

    return (
        <div className={`KROptions scrollable`}> 
        { processKRs(props.krs).map(okr => 
            <div key={okr.id} className="group">
                <div className="description">{okr.description}</div>
                { okr.children.map(kr => 
                    <div key={kr.id} className="option">
                        <Checkbox checked={props.checked.indexOf(kr.id) > -1} onChange={ () => handleKRChange(kr) }/>
                        <div className="description">{kr.description}</div>
                    </div>)
                }
                <Divider/>
            </div>            
        )}
        </div>
    )
}

function KRSelector(props) {

    const [opened, setOpened] = useState(false);
    const ref = useRef();

    const handleChange = (checked) => {
        if (props.onChange) {
            props.onChange(checked);
        }
    }

    return (
        <div className={`KRSelector scrollable`} ref={ref}> 
            <div className="field" onClick={() => {setOpened(true)}}><Icon name="bullseye-arrow"/> {props.checked.length} key results</div>
            {
                opened && 
                    <OverlayPositioned element={ref.current} onClose={() => {setOpened(false)}}>
                        <KROptions checked={props.checked} krs={props.krs} onChange={handleChange}/>
                    </OverlayPositioned>
            }   
        </div>
    )
}


export default KRSelector;