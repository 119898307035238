import './style.sass';

function VStack(props) {    
    return (
        <div className={`VStack ${props.fillHeight ? 'fillHeight' : ''} ${props.fillWidth ? 'fillWidth' : ''} ${props.spacing || 'normal'}`} style={props.style}>
            {props.children}
        </div>
    )
}

export default VStack;

