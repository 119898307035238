import './style.sass';
import { Button } from '../../components';
import { LCPDTopBar } from '../../composed_components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

function SimulIR(props) {
    
    const navigate = useNavigate();
    const location = useLocation();
    const tabArr = location.pathname.split('/');
    const tab = tabArr[tabArr.length - 1];

    const persons = {
        pedro: "Pedro Muniz",
        thiara: "Thiara Ramirez",
    }

    const [name, setName] = useState(persons[tab]);
    const [year, setYear] = useState('2025');

    const changeYear = (sel) => {
        setYear(sel.key);
    }

    const PersonItem = (props) => {

        const handleClick = () => {
            if (props.name) {
                navigate(`/ir${props.link}`)
                setName(props.name);
            }
        }

        return (
            <div className={`PersonItem ${props.selected ? 'selected' : ''}`} onClick={handleClick}>
                <div className="PersonIcon">
                    { props.name ? props.name.charAt(0) : '+' }
                </div>
                { props.name || 'Novo Livro Caixa' }
            </div>
        )
    }
    
    return (
        <div className='SimulIR l'>
          <LCPDTopBar onChangeYear={changeYear} />
          <div className="SimulIR__Content">
            <div className="SimulIR__Content__Left">
                <div className="SimulIR__Content__Left__Section">

                </div>

                <div className="SimulIR__Content__Left__Section">
                    <div className="overline">Parcelas</div>
                    <Button type="text-grey" small icon="receipt" label="Parcelas não-atribuídas"/>
                    <Button type="text-grey" small icon="receipt" label="Parcelas atribuídas"/>
                    <Button type="text-grey" small icon="receipt" label="Parcelas ignoradas"/>
                </div>
                <div className="SimulIR__Content__Left__Section">
                    <div className="overline">Livros Caixa</div>
                    <PersonItem selected={tab === 'pedro'} link="/pedro" name="Pedro Muniz"/>
                    <PersonItem selected={tab === 'thiara'} link="/thiara" name="Thiara Ramirez"/>
                    <PersonItem/>
                </div>
                <div className="SimulIR__Content__Left__Section">
                    <div className="overline">Outras Informações</div>
                    <Button type="text-grey" small icon="receipt" label="Imóveis Rurais"/>
                    <Button type="text-grey" small icon="receipt" label="Contadores Associados"/>
                </div>
            </div>
            <Outlet context={[name, year]}/>
          </div>
        </div>
    )
}

export default SimulIR;