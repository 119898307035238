import './style.sass';

import { useId } from 'react';

function Radio(props) {

    const uId = useId();

    const handleChange = (ev) => {
        if (props.onChange) {
            props.onChange(ev)
        }
    }

    return (
        <div className={`Radio`}>
            <input type="radio" id={uId} name={props.name} checked={props.checked} value={props.value} onChange={handleChange}/>
            <label htmlFor={uId}>{}
                <div className="Radio__Circle transition-out"></div>
                <div className="Radio__Label">{props.label}</div>
            </label>
        </div>
    )
}

export default Radio;
