import './style.sass';
import { useState } from 'react';
import { Accordion, Button, Divider, Icon, Loader, ButtonDropdown } from '../../../../components';
import ConciliationRow from '../ConciliationRow';
import { 
    getTotalValue,
    getSum,
    getValid
} from '../../DBFunctions';

function ConciliationCart(props) {

    const BottomNumber = ({label, number}) => {
        return (
            <div className='ConciliationCart__BottomInfo__BottomNumber'>
                <div className='caption'>{label}</div>
                <div className={`body1 ${number<0 ? 'negative': ''}`}>
                    {Intl.NumberFormat('pt-BR', {style:'currency', currency:'BRL'}).format(number)}
                </div>
            </div>
        )
    }

    const handleRemove = (id) => {
        if (props.onRemoveCart) props.onRemoveCart(id);
    }

    const [reconcile, setReconcile] = useState(null);
    
    const handleConciliation = () => {
        setReconcile('loading');
        setTimeout(() => {
            setReconcile('done');
            setTimeout(() => {
                if(props.onPreReconcile) props.onPreReconcile()
            }, 500);
            setTimeout(() => {
                setReconcile(null);
                if(props.onReconcile) props.onReconcile()
            }, 800);
            
        }, 1000);
    }

    const CartAnimation = (props) => {
        return (
            <div className={`ConciliationCart__Animation ${props.done ? 'done' : ''}`}>
                <div className='ConciliationCart__Animation__1'><Icon name="receipt"/></div>
                <div className='ConciliationCart__Animation__2'>
                    { !props.done && <Loader/> }
                    { props.done && <Icon name='link'/> }
                </div>
                <div className='ConciliationCart__Animation__2__Ripple'></div>
                <div className='ConciliationCart__Animation__3'><Icon name="cash-multiple"/></div>
            </div>
        )
    }

    const differenceOptions = [
        { key: 'bill', label: 'Novo Lançamento', icon: 'cash-multiple' },
        { key: 'wire', label: 'Nova Transferência', icon: 'arrow-right' },
    ]

    return (
        <div className={`ConciliationCart transition-out ${props.active ? 'active' : ''} ${getValid(props.item) ? 'valid' : ''}`}>
            {reconcile && <CartAnimation done={reconcile === 'done'}/> }
            <Accordion
                full
                opened={true}
                header={
                    <div className='ConciliationCart__Header'>
                        { props.item?.bills?.length === 0 && <span>Selecione parcelas para conciliar</span> }
                        { props.item?.bills?.length > 0 && 
                            <div className='name-comp'>
                                <div>Conciliar</div>
                                <b>"</b><div className='ofx-label ellipsis'>{props.item.label}</div><b>"</b>
                                <div><b>{ props.item.children && props.item.children.length > 0 ? `+ ${props.item?.children.length}` : ''}</b></div>
                                <div>com { props.item?.bills?.length } parcela{props.item?.bills?.length > 1 || props.item?.bills?.length === 0 ? 's' : ''}</div> 
                            </div>
                        }
                    </div>
                }
            >
                <div className='ConciliationCart__BillsList scrollable'>
                    {props.item?.bills && props.item?.bills.map(bill => (
                        <ConciliationRow cart selected bill={bill} key={bill.id} onRemoveCart={() => {handleRemove(bill.id)}}/>
                    ))}
                </div>
            </Accordion>
            <Divider/>
            {
                props.item?.bills && props.item?.bills.length > 0 &&
                <div className='ConciliationCart__BottomInfo'>
                    <BottomNumber label='Nubank' number={getTotalValue(props.item)}/>
                    <BottomNumber label='Aegro' number={getSum(props.item)}/>
                    <BottomNumber label='Diferença' number={getTotalValue(props.item) - getSum(props.item)}/>
                    {/* <ButtonDropdown options={differenceOptions} label='Resolver Diferença'/> */}
                    <div className='ConciliationCart__BottomInfo__Buttons'>
                        <Button disabled={!getValid(props.item)} label="Conciliar" onClick={handleConciliation}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default ConciliationCart;