import './style.sass';

function Percent(props) {

    return (
        <div className={`Percent ${props.dashed ? 'dashed' : ''}`}>
            <svg viewBox="0 0 32 32">
                <circle className="track" cx="16" cy="16" r="12"/>
                <circle className="fill transition-out" cx="16" cy="16" r="12" style={{ strokeDashoffset: 76 - ((76 / 100) * (props.percent || 0)) }}/>
            </svg>
            {
                !props.hideLabels &&
                    <div className="Percent__Labels">
                        <div></div>
                        <div>{props.percent || 0}%</div>
                    </div>
            }
        </div>
    )
}

export default Percent;
