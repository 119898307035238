import { Button, Icon, Tooltip } from '../../components';
import './details.sass'

function Details () {

    return (
        <div className='Details'>
            <div className='content'>
                <div className='Details__Info'>
                    <div className='Details__Info__Group'>
                        <div className='Details__Info__Image'>
                            <img src='img/nubank.png' alt='nubank'></img>
                        </div>
                        <div className='Details__Field'>
                            <div className='caption'>Nome da conta bancária</div>
                            Banco Nubank
                        </div>
                        <div className='Details__Field'>
                            <div className='caption'>Código</div>
                            260
                        </div>
                    </div>
                    <Button type='outline' label='Editar' icon='pencil'/>
                </div>
                <div className='Details__Stats'>
                    <div className='Details__Stats__Left'>
                        <div className='Details__Stats__Left__Balance boxed'>
                            <div className='overline'>Saldo Inicial</div>
                            <div className='Details__Info__Group'>
                                <div className='Details__Field'>
                                    <div className='caption'>Data do saldo inicial</div>
                                    20/01/2022
                                </div>
                                <div className='Details__Field'>
                                    <div className='caption'>Daldo inicial (R$)</div>
                                    R$ 80.547,90
                                </div>
                            </div>
                        </div>
                        <div className='Details__Stats__Left__Data boxed'>
                            <div className='overline'>Dados de sua conta bancária</div>
                            <div className='Details__Info__Group'>
                                <div className='Details__Field'>
                                    <div className='caption'>Agência</div>
                                    0245
                                </div>
                                <div className='Details__Field'>
                                    <div className='caption'>Número da conta</div>
                                    64587-5
                                </div>
                                <div className='Details__Field'>
                                    <div className='caption'>País</div>
                                    Brasil
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Details__Stats__Right boxed'>
                        <div className='overline'>Titulares</div>
                        <div className='Details__Stats__User'>
                            <Icon name='account-circle-outline'/>
                            Não informado
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details;