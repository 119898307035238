import './style.sass';

function Loader(props) {

    return (
        <svg className={`Loader`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10"/>
        </svg>
    )
}

export default Loader;
