import { useRef, useState } from 'react';
import { DropdownItem, InputText, OverlayPositioned, Icon, Divider, Button } from '..';
import { cloneDeep, kebabCase } from 'lodash';
import './style.sass';

function Dialog(props) {

    const handleChoice = (choice) => {
        if (props.onChoice) props.onChoice(choice);
    }

    return (
        <OverlayPositioned blur>
            <div className='Card fix'>
                <div className="Dialog">
                    <div className="Dialog__Messages">
                        <div className="h6">{props.message}</div>
                        { props.submessage && <div className="body">{props.submessage}</div> }
                    </div>
                    <div className="Dialog__Actions">
                        <Button type="outline" label={props.secondaryLabel || 'Cancelar'} onClick={()=>{handleChoice(false)}}/>
                        <Button label={props.primaryLabel || 'Ok'} onClick={()=>{handleChoice(true)}}/>
                    </div>
                </div>
            </div>        
        </OverlayPositioned>
    )
}

export default Dialog;
