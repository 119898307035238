import './NFeFiscal.sass';

import { Button, ButtonMulti, OverlayPositioned, SearchBar, } from '../../components';
import { useState } from 'react';
import { DataGrid, SidebarContent } from '../../composed_components';

import NewNFe from './components/NewNFe';
import ViewNfe from './components/ViewNFe';

import { nfeModel, gridData } from './database';

function NFeFiscal(props) {

    const tabs2 = [
        { key: 'nfe', name: 'NF-e', label: 'NF-e' },
        { key: 'mdfe', name: 'MDF-e', label: 'MDF-e', badge: 'pro' },
        { key: 'sefaz', name: 'Importação Sefaz', label: 'Importação Sefaz', badge: 'pro' },
        { key: 'lcdpr', name: 'Livro Caixa', label: 'Livro Caixa', badge: 'pro' },
    ];

    const filter = {
        by: 'user',
        view: 'monthly',
        date: { startDate: new Date().toISOString(), endDate: new Date().toISOString()},
        tags: [
            {
                key: 'bank-account',
                label: 'Conta bancária',
                icon: 'bank',
                type: 'multi',
                added: new Date().getTime(),
                options: [
                    { key: 'nubank', label: 'Nubank' },
                    { key: 'bradesco', label: 'Bradesco' },
                ],
                active: [{ key: 'nubank', label: 'Nubank' }]
            },
            {
                key: 'value-range',
                label: 'Valor',
                icon: 'currency',
                type: 'range',
                added: new Date().getTime(),
                min: 0.00,
                max: 10000000.00,
                step: 0.01,
                active: null,
            }
        ]
    }

    const options = {
        icon: 'receipt',
        title: 'Fiscal',
        subtitle: 'Emita notas fiscais e outras obrigações fiscais',
        tabs: tabs2,
        currentTab: 'nfe'
    };

    const btnOptions = [
        { key: 'xlsx', icon: 'xlsx-color', label: 'Baixar relatório em .xlsx' },
        { key: 'pdf', icon: 'pdf-color', label: 'Baixar relatório em .pdf' }
    ];

    const handleNfeDataChange = (newData) => {
        setNfeData(newData);
    }

    const handleDataSelect = (rowIndex) => {
        setOverlayNFe('view');
        // setNfeData(gridData.data[rowIndex]);
    }

    const [overlayNFe, setOverlayNFe] = useState(false);
    const [nfeData, setNfeData] = useState(nfeModel);

    return (
        <div className='NFeFiscal'>
            <div className="NFe__Content scrollable">
            <SidebarContent { ...options } section="Fiscal" contentTitle="NF-e">
                <SearchBar filters={filter}/>
                <div className="TableActions">
                    <Button label="Nova NFe" icon="plus" onClick={()=>{ setOverlayNFe('new'); setNfeData(nfeModel) }}/>
                    <ButtonMulti label="Relatório" options={btnOptions}/>
                </div>
                <DataGrid data={gridData} onClick={handleDataSelect}/>
                {
                    overlayNFe &&                     
                        <OverlayPositioned>
                            { overlayNFe === 'new' && <NewNFe nfeData={nfeData} onChange={handleNfeDataChange} onClose={()=>{ setOverlayNFe(false) }}/> }
                            { overlayNFe === 'view' && <ViewNfe nfeData={nfeData} onChange={handleNfeDataChange} onClose={()=>{ setOverlayNFe(false) }}/> }
                        </OverlayPositioned>
                }
            </SidebarContent>
          </div>
        </div>
    )
}

export default NFeFiscal;