import { useCallback, useEffect, useRef, useState } from 'react';
import Overlay from '../Overlay/Overlay';
import './OverlayPositioned.sass'


const OverlayPositioned = (props) => {

    const floater = useRef(null);

    const [style, setStyle] = useState({})
    const [clss, setClss] = useState('')

    const calculateFloaterPosition = useCallback(() => {     
        const stl = {};
        
        if (!props.element || !floater) {
            stl.opacity = 1;
            setStyle(stl);
            return;
        }
        if (!floater.current) {
            stl.opacity = 1;
            setStyle(stl);
            return;
        }

        const margin = 6;

        const wRect = { top: 0, left: 0, right: window.innerWidth, bottom: window.innerHeight }
        const elRect = props.element.getBoundingClientRect();
        const flRect = floater.current.getBoundingClientRect();

        let nClss = [];

        stl.opacity = 1;
        
        if (elRect.bottom + flRect.height + margin > wRect.bottom ) {
            // Verificar esse comportamento
            // Goes above
            stl.bottom = wRect.bottom - elRect.top + margin;
            stl.maxHeight = elRect.top - margin
            // stl.height = elRect.top - margin
            nClss.push('above');
        } else {
            // Goes bellow
            stl.top = elRect.bottom + margin;
            nClss.push('below');
        }

        if (elRect.left + flRect.width > wRect.right) {
            // Goes Left
            stl.right = wRect.right - elRect.right;
            nClss.push('left');
        } else {
            // Goes Right
            stl.left = elRect.left;
            nClss.push('right');
        }

        if (props.fill) stl.minWidth = elRect.width;
        
        setClss(nClss.join(' '));
        setStyle(stl);
    },[props.element, props.fill]);

    useEffect(() => {
        calculateFloaterPosition();
    }, [calculateFloaterPosition]);


    const handleClose = () => {
        if (props.onClose) props.onClose();
    }

    return (
        <Overlay blur={props.blur}>
            <div className='OverlayPositioned'>
                <div className={`OverlayPositioned__Background ${props.blur ? 'blur' : ''}`} onClick={handleClose}></div>
                <div ref={floater} style={style} className={`OverlayPositioned__Floater ${clss}`}>
                    {props.children}
                </div>
            </div>            
        </Overlay>
    );
} 

export default OverlayPositioned;