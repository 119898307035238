import './style.sass';
import { Divider, Button, Tabs, Icon, Radio } from '../../components';
import { useState, useEffect } from 'react';
import Simulador from './Simulador';
import List from './List';
import { useOutletContext } from 'react-router-dom';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function SimulIRContent(props) {

    const getCurrency = (value) => {
        return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }

    const randomData = () => {
        const data = [];
        
        const m = Math.floor(Math.random() * (13 - 1) + 1);

        const dataStructure = [
            {
                stack: 'Despesa',
                names: ['projetada', 'realizada'],
                color: '#EE3737',
                colors: ['#ffa5a5', '#EE3737'],
            },
            {
                stack: 'Receita',
                names: ['projetada', 'realizada'],
                color: '#2196F3',
                colors: ['#79b9ed', '#2196F3'],
            }
        ];

        const pcts = [];
        for (let index = 1; index < 13; index++) {
            pcts.push(index <= m ? Math.random() * (0.4 - 0) + 0 : 0)
        }

        dataStructure.forEach(str => {
            const dt = [];
            for (let index = 1; index < 13; index++) {
                dt.push(index <= m ? Math.random() * (2076789.00 - 0) + 0 : 0)
            }
            str.names.forEach((nm, i) => {
                const pctDt = [];
                dt.forEach((dataPoint, idx) => {
                    const factor = nm === 'pendentes' ? pcts[idx] : (1-pcts[idx])
                    pctDt.push(dataPoint * factor);
                });
                data.push({ 
                    name: `${str.stack} ${nm}`, 
                    data: pctDt, 
                    stack: str.stack ,
                    color: str.colors[i]
                });
            })
        });  
        return data;
    }
    
    const tabs = [
        {
            name: 'Livro Caixa',
            key: ''
        },{
            name: 'Imposto de Renda',
            key: 'ir',
            badge: 'new'
        }
    ]

    const months = {
        JAN: 'Janeiro',
        FEV: 'Fevereiro',
        MAR: 'Março',
        ABR: 'Abril',
        MAI: 'Maio',
        JUN: 'Junho',
        JUL: 'Julho',
        AGO: 'Agosto',
        SET: 'Setembro',
        OUT: 'Outubro',
        NOV: 'Novembro',
        DEZ: 'Dezembro',
    }

    const [currentTab, setCurrentTab] = useState('');
    const [currentValueType, setCurrentValueType] = useState('realizado');
    const [data, setData] = useState(randomData());

    const [name, year] = useOutletContext();

    const changeTab = (tab) => {
        setCurrentTab(tab);
        localStorage.setItem('last-lcpd-tab', tab);
    }

    const valueTypeDict = {
        'realizado': 'real',
        'projetado': 'projection',
    }

    useEffect(() => {
        const lastTab = localStorage.getItem('last-lcpd-tab');
        setCurrentTab(lastTab || '');
    }, []);

    const options = {
        title: {
          text: null
        },
        chart: {
            className: 'HighChart',
            backgroundColor: null,
            type: 'column'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                states: {
                    // hover: {
                    //   color: '#00BE5E'
                    // }
                  }
            }
        },
        series: data,
        colorbypoint: true,
        xAxis: {
            categories: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
        },
        tooltip: {
            borderRadius: 8,
            padding: 12,
            shared: true,
            formatter: function() {
              let thisPoint = this.point,
                allSeries = this.series.chart.series,
                thisIndex = thisPoint.index,
                returnString = '<b>' + months[this.x] + '</b><br/><br/>';
        
              let despesaTot = 0;
              const despesaValues = [];
              let receitaTot = 0;
              const receitaValues = [];

              allSeries.forEach((ser) => {
                if (ser.options.stack === 'Despesas') {
                    despesaTot += ser.points[thisIndex].y;
                    despesaValues.push(ser.points[thisIndex].y);
                }
                if (ser.options.stack === 'Receitas') {
                    receitaTot += ser.points[thisIndex].y;
                    receitaValues.push(ser.points[thisIndex].y);
                }
              });
        
              returnString += `<b>Despesas:</b> ${getCurrency(despesaTot)}<br/>`;
              returnString += `<b>Pendente:</b> ${getCurrency(despesaValues[0])}<br/><br/>`;
              returnString += `<b>Receitas:</b> ${getCurrency(receitaTot)}<br/>`;
              returnString += `<b>Pendente:</b> ${getCurrency(receitaValues[0])}<br/>`;
        
              return returnString;
            }
          },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: null
            }
        },
      }

    const getTotals = () => {
        let receitaTot = 0;
        let receitaReal = 0;
        let despesaTot = 0;
        let despesaReal = 0;

        data.forEach(m => {            
            if (m.stack === 'Receita') {
                m.data.forEach(d => {
                    receitaTot += d;
                })
            }
            if (m.name === 'Receita realizada') {
                m.data.forEach(d => {
                    receitaReal += d;
                })
            }
            if (m.stack === 'Despesa') {
                m.data.forEach(d => {
                    despesaTot += d;
                })
            }
            if (m.name === 'Despesa realizada') {
                m.data.forEach(d => {
                    despesaReal += d;
                })
            }
        });

        const tots = {
            receita: receitaTot,
            receitaReal: receitaReal,
            despesa: despesaTot,
            despesaReal: despesaReal,
        }

        return tots
    }

    const NumberItem = (props) => {
        return (
            <div className={`NumberItem ${props.color}`}>
                <div className="NumberItem__Label overline">{props.label}</div>
                <div className={`NumberItem__Number transition-out ${props.active === 'real' ? 'active' : ''}`}>
                    {props.projection && <span>Realizada<br/></span>}
                    {props.real}
                </div>
                <div className={`NumberItem__Number transition-out ${props.active === 'projection' ? 'active' : ''}`}>
                    {props.projection && <span>Projetada<br/></span>}
                    {props.projection}
                </div>
            </div>
        )
    }

    const MonthItem = (props) => {
        return (
            <div className={`MonthItem ${props.count ? 'fill' : ''}`}>
                <div className="Header">
                    <div>
                        <Icon name="receipt"/>
                        {props.title}
                    </div>
                    <div>
                        { props.count && <Button type="icon" icon="alert-circle" count={props.count}/> }
                        <Icon name="chevron-right"/>
                    </div>
                </div>
                <Divider/>
                {props.count && 
                    <div className="Content">
                        <NumberItem label="Receitas" number="R$ 0,00"/> 
                        <NumberItem label="Despesas" number="R$ 1.300,00"/>
                        <NumberItem label="Saldo" number="- R$ 1.300,00"/>
                    </div>
                }
                { !props.count && 
                    <div className="Content empty">
                        <svg width="105" height="63" viewBox="0 0 105 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.3948 18.5228C10.6361 0.879 24.3991 -0.231233 30.5005 1.41912C51.355 3.6696 67.1083 27.8248 67.1083 15.6722C67.1083 3.51958 77.3105 -1.88158 89.1631 2.61939C101.016 7.12035 96.0645 18.5228 93.0639 21.2234C90.0633 23.924 74.91 33.0759 86.4625 36.3766C98.015 39.6773 102.966 55.5808 89.1631 61.132C75.3601 66.6831 81.9615 58.1313 57.9564 57.3811C33.9512 56.631 44.0034 64.7327 21.4986 61.132C-1.00627 57.5312 -3.40678 40.5775 4.3948 18.5228Z" fill="#E1EFE0"/>
                            <rect x="18.2395" y="8.67065" width="61.4136" height="39.0586" fill="white" stroke="black"/>
                            <path d="M25.2493 19.123H52.5552M54.9557 19.123H63.9576M25.2493 24.8243H58.8565M25.2493 31.2757H42.9531M45.0536 31.2757H61.7071M25.2493 37.8771H56.7561" stroke="#C4C4C4"/>
                            <ellipse cx="79.2609" cy="28.0499" rx="11.4025" ry="12.9778" fill="#EFEFEF"/>
                            <circle cx="77.6855" cy="27.75" r="13.228" stroke="black"/>
                            <path d="M86.7625 37.277L90.6633 41.1778" stroke="black"/>
                            <rect x="92.3137" y="39.9344" width="16.1865" height="4.09237" rx="2.04618" transform="rotate(45 92.3137 39.9344)" fill="#C4C4C4" stroke="black"/>
                        </svg>
                        <div className="body">Não encontramos parcelas neste mês.</div>
                    </div>
                }
            </div>
        )
    }


    return (
        <div key={name} className="SimulIR__Content__Right scrollable">
            <div className="Header">
                <div className="subtitle">{name}</div>
                <Tabs tabs={tabs} current={currentTab} onChangeTab={changeTab}/>
            </div>
            <Divider/>
            {currentTab === '' && 
                <div className="Content">
                    <div className="Toolbar">
                        <div className="period">
                            <Icon name="calendar"/>
                            Período de 01/01/{year} a 31/12/{year}
                        </div>
                        <div className="actions">
                            <Button type="outline" label="Gerar livro caixa"/>
                            <Button type="icon" icon="printer"/>
                            <Button type="icon" icon="more-vertical"/>
                        </div>
                    </div>
                    <div className="Numbers">
                        <NumberItem label="Receitas" real="R$ 0,00"/>
                        <NumberItem label="Despesas" real="R$ 1.300,00"/>
                        <NumberItem label="Saldo" real="- R$ 1.300,00"/>
                    </div>
                    <div className="Cards">
                        <MonthItem title="Janeiro" count={1}/>
                        <MonthItem title="Fevereiro"/>
                        <MonthItem title="Março"/>
                        <MonthItem title="Abril" count={2}/>
                        <MonthItem title="Maio"/>
                        <MonthItem title="Junho"/>
                    </div>
                </div>
            }
            { currentTab === 'ir' && 
                <div className="Content">    
                    <div className="h5">
                        Despesa X Receita
                        <Button icon="reload" type="icon"  onClick={() => { setData(randomData()) }}/>
                    </div>
                    <div className="GraphContainer">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                        {/* <Graph data={data}/> */}
                        <div className="Numbers">
                            <NumberItem 
                                active={valueTypeDict[currentValueType]}
                                color="blue" 
                                label="receita BRUTA total" 
                                real={getCurrency(getTotals().receitaReal)}
                                projection={getCurrency(getTotals().receita)}/>
                            
                            <NumberItem 
                                active={valueTypeDict[currentValueType]}
                                color="red" 
                                label="Despesa DE CUSTEIO E INVESTIMENTO Total" 
                                real={getCurrency(getTotals().despesaReal)}
                                projection={getCurrency(getTotals().despesa)}/>
                        </div>
                    </div>
                    <div className="Simulador__Fields__Selector">
                            <Radio label="Usar valores realizados" checked={currentValueType === 'realizado'} onChange={() => { setCurrentValueType('realizado') }}/>
                            <Radio label="Usar valores projetados" checked={currentValueType === 'projetado'} onChange={() => { setCurrentValueType('projetado') }}/>
                        </div>
                    <div className="SimulContainer">
                        <div style={{ flex: 1 }}>
                            <div className="h5">Despesas por categoria</div>
                            <List despesa={currentValueType === 'realizado' ? getTotals().despesaReal : getTotals().despesa}/>
                        </div>
                        <Simulador year={year} receita={currentValueType === 'realizado' ? getTotals().receitaReal : getTotals().receita} despesa={currentValueType === 'realizado' ? getTotals().despesaReal : getTotals().despesa}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default SimulIRContent;