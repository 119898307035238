import './components.sass';
import { Icon } from "../../../../components"
    
function NFeSection(props) {

    const handleExpand = () => {
        if (props.onExpand) props.onExpand(props.edit)
    }

    return (
        <div className={`NFeSection transition-in-out ${props.valid ? 'valid' : ''} ${props.expandable ? 'expandable' : ''}`}>
            {   props.expandable &&
                <div className="over" onClick={handleExpand}>
                    Detalhar 
                    <Icon small name="pencil"/>
                </div>
            }
            <NFeTitle title={props.title} icon={props.icon} valid={props.valid}/>
            {props.children}
        </div>
    )
}


function NFeSectionGroup(props) {

    const handleExpand = () => {
        if (props.onExpand) props.onExpand(props.edit)
    }

    return (
        <div className={`NFeSectionGroup transition-in-out ${props.valid ? 'valid' : ''} ${props.expandable ? 'expandable' : ''}`}>
            {   props.expandable &&
                <div className="over" onClick={handleExpand}>
                    Detalhar 
                    <Icon small name="pencil"/>
                </div>
            }
            <NFeTitle title={props.title} icon={props.icon} valid={props.valid}/>
            {props.children}
        </div>
    )
}

function NFeTitle(props) {
    return (
        <div className="NFeTitle">
            <Icon name={props.icon}/>
            <div className="h6">{props.title}</div>
            {/* { props.valid && <Icon name="check-circle"/> } */}
        </div>
    )
}

export {
    NFeSection, 
    NFeSectionGroup,
    NFeTitle
};