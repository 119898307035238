import { Button, Icon, Loader, OverlayPositioned, Tabs } from '../../components';
import './Gallery.sass';
import { useEffect, useState } from 'react';;

function Gallery(props) {

    const [currentItem, setCurrentItem] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);

    return (
        <div className={`Gallery ${props.type}`}> 
            <div className="Gallery__Controls">
                <Button type="icon" icon="arrow-left" onClick={ () => { if (currentItem > 0) setCurrentItem(currentItem - 1) } }/>
                <div className="open" onClick={() => { setFullscreen(true) }}></div>
                <Button type="icon" icon="arrow-right" onClick={ () => { if (currentItem < props.pics.length - 1) setCurrentItem(currentItem + 1) } }/>
            </div>
            <div className="Gallery__Pictures transition-out" style={{ transform: `translateX(-${currentItem * (100/props.pics.length)}%)`, width: `${props.pics.length * 100}%` }}>
                {
                    props.pics && props.pics.map((pic, i) => 
                        <div key={i} style={{ width: `${100/props.pics.length}%`}}>
                            <img src={pic.src} title={pic.title} alt={pic.title}/>
                        </div>
                    )
                }
            </div>

            {
                fullscreen && 
                    <OverlayPositioned blur>
                        <div className={`Gallery fullscreen`}>
                            <div className="Gallery__Controls">
                                <div className="close"><Button type="icon" icon="close" onClick={() => { setFullscreen(false) }}/></div>
                                <div className="arrow-wrapper"><Button type="icon" icon="arrow-left" onClick={ () => { if (currentItem > 0) setCurrentItem(currentItem - 1) } }/></div>
                                <div className="arrow-wrapper"><Button type="icon" icon="arrow-right" onClick={ () => { if (currentItem < props.pics.length - 1) setCurrentItem(currentItem + 1) } }/></div>
                            </div>
                            <div className="Gallery__Pictures transition-out" style={{ transform: `translateX(-${currentItem * (100/props.pics.length)}%)`, width: `${props.pics.length * 100}%` }}>
                                {
                                    props.pics && props.pics.map((pic, i) => 
                                        <div key={i} style={{ width: `${100/props.pics.length}%`}}>
                                            <img src={pic.src} title={pic.title} alt={pic.title}/>
                                            <div>{pic.title}</div>
                                            
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </OverlayPositioned>
            }
        </div>
    )
}

export default Gallery;