import Badge from '../Badge';
import Counter from '../Counter';
import './style.sass';

function Tabs(props) {

    const onClickTab = (key) => {
        if (props.onChangeTab) {
            props.onChangeTab(key);
        }
    }

    return (
        <div className={`Tabs ${props.full ? 'full' : ''} ${props.type || ''}`}>
            { props.tabs && props.tabs.map(tab => (
                <div key={tab.key} className={`Tab ${tab.key === props.current ? 'active' : ''}`} onClick={() => {onClickTab(tab.key)}}>
                    {tab.name}
                    {(!!tab.count && tab.count > 0) && <Counter small type='warning' count={tab.count}/>}
                    {tab.badge && <Badge type={tab.badge}/>}
                </div>
            )) }
        </div>
    )
}

export default Tabs;