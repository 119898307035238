import { Button, ButtonDropdown, Icon, OverlayPositioned, ResizableContent } from '../../components';
import './style.sass';

import { useState, useEffect } from 'react';
import { cloneDeep, remove, find, findIndex, orderBy } from 'lodash';

import cat_icons from '../../data/categories_icon.json';
import outcome_categories from '../../data/outcome_categories.json'
import income_categories from '../../data/income_categories.json'
import uniqid from 'uniqid';

import { saveLabData, listenLabData, updateEditing } from '../../services/docFunctions';

function OFXData(props) {

    useEffect(() => {
        const listener = listenLabData((snapshot) => {
            snapshot.forEach((doc) => {
                if (doc.id === 'ofx') {
                    setOfxList(orderBy(JSON.parse(doc.data().ofx), ['date', 'value'], ['asc', 'asc']));
                    setBillsList(orderBy(JSON.parse(doc.data().bills), ['date', 'value'], ['asc', 'asc']));
                    if (doc.data().editing) setBlockedItems(JSON.parse(doc.data().editing));
                }
            });    
        });

        return () => {
            listener();
        }
    }, []);

    const [ofxList, setOfxList] = useState([]);
    const [billsList, setBillsList] = useState([]);
    const [opened, setOpened] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [blockedItems, setBlockedItems] = useState([])
    
    const processDate = (isoDate) => {
        const sDate = isoDate.split('-');
        return new Date(sDate[0], sDate[1]-1, sDate[2]);
    }

    const newOFXItem = () => {
        const newId = uniqid();
        const newItem = {
            id: newId,
            date: '2023-01-20',
            label: '',
            value: 0,
            bills: [],
            status: 'pending'
        };
        setCurrentItem(newItem);
        setOpened('ofx');
    }

    const newBILLItem = () => {
        const newId = uniqid();
        const newItem = {
            id: newId,
            date: '2023-01-21',
            category: '',
            account: '',
            part: '',
            installment: '',
            value: 0
        };
        setCurrentItem(newItem);
        setOpened('bill');
    }

    const setEditing = (id, isEditing) => {
        updateEditing('ofx', isEditing, id, blockedItems)
            .then(newBlockedList => {
                setBlockedItems(newBlockedList)
            })
            .catch(err => {
                console.log(err);
            });
    }

    const updateOFXList = (list) => {
        setOfxList(orderBy(list, ['date', 'value'], ['asc', 'asc']));
        saveData(list, billsList);
    }

    const updateBILLSList = (list) => {
        setOfxList(orderBy(list, ['date', 'value'], ['asc', 'asc']));
        saveData(ofxList, list);
    }

    const saveData = (ofxlist, billslist) => {
        const data = {
            ofx: ofxlist,
            bills: billslist,
        };
        saveLabData('ofx', data)
            .then((response) => {
                // console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const itemOptions = [
        { key: 'mirror', label: 'Espelhar' },
        { key: 'edit', label: 'Editar' },
        { key: 'delete', label: 'Excluir' },
    ];

    const editOFX = (id) => {
        setEditing(id, true);
        const list = cloneDeep(ofxList);
        const item = find(list, i => i.id === id);
        setCurrentItem(item);
        setOpened('ofx')
    }
    const removeOFX = (id) => {
        const nOFXlist = cloneDeep(ofxList);
        remove(nOFXlist, o => o.id === id);
        updateOFXList(nOFXlist);

    }
    const editBILL = (id) => {
        setEditing(id, true);
        const list = cloneDeep(billsList);
        const item = find(list, i => i.id === id);
        setCurrentItem(item);
        setOpened('bill')
    }
    const removeBILL = (id) => {
        const nBILLLlist = cloneDeep(billsList);
        remove(nBILLLlist, b => b.id === id);
        updateBILLSList(nBILLLlist);
    }

    const mirrorItem = (from, id) => {
        const newId = uniqid();
        if (from === 'ofx') {
            const list = cloneDeep(ofxList);
            const item = find(list, i => i.id === id);
            const newItem = {
                id: newId,
                date: item.date,
                category: '',
                account: '',
                part: '',
                installment: '',
                value: item.value
            };
            setCurrentItem(newItem);
            setOpened('bill');
        } else if (from === 'bill') {
            const list = cloneDeep(billsList);
            const item = find(list, i => i.id === id);
            const newItem = {
                id: newId,
                date: item.date,
                label: '',
                value: item.value,
                bills: [],
                status: 'pending'
            };
            setCurrentItem(newItem);
            setOpened('ofx');
        }
    }

    const handleOptions = (type, id, option) => {
        if (option.key === 'delete') {
            if (type === 'ofx') removeOFX(id);
            if (type === 'bill') removeBILL(id);
        }
        if (option.key === 'edit') {
            if (type === 'ofx') editOFX(id);
            if (type === 'bill') editBILL(id);
        }
        if (option.key === 'mirror') {
            mirrorItem(type, id);
        }
    }

    const editField = (field, val) => {
        const nVal = val.target ? val.target.value : (val.key ? val.label : val);
        if (val.key) {
            const nItem = cloneDeep(currentItem);
            nItem[field] = Number(nVal) ? Number(nVal) : nVal;
            setCurrentItem(nItem);
        } else {
            currentItem[field] = Number(nVal) ? Number(nVal) : nVal;
        }
    }

    const saveItem = () => {
        const nItem = cloneDeep(currentItem);
        if (opened === 'bill') {
            const nBILLLlist = cloneDeep(billsList);
            const idx = findIndex(nBILLLlist, i => i.id === nItem.id);
            if (idx > -1) nBILLLlist[idx] = nItem;
            else nBILLLlist.push(nItem)
            updateBILLSList(nBILLLlist);
        } else {
            const nOFXlist = cloneDeep(ofxList);
            const idx = findIndex(nOFXlist, i => i.id === nItem.id);
            if (idx > -1) nOFXlist[idx] = nItem;
            else nOFXlist.push(nItem)
            updateOFXList(nOFXlist);
        }
        setEditing(nItem.id, false);
        setOpened(false);
    }

    const OFXCardHeader = (props) => {
        return (
            <div className='OFXData__OFXCard__Header'>
                <div className='date'>{processDate(props.item.date).toLocaleDateString('pt-BR')}</div>
                <div className='ellipsis'>{props.item.label}</div>
                <div className={`value ${props.item.value < 0 ? 'negative' : ''}`}>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.item.value)}</div>
                { blockedItems.indexOf(props.item.id) > -1 && <Button type='icon' icon='lock' onClick={ () => { setEditing(props.item.id, false) } }/> }
                { blockedItems.indexOf(props.item.id) < 0 && <ButtonDropdown icon='more-vertical' options={itemOptions} onSelect={ (option) => { handleOptions('ofx', props.item.id, option) } }/> }
            </div>
        )
    }

    const OFXCardEdit = (props) => {
        return (
            <div className='OFXData__OFXCard'>
                <div className='ModalHeader'>
                    Item OFX
                    <Button type='icon' icon='close' onClick={() => { setOpened(false); setEditing(props.item.id, false); }}/>
                </div>
                <div className='OFXData__OFXCard__Content'>
                    <div className='FieldLine'>
                        <div className='Field'>
                            <label className='caption'>Data</label>
                            <input key={`date${props.item.id}`} type='date' defaultValue={props.item.date} maxLength={65} onChange={(ev) => { editField('date', ev) }}/>
                        </div>
                        <div className='Field'>
                            <label className='caption'>Valor</label>
                            <input key={`value_${props.item.id}`} type='number' defaultValue={props.item.value} maxLength={65} onChange={(ev) => { editField('value', ev) }}/>
                        </div>
                    </div>
                    <div className='Field'>
                        <label className='caption'>Descrição</label>
                        <input key={`label_${props.item.id}`} type='text' defaultValue={props.item.label} maxLength={65} onChange={(ev) => { editField('label', ev) }}/>
                    </div>
                    <Button label='salvar' onClick={saveItem}/>
                </div>
            </div>
        )
    }

    const BILLCardHeader = (props) => {
        return (
            <div className='OFXData__BILLCard__Header'>
                <div className='date'>{processDate(props.item.date).toLocaleDateString('pt-BR')}</div>
                <Icon name={cat_icons[props.item.category]}/>
                <div className={`transfer ${props.item.value < 0 ? 'negative' : ''}`}>
                    <div className='ellipsis'>{props.item.account}</div>
                    <Icon name={props.item?.value<0 ? 'arrow-right': 'arrow-left'}/>
                    <div className='ellipsis'>{props.item.part}</div>   
                </div>
                <div className='installment'>{props.item.installment}</div>
                <div className={`value ${props.item.value < 0 ? 'negative' : ''}`}>{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.item.value)}</div>
                { blockedItems.indexOf(props.item.id) > -1 && <Button type='icon' icon='lock' onClick={ () => { setEditing(props.item.id, false) } }/> }
                { blockedItems.indexOf(props.item.id) < 0 && <ButtonDropdown icon='more-vertical' options={itemOptions} onSelect={ (option) => { handleOptions('bill', props.item.id, option) } }/> }
            </div>
        )
    }

    const getOptionsFromValue = (isNegative) => {
        if (isNegative) {
            return outcome_categories.map(c => ({ key: c.category, icon: c.icon, label: c.category }))
        } else {
            return income_categories.map(c => ({ key: c.category, icon: c.icon, label: c.category }))
        }
    }

    const accountOptions = [
        { key: 'nubank', label: 'Nubank' },
        { key: 'bradesco', label: 'Bradesco' },
    ];

    const BILLCardEdit = (props) => {
        const [isNegative, setIsNegative] = useState(props.item.value < 0);

        const updateField = (ev) => {
            setIsNegative(Number(ev.target.value) < 0);
        }

        return (
            <div className='OFXData__BILLCard'>
                <div className='ModalHeader'>
                    Parcela
                    <Button type='icon' icon='close' onClick={() => { setOpened(false); setEditing(props.item.id, false);}}/>
                </div>
                <div className='OFXData__BILLCard__Content'>
                    <div className='FieldLine'>
                        <div className='Field'>
                            <label className='caption'>Data</label>
                            <input key={`date_${props.item.id}`} type='date' defaultValue={props.item.date} maxLength={65} onChange={(ev) => { editField('date', ev) }}/>
                        </div>
                        <div className='Field'>
                            <label className='caption'>Parcela</label>
                            <input key={`installment_${props.item.id}`} type='text' defaultValue={props.item.installment} maxLength={65} onChange={(ev) => { editField('installment', ev) }}/>
                        </div>
                        <div className='Field'>
                            <label className='caption'>Valor</label>
                            <input key={`value_${props.item.id}`} type='number' defaultValue={props.item.value} maxLength={65} onChange={(ev) => { editField('value', ev); updateField(ev) }}/>
                        </div>
                    </div>
                    <div className='FieldLine'>
                        <div className='Field'>
                            <label className='caption'>Conta</label>
                            <ButtonDropdown icon={cat_icons[props.item.account] || ''} label={props.item.account || 'Escolha uma conta'} options={accountOptions} onSelect={(ev) => { editField('account', ev) }}/>
                        </div>
                        <div className='Field'>
                            <label className='caption'>Origem/Destino</label>
                            <input key={`part_${props.item.id}`} type='text' defaultValue={props.item.part} maxLength={65} onChange={(ev) => { editField('part', ev) }}/>
                        </div>
                    </div>
                    <div className='FieldLine'>
                    </div>                        
                    <div className='Field'>
                        <label className='caption'>Categoria</label>
                        <ButtonDropdown icon={cat_icons[props.item.category] || ''} label={props.item.category || 'Escolha uma categoria'} options={getOptionsFromValue(isNegative)} onSelect={(ev) => { editField('category', ev) }}/>
                    </div>
                    <Button label='salvar' onClick={saveItem}/>
                </div>
            </div>
        )
    }

    
    return (
        <div className='OFXData'>
            <ResizableContent min={400}
                    a={
                        <div className='OFXData__List scrollable'>
                            <div className='h6'>
                                OFX
                                <Button small type='text' icon='plus' label='Novo Item OFX' onClick={newOFXItem}/>
                            </div>
                            { ofxList.map(OFXItem => 
                                <OFXCardHeader key={OFXItem.id} item={OFXItem}/>
                            )}
                        </div>
                    } 
                    b={
                        <div className='OFXData__List scrollable'>
                            <div className='h6'>
                                Parcelas
                                <Button small type='text' icon='plus' label='Nova Parcela' onClick={newBILLItem}/>
                            </div>
                            { billsList.map(BILLItem => 
                                <BILLCardHeader key={BILLItem.id} item={BILLItem}/>
                            )}
                        </div>
                }/>
            {   opened && 
                <OverlayPositioned blur>
                    <div className='Card'>
                        { opened === 'ofx' && <OFXCardEdit item={currentItem}/> }
                        { opened === 'bill' && <BILLCardEdit item={currentItem}/> }
                    </div>        
                </OverlayPositioned>
            }
        </div>
    )
}

export default OFXData;