import { cloneDeep } from 'lodash';

export function translateDate(date) {
    if (Array.isArray(date)) {
        return translateWeek(date);
    } 
    return translateMonth(date);
}

export function translateWeek(week) {
    let weekS = '';      
    const dt1 = new Date(week[0]);
    const dt2 = new Date(week[1]);

    weekS += new Intl.DateTimeFormat("pt-BR", { day: 'numeric' }).format(dt1.setDate(dt1.getDate() + 1));
    weekS += '/';
    weekS += new Intl.DateTimeFormat("pt-BR", { month: 'numeric' }).format(dt1.setDate(dt1.getDate() + 1));
    weekS += ' → ';
    weekS += new Intl.DateTimeFormat("pt-BR", { day: 'numeric' }).format(dt2.setDate(dt2.getDate() + 1));
    weekS += '/';
    weekS += new Intl.DateTimeFormat("pt-BR", { month: 'numeric' }).format(dt2.setDate(dt2.getDate() + 1));     
    return weekS;
}

export function translateMonth(month) {
    const date = new Date(month);
    return new Intl.DateTimeFormat("pt-BR", { month: 'short',  year: 'numeric' }).format(date.setDate(date.getDate() + 1));
}

export function dateToKey(date) {
    if (Array.isArray(date)) return date.join('/');
    return date;
}

export function generateDates(initialDate = '01-01-2024', count = 15, period) {
    if (period === 'week') {
        return generateWeeks(initialDate, count);
    }
    return generateMonths(initialDate, count);
}

export function generateWeeks(initialDate = '01-01-2024', numberOfWeeks = 15) {
    const date = new Date(initialDate);
    const weeks = [];

    for (let index = 0; index < numberOfWeeks; index++) {
        const week = [];      
        week.push(date.toISOString().split('T')[0])
        date.setDate(date.getDate() + 6)
        week.push(date.toISOString().split('T')[0])
        date.setDate(date.getDate() + 1)
        weeks.push(week);
    }
    return weeks;
}

export function generateMonths(initialDate = '01-01-2024', numberOfMonths = 15) {
    const date = new Date(initialDate);
    const months = [];
    for (let index = 0; index < numberOfMonths; index++) {
        months.push(date.toISOString().split('T')[0])
        date.setMonth(date.getMonth() + 1)
    }
    return months;
}

export function getTranslatedDates(dates) {
    const nDates = [];
    dates.forEach(date => {        
        nDates.push(translateDate(date))
    });
    return nDates
}

export function hasDatePassed(date) {
    if (Array.isArray(date)) {
        const actualDate = new Date().getTime();
        const weekDate1 = new Date(date[0]).getTime();    
        const weekDate2 = new Date(date[1]).getTime();    
        return actualDate >= weekDate1;
    } 
    const actualDate = new Date().getTime();
    const monthDate = new Date(date).getTime();
    return actualDate >= monthDate;
}

export function orderDataByDateKey(data) {
    const nData = data;
    const ordered = Object.keys(nData)
        .sort()
        .reduce((obj, key) => {
            obj[key] = nData[key];
            return obj;
        }, {});
    return ordered;
}

export function handleData(startDate, endDate, data, period, datatype) {
    const nData = [];
    if (data) {
        data.forEach((group) => {
            const nGroup = {};
            nGroup.name = group.name;
            nGroup.data = [];
            //Week
            if (period === 'week') {
                if (group.wdata) {
                    Object.keys(orderDataByDateKey(group.wdata)).forEach((key) => {
                        const date = key.split('/');
                        const dateStart = new Date(startDate).getTime();
                        const dateEnd = new Date(endDate).getTime();
                        const weekStart = new Date(date[0]).getTime();
                        const weekEnd = new Date(date[1]).getTime();
                        if (weekStart >= dateStart && weekEnd <= dateEnd) {
                            if (group.wdata[key]) {
                                nGroup.data.push(group.wdata[key])
                            } else {
                                nGroup.data.push(0)
                            }
                        }
                    });
                    console.log(nGroup);
                    nData.push(nGroup);
                }
            } else if (period === 'month') {
                if (group.mdata) {
                    if (group.mdata) {
                        Object.keys(orderDataByDateKey(group.mdata)).forEach((key) => {

                            if (group.mdata[key]) {
                                nGroup.data.push(group.mdata[key])
                            } else {
                                nGroup.data.push(0)
                            }
                        });
                        nData.push(nGroup);
                    }
                }
            }
        });
    }
    return nData;
}

export function replaceKpiData(startDate, endDate, kpi, period) {
    const nKPI = cloneDeep(kpi);
    nKPI.data = handleData(startDate, endDate, kpi.data, period);
    return nKPI;    
}