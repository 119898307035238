import Icon from '../Icon';
import './style.sass';

function DropdownItem(props) {

    const handleClick = (option) => {
        if (props.onSelect) props.onSelect(option);
    }

    return (
        <div className={`DropdownItem ${props.vertical ? 'vertical' : ''} ${props.selected ? 'selected' : ''}`} onClick={() => { handleClick(props.option) }}>
            {props.check && <Icon name={props.checked ? 'checkbox-outline' : 'checkbox-blank-outline'}/>}
            {!props.check && props.option.icon && <Icon name={props.option.icon}/>}
            {props.option.label && <div className='DropdownItem__Label body2'>{props.option.label}</div>}
        </div>
    )
}

export default DropdownItem;
