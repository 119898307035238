import { Button, Checkbox, Divider, Icon, Loader, OverlayPositioned, Tabs } from '../../components';
import './JTBDSelector.sass';
import { useState, useRef } from 'react';
import { findIndex, cloneDeep } from 'lodash';

function JTBDOptions(props) {

    const handleJTBDChange = (jtbd) => {
        const nChecked = cloneDeep(props.checked);
        const idx = nChecked.indexOf(jtbd.id);

        if (idx > -1) {
            nChecked.splice(idx, 1);
        } else {
            nChecked.push(jtbd.id)
        }

        if (props.onChange) {
            props.onChange(nChecked);
        }
    }

    return (
        <div className={`JTBDOptions scrollable`}> 
        <div className="group">
        { props.jtbds.map(jtbd => 
            <div key={jtbd.id} className="option">
                <Checkbox checked={props.checked.indexOf(jtbd.id) > -1} onChange={ () => handleJTBDChange(jtbd) }/>
                <div className="description">{jtbd.description}</div>
            </div>
        )}
        </div>            
        </div>
    )
}

function JTBDSelector(props) {

    const [opened, setOpened] = useState(false);
    const ref = useRef();

    const handleChange = (checked) => {
        if (props.onChange) {
            props.onChange(checked);
        }
    }

    return (
        <div className={`KRSelector scrollable`} ref={ref}> 
            <div className="field" onClick={() => {setOpened(true)}}><Icon name="flag-checkered"/> {props.checked.length} jobs to be done</div>
            {
                opened && 
                    <OverlayPositioned element={ref.current} onClose={() => {setOpened(false)}}>
                        <JTBDOptions checked={props.checked} jtbds={props.jtbds} onChange={handleChange}/>
                    </OverlayPositioned>
            }   
        </div>
    )
}


export default JTBDSelector;