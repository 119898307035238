import { Icon, Tooltip } from '../../components';
import { useState } from 'react';
import './List.sass';
import catIcons from '../../data/categories_icon.json';

function List(props) {

    const getCurrency = (value) => {
        return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    }

    const catlist = [
        { cat: 'Administração', value: 0.00 },
        { cat: 'Aviação', value: 0.00 },
        { cat: 'Combustível', value: 0.00 },
        { cat: 'Comercialização', value: 0.00 },
        { cat: 'Insumos agrícolas', value: 0.00 },
        { cat: 'Insumos para máquinas', value: 0.00 },
        { cat: 'Outros custos de máquinas', value: 0.00 },
        { cat: 'Outros itens', value: 0.00 },
        { cat: 'Salários', value: 0.00 },
        { cat: 'Serviços terceirizados', value: 0.00 },
        { cat: 'Taxas', value: 0.00 },
    ];

    const getList = () => {

        let total = props.despesa;
        const lst = catlist;

        lst.forEach(i => {
            const pct = Math.random() * (0.3 - 0.01) + 0.01
            const v = total * pct;
            if (v > 0) {
                i.value = v;
                total -= v;
            }
        })


        return lst;
    }

    return (
        <div className='List'>
            
            {
                getList().map((i, idx) => {
                    return(
                        <div className="ListItem" key={idx}>
                            <div>
                                <Icon small name={catIcons[i.cat]}/>
                                <div className="body2 ellipsis">{i.cat}</div>
                            </div>
                            <div className="body2">
                                {getCurrency(i.value)}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default List;