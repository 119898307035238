import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import OFXConc from './views/OFXConc';
import App from './components/App';
import Details from './views/OFXConc/details';
import Reconciliation from './views/OFXConc/reconciliation';
import Statement from './views/OFXConc/statement';
import OFXData from './views/OFXData';
import Admin from './views/Admin';
import NewLogin2 from './views/NewLogin2';
import SimulIR from './views/SimulIR';
import SimulIRContent from './views/SimulIR/Content';
import Indicadores from './views/Indicadores';
import Roadmap from './views/Roadmap';
import NFe from './views/NFe';
import NFeHome from './views/NFe/NFeHome';
import NFeFiscal from './views/NFe/NFeFiscal';
import NFeTeam from './views/NFe/NFeTeam';
import NFeTeamManage from './views/NFe/NFeTeamManage';
import NFeTeamRoles from './views/NFe/NFeTeamRoles';

function AppStart() {

  const [count, setCount] = useState(null);

  return (
      <App>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home/>}/>
            <Route path="ofx" element={<OFXConc count={count}/>}>
              <Route index element={<Details/>}/>
              <Route path="import" element={<Reconciliation onChangeOFX={setCount}/>}/>
              <Route path="statement" element={<Statement onChangeOFX={setCount}/>}/>
            </Route>
            <Route path="login" element={<NewLogin2/>}/>
            <Route path="ir" element={<SimulIR/>}>
              <Route path="pedro" element={<SimulIRContent/>}/>
              <Route path="thiara" element={<SimulIRContent/>}/>
            </Route>
            <Route path="nfe" element={<NFe/>}>
              <Route index element={<NFeHome/>}/>
              <Route path="fiscal" element={<NFeFiscal/>}/>
              <Route path="team" element={<NFeTeam/>}>
                <Route index element={<NFeTeamManage/>}/>
                <Route path="roles" element={<NFeTeamRoles/>}/>                
              </Route>
            </Route>
            <Route path="admin" element={<Admin/>}>
              <Route path="indicadores" element={<Indicadores/>}/>
              <Route path="roadmap" element={<Roadmap/>}/>
              <Route path="ofx-data" element={<OFXData/>}/>
            </Route>
          </Routes>      
        </BrowserRouter>   
      </App>   
  );
}

export default AppStart;
