import './style.sass';

function AegroLogo(props) {

    return (
        <svg viewBox={props.glyph ? `0 0 27 37` : `0 0 112 37`} className={`AegroLogo ${props.white ? 'white' : ''}  ${props.glyph ? 'glyph' : ''}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            {
                props.glyph && 
                    <path d="M15.9975 0H2.44913V1.23507C2.44913 5.42736 4.77668 9.063 8.19851 10.9591C3.3871 13.0292 0 17.8303 0 23.4142C0 30.9116 6.06204 36.9826 13.5484 36.9826C18.134 36.9826 22.1811 34.7038 24.6303 31.2073V35.7475H27.0968V11.0983C27.0794 4.97508 22.129 0 15.9975 0ZM13.5484 34.5125C7.43425 34.5125 2.4665 29.5374 2.4665 23.4142C2.4665 17.291 7.41688 12.3333 13.5484 12.3333C19.6625 12.3333 24.6303 17.3084 24.6303 23.4316C24.6303 29.5548 19.6625 34.5125 13.5484 34.5125ZM24.6129 15.6385C22.1638 12.1594 18.1166 9.86319 13.531 9.86319C9.18859 9.86319 5.59305 6.64504 5.00248 2.47015H15.9975C20.7568 2.47015 24.6129 6.33192 24.6129 11.0983V15.6385Z"/>
                
            }
            {
                !props.glyph && 
                    [<path d="M46.0298 17.7781C44.8834 16.3342 43.2853 15.621 41.2184 15.621C39.2382 15.621 37.5533 16.3864 36.129 17.8998C34.7394 19.4132 34.0273 21.2397 34.0273 23.4316C34.0273 25.6234 34.7394 27.4847 36.129 28.9981C37.5533 30.5115 39.2382 31.2421 41.2184 31.2421C43.2853 31.2421 44.8834 30.5289 46.0298 29.0851V30.8246H49.8511V16.0385H46.0298V17.7781ZM44.8486 26.441C44.0844 27.2064 43.1116 27.5891 41.9305 27.5891C40.7494 27.5891 39.7767 27.2064 39.0124 26.441C38.2481 25.6408 37.866 24.6318 37.866 23.4316C37.866 22.2313 38.2481 21.205 39.0124 20.4396C39.7767 19.6394 40.7494 19.2567 41.9305 19.2567C43.1116 19.2567 44.0844 19.6394 44.8486 20.4396C45.6476 21.205 46.0298 22.2139 46.0298 23.4316C46.0298 24.6492 45.6476 25.6408 44.8486 26.441Z"/>,
                    <path d="M59.5435 15.6037C57.268 15.6037 55.3747 16.3517 53.933 17.8477C52.4913 19.3263 51.7444 21.1876 51.7444 23.4142C51.7444 25.6582 52.4913 27.5195 53.9678 28.9982C55.4442 30.4768 57.3896 31.2248 59.8387 31.2248C62.67 31.2248 64.8239 30.1636 66.2829 28.0588L63.2085 26.2845C62.4616 27.2586 61.3499 27.7631 59.9082 27.7631C57.6328 27.7631 56.2432 26.8411 55.7395 24.9798H66.8735C66.9603 24.4232 67.0298 23.9187 67.0298 23.4142C67.0298 21.1876 66.3177 19.3611 64.9107 17.8477C63.5038 16.3517 61.7147 15.6037 59.5435 15.6037ZM55.6874 22.04C56.139 20.1439 57.5112 19.048 59.5608 19.048C61.2978 19.048 62.7742 20.0221 63.2258 22.04H55.6874Z"/>,
                    <path d="M80.5435 17.865C79.4144 16.3516 77.8512 15.621 75.8189 15.621C73.7519 15.621 72.0149 16.369 70.5906 17.8476C69.1663 19.3263 68.4715 21.1006 68.4715 23.2054C68.4715 25.3103 69.1837 27.102 70.5732 28.598C71.9975 30.0418 73.7345 30.7898 75.8363 30.7898C77.8685 30.7898 79.4318 30.0418 80.5608 28.5458V29.9722C80.5608 32.3032 79.0844 33.6079 76.6874 33.6079C74.8636 33.6079 73.5608 32.9295 72.8139 31.5378L69.5658 33.3991C70.8685 35.7649 73.3698 37 76.5658 37C78.6849 37 80.4914 36.4085 82.0025 35.1909C83.5137 33.9732 84.278 32.2336 84.278 29.9548V16.0385H80.5608V17.865H80.5435ZM76.3921 27.2412C73.9951 27.2412 72.2581 25.5538 72.2581 23.188C72.2581 22.0399 72.6402 21.0658 73.4045 20.3178C74.2035 19.5524 75.1762 19.1697 76.3921 19.1697C77.608 19.1697 78.5807 19.5524 79.3449 20.3178C80.144 21.0658 80.5261 22.0399 80.5261 23.188C80.5435 25.5538 78.7891 27.2412 76.3921 27.2412Z"/>,
                    <path d="M90.9306 18.5783V16.0212H87.1266V30.8073H90.9306V23.7447C90.9306 22.3183 91.3822 21.3268 92.2854 20.7005C93.206 20.0743 94.2655 19.8482 95.4119 19.9873V15.7255C93.4144 15.7255 91.6427 16.6822 90.9306 18.5783Z"/>,
                    <path d="M109.725 17.8825C108.213 16.3691 106.355 15.6037 104.166 15.6037C101.978 15.6037 100.119 16.3691 98.6079 17.8825C97.0967 19.3959 96.3672 21.2224 96.3672 23.4142C96.3672 25.6061 97.1141 27.4674 98.6079 28.9808C100.102 30.4942 101.978 31.2248 104.166 31.2248C106.355 31.2248 108.213 30.4768 109.725 28.9808C111.236 27.4848 112 25.6061 112 23.4142C112 21.2224 111.236 19.3959 109.725 17.8825ZM107.032 26.3541C106.268 27.1194 105.313 27.5021 104.166 27.5021C103.02 27.5021 102.064 27.1194 101.3 26.3541C100.553 25.5887 100.171 24.6145 100.171 23.4316C100.171 22.2487 100.553 21.2746 101.3 20.5092C102.064 19.7438 103.02 19.3611 104.166 19.3611C105.313 19.3611 106.268 19.7438 107.032 20.5092C107.796 21.2746 108.179 22.2487 108.179 23.4316C108.179 24.6145 107.796 25.5887 107.032 26.3541Z"/>,
                    <path d="M15.9975 0H2.44913V1.23507C2.44913 5.42736 4.77668 9.063 8.19851 10.9591C3.3871 13.0292 0 17.8303 0 23.4142C0 30.9116 6.06204 36.9826 13.5484 36.9826C18.134 36.9826 22.1811 34.7038 24.6303 31.2073V35.7475H27.0968V11.0983C27.0794 4.97508 22.129 0 15.9975 0ZM13.5484 34.5125C7.43425 34.5125 2.4665 29.5374 2.4665 23.4142C2.4665 17.291 7.41688 12.3333 13.5484 12.3333C19.6625 12.3333 24.6303 17.3084 24.6303 23.4316C24.6303 29.5548 19.6625 34.5125 13.5484 34.5125ZM24.6129 15.6385C22.1638 12.1594 18.1166 9.86319 13.531 9.86319C9.18859 9.86319 5.59305 6.64504 5.00248 2.47015H15.9975C20.7568 2.47015 24.6129 6.33192 24.6129 11.0983V15.6385Z"/>]
            }
        </svg>

    )
}

export default AegroLogo;
