import { Button, ButtonDropdown, Divider, Dropdown, LCPDLogo } from '../../components';
import './style.sass';
import { useState } from 'react';

function LCPDTopBar(props) {

    const [currentYear, setCurrentYear] = useState({ key: '2025', label: 'Ano calendário 2025' });

    const handleSelect = (selected) => {
        setCurrentYear(selected);
        if (props.onChangeYear) {
            props.onChangeYear(selected);
        }
    }
    
    const opts = [
        { key: '2025', label: 'Ano calendário 2025' },
        { key: '2024', label: 'Ano calendário 2024' },
        { key: '2023', label: 'Ano calendário 2023' },
        { key: '2022', label: 'Ano calendário 2022' },
        { key: '2021', label: 'Ano calendário 2021' },
        { key: '2020', label: 'Ano calendário 2020' },
    ];

    return (
        <div className={`LCPDTopBar`}>
            <div className="Left">
                <LCPDLogo/>
                <Divider vertical/>
                <ButtonDropdown options={opts} selected={currentYear} label="Ano calendário" icon="chevron-down" onSelect={handleSelect}/>
            </div>
            <Button icon="help-circle-outline" label="Precisa de ajuda?"/>
        </div>
    )
}

export default LCPDTopBar;
