import { useState, useEffect } from 'react';

import { cloneDeep, find } from 'lodash';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Accordion, Button, ButtonDropdown, Dialog, Divider, Dropdown, InputText, Radio, Tooltip } from '../../components';
import DataInput from './DataInput';

import { getTranslatedDates, generateDates, handleData } from './libs'

function IndGraph(props) {    

    const initialOptions = {
        title: { text: null },
        chart: {
            className: 'HighChart',
            backgroundColor: null,
            type: props.kpi.view
        },
        plotOptions: {
            column: {
                // stacking: 'normal',
                states: {
                    // hover: {
                    //   color: '#00BE5E'
                    // }
                  }
            }
        },
        series: handleData(props.startDate, props.endDate, props.kpi.data, props.period, props.kpi.datatype),
        colorbypoint: true,
        xAxis: {
            categories: getTranslatedDates(generateDates(undefined, undefined, props.period))
        },
        tooltip: {
            borderRadius: 8,
            padding: 12,
            shared: true,
          },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: null
            }
        },
    }

    const getData = (data) => {
        const opts = cloneDeep(initialOptions);
        opts.series = data.data;
        return opts
    }

    const [options, setOptions] = useState(getData(props.kpi));
    const [chartType, setChartType] = useState(props.chartType || 'chart-bar');
    const [currentTab, setCurrentTab] = useState('settings');

    const opts = [
        { icon: 'chart-bar', key: 'column', label: 'Colunas' },
        { icon: 'chart-line', key: 'line', label: 'Linhas' },
    ];

    const opts_source = [
        { key: 'mixpanel', label: 'Mixpanel' },
        { key: 'netsuite', label: 'NetSuite' },
        { key: 'intercom', label: 'Intercom' },
        { key: 'jira', label: 'Jira' },
        { key: 'satismeter', label: 'Satismeter' },
    ];

    const dict_source = {
        mixpanel: 'Mixpanel',
        netsuite: 'NetSuite',
        intercom: 'Intercom',
        jira: 'Jira',
        satismeter: 'Satismeter',
    }

    const handleDataChange = (key, data) => {
        if (props.onChange) {
            props.onChange(key, data, props.kpi.id);
        }
    }

    const handleEvents = (event) => {
        const data = {};
        if (event === 'remove') {
            data.id = props.kpi.id;
        }
        if (event === 'edit') {
            data.id = props.kpi.id;
        }
        if (props.onEvent) {
            props.onEvent(event, data);
        }
    }

    const handleRemoveDialogChoice = (choice) => {
        if (choice) handleEvents('remove');
        setShowRemoveDialog(false);
    }

    const [showRemoveDialog, setShowRemoveDialog] = useState(false);

    const getUserInfo = () => {
        return find(props.users, u => u.key === props.kpi.owner);
    }

    return (
        <div className='IndGraph' id={props.kpi.id}>
            <div className="IndGraph__Header h4">
                {props.kpi.title || 'Sem título'}
            </div>
            <Accordion
                icon="cog"
                header={
                    <div className="IndGraph__Header">
                        <div className="caption">
                            { props.kpi.link && <a href={props.kpi.link}>{dict_source[props.kpi.source]}</a> }
                            { !props.kpi.link && <div>{dict_source[props.kpi.source]}</div> }                             
                            { props.kpi.source && props.kpi.owner && <div>•</div>}
                            <Tooltip text={props.kpi.owner}>{ getUserInfo()?.label }</Tooltip>
                        </div>
                        <Button icon="pencil" type="icon" onClick={()=>{handleEvents('edit')}}/>
                    </div>
                }>
                <div className="IndGraph__Wrapper">
                    <div className="IndGraph__Config">
                        {/* <div className="IndGraph__Config__Menu">
                            <div className={currentTab === 'settings' ? 'active' : ''}>
                                <Button type="icon" small icon="tune-vertical" onClick={ () => { setCurrentTab('settings') } }/>
                            </div>
                            <div className={currentTab === 'data' ? 'active' : ''}>
                                <Button type="icon" small icon="database" onClick={ () => { setCurrentTab('data') } }/>
                            </div>
                        </div> */}
                        <div className="IndGraph__Config__Content scrollable">
                            { currentTab === 'settings' &&
                                <div className="IndGraph__Content">
                                    <div className="IndGraph__Content__Columns">
                                        <div className="IndGraph__Content__Column">
                                            <InputText label="Título" value={props.kpi.title} onChangeValue={(ev)=>{handleDataChange('title', ev)}}/>
                                            <Dropdown selected={props.kpi.view}  label="Visualização" options={opts} onSelect={(ev)=>{handleDataChange('view', ev)}}/>
                                            <div className="RadioFamily">
                                                <label>Tipo de dado</label>
                                                <Radio checked={props.kpi.datatype === 'number'} label="Número" value="number" name={`datatype-${props.kpi.id}`} onChange={(ev)=>{handleDataChange('datatype', ev)}}/>
                                                <Radio checked={props.kpi.datatype === 'percentage'} label="Porcentagem" value="percentage" name={`datatype-${props.kpi.id}`} onChange={(ev)=>{handleDataChange('datatype', ev)}}/>
                                            </div>
                                            <div className="RadioFamily">
                                                <label>Acompanhamento</label>
                                                <Radio checked={props.kpi.period === 'week'} label="Semanal" value="week" name={`period-${props.kpi.id}`} onChange={(ev)=>{handleDataChange('period', ev)}}/>
                                                <Radio checked={props.kpi.period === 'month'} label="Mensal" value="month" name={`period-${props.kpi.id}`} onChange={(ev)=>{handleDataChange('period', ev)}}/>
                                                <Radio checked={props.kpi.period === 'both'} label="Ambos" value="both" name={`period-${props.kpi.id}`} onChange={(ev)=>{handleDataChange('period', ev)}}/>
                                            </div>
                                        </div>
                                        <div className="IndGraph__Content__Column">
                                            <Dropdown selected={props.kpi.source} options={opts_source} label="Fonte" onSelect={(ev)=>{handleDataChange('source', ev)}}/>
                                            <InputText label="Link" value={props.kpi.link} onChangeValue={(ev)=>{handleDataChange('link', ev)}}/>
                                            <Dropdown selected={props.kpi.owner}  label="Responsável" options={props.users} onSelect={(ev)=>{handleDataChange('owner', ev)}}/>
                                            <Button label="remover" onClick={()=>{setShowRemoveDialog(true)}}/>
                                        </div>
                                    </div>
                                    { 
                                        showRemoveDialog && 
                                            <Dialog
                                                message={`Deseja remover o indicador '${props.kpi.title}'?`}
                                                submessage="Esta ação é irreversível"
                                                primaryLabel="Sim"
                                                onChoice={handleRemoveDialogChoice}
                                                /> 
                                    }

                                </div>
                            }
                            {/* { currentTab === 'data' &&
                                <div className="IndGraph__Content">
                                    <div className="IndGraph__Content__Data">
                                        {
                                            getTranslatedWeeks(generateWeeks()).map((week, i) => (
                                                <DataInput key={i} label={week} value={getData(props.kpi).series[0].data[i]} onChange={(ev) => changeData(i, ev)}/>
                                            ))
                                        }
                                    </div>
                                </div>
                            } */}
                        </div>    
                    </div>
                    
                    <Divider/>
                </div>
            </Accordion>
            <div className="IndGraph__Content">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={getData(props.kpi)}
                    />
            </div>
        </div>
    )
}

export default IndGraph;