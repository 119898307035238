import './style.sass';

function TextArea(props) {

    const handleChange = (ev) => {
        if (props.onChange) props.onChange(ev.target.value)
    }

    return (
        <div className={`TextArea`}>
            <textarea disabled={props.disabled} defaultValue={props.value} maxlength={props.maxlength} onChange={handleChange}/>
            {props.maxlength && <div className="infos caption">{(props.value ? props.value.length : 0)} / {props.maxlength}</div>}
        </div>
    )
}

export default TextArea;
