import { useRef, useState } from 'react';
import { Button, DropdownItem, OverlayPositioned, Tooltip } from '..';
import './style.sass';

function ButtonDropdown(props) {

    const[opened, setOpened] = useState(false);
    const selfRef = useRef(null);

    const handleOpen = () => {
        if (!props.readOnly) setOpened(!opened);
    }

    const handleSelect = (option) => {
        if (option) {
            if (props.onSelect) props.onSelect(option);
        }    
        handleOpen();
    }

    return (
        <div className={`ButtonDropdown`} ref={selfRef}>
            <Tooltip text={props.tooltip}>
                <Button type={props.icon && !props.label ? 'icon' : 'text-grey'} icon={props.icon} label={props.selected ? props.selected.label : props.label} onClick={handleOpen}/>
            </Tooltip>
            {

                opened && 
                <OverlayPositioned element={selfRef.current} onClose={handleOpen}>
                    <div className='Card scrollable fix'>
                        {
                            props.options && props.options.length > 0 &&
                            props.options.map((option, i) => 
                                <DropdownItem key={option.key} selected={props.selected?.key === option.key} option={option} onSelect={handleSelect} />
                            ) 
                        }
                    </div>        
                </OverlayPositioned>
            }
        </div>
    )
}

export default ButtonDropdown;
