import './NFeHome.sass';
import { Button, ButtonDropdown, Checkbox, Divider, Icon, SearchBar, Tabs } from '../../components';
import { useState } from 'react';
import { AegroMenu } from '../../composed_components';

function NFeHome(props) {
   

    const [hasNFe, setHasNFe] = useState(false);

    return (
        <div className='NFeHome scrollable'>
            <Checkbox checked={hasNFe} onChange={()=>{setHasNFe(!hasNFe)}}/>
            <div className="Banners">
                {
                    !hasNFe &&
                        [
                        <div className="Full">
                            <img src="/img/fiscal3.png" alt=""/>
                            <div className="h4"></div>
                            <div className="Diferenciais">
                                <div><Icon name="check-circle"/>Pré cadastro inteligente</div>
                                <div><Icon name="check-circle"/>Emissão offline e em contigência</div>
                                <div><Icon name="check-circle"/>Automatização de Impostos</div>
                                <div><Icon name="check-circle"/>Aliquotas Automáticas</div>
                                <div><Icon name="check-circle"/>Atualizado com a legislação</div>
                            </div>
                        </div>,
                        <div className="Banner Main">
                            <div className="left">
                                <div className="h4">Emita sua primeira NFe<br/>em menos de <span>1 minuto</span></div>
                            </div>
                            <div className="right">
                                <Button label="Começar a emitir"/>
                                <div className="caption">
                                    Será necessário um certificado digital. <a href="">Saiba mais</a>
                                </div>
                            </div>
                        </div>
                        ]
                }
                {
                    hasNFe &&
                    [
                        <div className="Banner Main">
                            <div className="h4">
                                Dash
                            </div>
                        </div>,
                        <div className="Banner Tip">
                            <div className="h4">Começa a sua gestão financeira</div>
                        </div>,
                        <div className="Banner Tip">
                            <div className="h4">Tenha imagens de satélite da lavoura</div>
                        </div>,
                        <div className="Banner Tip">
                            <div className="h4">Integre a com os serviços de máquina</div>
                        </div>
                    ]
                }
            </div>
        </div>
    )
}

export default NFeHome;