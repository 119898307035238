import Button from '../Button';
import Divider from '../Divider';
import InputText from '../InputText';
import OverlayPositioned from '../OverlayPositioned/OverlayPositioned';
import './style.sass';
import { useState, useRef, useEffect, useCallback } from 'react';

function InputDate(props) {

    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    const selfRef = useRef();

    const processDate = useCallback((value) => {
        if (value && value.length === 10) {
            const dt = new Date(value+"T00:00:00");
            if (!isNaN(dt)) {
                return new Intl.DateTimeFormat('pt-BR').format(dt);
            }
        }
        return value;   
    }, []);

    const validateDate = (d) => {
        if (d && d.length === 10) {
            const dt = new Date(d);
            return !isNaN(dt)
        } else {
            return false;
        }
    }

    const unprocessDate = (value) => {
        if (value && value.length === 10) {
            const n = value.split("/").reverse().join("-");
            const dt = new Date(n);
            if (!isNaN(dt)) {
                return n;
            }
        }
        return value;
    }

    const cutDate = (longDate) => {
        return longDate.split('T')[0];
    }

    const getNow = () => {
        const d = new Date(Date.now())
        return cutDate(d.toISOString());
    }
    
    const [opened, setOpened] = useState(false);
    const [date, setDate] = useState(processDate(props.date));
    const [validDate, setValidDate] = useState(props.date || getNow());

    const changeMonth = (change) => {
        const nDate = new Date(validDate);
        nDate.setMonth(nDate.getMonth() + change)
        emitDate(nDate.toISOString().split('T')[0])
    }

    const changeYear = (change) => {
        const nDate = new Date(validDate);
        nDate.setFullYear(nDate.getFullYear() + change)
        emitDate(nDate.toISOString().split('T')[0])
    }

    const today = (date1, date2) => {
        return date1.split('T')[0] === date2.split('T')[0] ? 'today' : ''
    }

    const month = (date1, date2, what = 'month') => {
        const whats = ['year', 'month', 'day'];
        return date1.split('T')[0].split('-')[whats.indexOf(what)] === date2.split('T')[0].split('-')[whats.indexOf(what)] ? 'month' : ''
    }

    const getMonthDays = (dt) => {
        const days = [];

        const d1 = new Date(dt);
        d1.setHours(0);
        d1.setMinutes(0);
        d1.setSeconds(1)
        d1.setDate(1)
        const d2 = new Date(d1);
        const month = d1.getMonth();
        
        while (d1.getMonth() === month) {
            if (d1.getMonth() === month) {
                const dte = d1.toISOString();
                days.push({
                    date: dte,
                    day: dte.split('T')[0].split('-')[2]
                })
            }
            d1.setDate(d1.getDate() + 1);
        }

        while (d2.getDay() > 0) {
            d2.setDate(d2.getDate() - 1);
            const dte = d2.toISOString();
            days.unshift({
                date: dte,
                day: dte.split('T')[0].split('-')[2]
            })
        }
        
        while (days.length % 7 !== 0) {
            const dte = d1.toISOString();
            days.push({
                date: dte,
                day: dte.split('T')[0].split('-')[2]
            })
            d1.setDate(d1.getDate() + 1);
        }

        return days;
    }

    const validateKeys = (ev) => {
        if (!/[0-9]/.test(ev.key)) {
            ev.preventDefault();
        }
    }

    const handleChange = (ev) => {
        const val = ev.target.value.split('');
        const rslt = [];

        if (val && val.length <= 10) {
            val.forEach(char => {
                rslt.push(char);  
            });
    
            if (date && rslt.length > date.length) { // Adding
                if (rslt.length === 2 || rslt.length === 5) {
                    rslt.push('/');  
                }     
            } else { // Removing
                if (rslt.length === 3 || rslt.length === 6) {
                    rslt.splice(rslt.length - 1);  
                }     
            }
            emitDate(rslt.join(''));
        }
    }

    const emitDate = (dte) => {
        if (validateDate(unprocessDate(dte))) setValidDate(unprocessDate(dte));
        setDate(processDate(dte));
        if (props.onChangeValue) {
            props.onChangeValue({target: { value: unprocessDate(dte) }});
        }
    }

    const handleFocus = (focus, ev) => {
        if (props.onChangeFocus) {
            props.onChangeFocus(focus, ev);
        } 
    }

    useEffect(() => {
        setDate(processDate(props.date));
    }, [props.value, processDate, props.date]);

    return (
        <div className={`InputDate`} ref={selfRef}>
            <InputText 
                look={props.look}
                disabled={props.disabled} 
                readOnly={props.readonly} 
                autoFocus={props.autofocus} 
                value={date} 
                onKeyPress={validateKeys} 
                placeholder={props.placeholder} 
                label={props.label}
                required={props.required}
                description={props.description}
                onChangeValue={handleChange} 
                onFocus={(ev) => { handleFocus(true, ev) }} 
                onBlur={(ev) => { handleFocus(false, ev) }}                
                />  
            <Button type="icon" icon="calendar" onClick={()=>setOpened(true)}/>
            {
                opened && 
                    <OverlayPositioned element={selfRef.current} onClose={()=>{setOpened(false)}}>
                        <div className={`InputDateCard`}>
                            <div className={`PageSwitcher`}>
                                <div className="transition-out">
                                    <Button type="icon" icon="chevron-left" onClick={()=>{changeMonth(-1)}}/>
                                    <div>{months[new Date(validDate).getMonth()]}</div>
                                    <Button type="icon" icon="chevron-right" onClick={()=>{changeMonth(+1)}}/>
                                </div>
                                <div className="transition-out">
                                    <Button type="icon" icon="chevron-left" onClick={()=>{changeYear(-1)}}/>
                                    <div>{new Date(validDate).getFullYear()}</div>
                                    <Button type="icon" icon="chevron-right" onClick={()=>{changeYear(+1)}}/>
                                </div>
                            </div>
                            <Divider/>
                            <div className={`Month transition-out`}>
                                <div className={`Weekdays`}>
                                    <div>DOM</div>
                                    <div>SEG</div>
                                    <div>TER</div>
                                    <div>QUA</div>
                                    <div>QUI</div>
                                    <div>SEX</div>
                                    <div>SAB</div>
                                </div>
                                <div className={`Days`}>
                                    {
                                        getMonthDays(validDate).map(d => 
                                            <div key={d.date} className={`${month(validDate, d.date, 'month')} ${today(validDate, d.date)}`} onClick={()=>{emitDate(processDate(cutDate(d.date)))}}>{d.day}</div>    
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </OverlayPositioned>
            }
        </div>
    )
}

export default InputDate;
