import './auth.sass';
import { Divider, Button, AegroLogo, SocialButton, InputText, Tabs, Dropdown } from '../../components';
import { useState } from 'react';

function Auth(props) {
    
    const handleStep = (step) => {
        if (props.onStep) {
            props.onStep(step)
        }
    }

    return (
        <div className='Auth'>
            <div className="h5">Auth</div>            
            <div className="Box">
                <div className="content">
                    <Button loading={props.loading} full label="Voltar ao login" onClick={()=>{handleStep('login')}}/>
                </div>
            </div>
        </div>
    )
}

export default Auth;