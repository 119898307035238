import './style.sass';

function StatusTag(props) {

    return (
        <div className={`StatusTag ${props.status}`}>
            <span className="subtitle2">{props.children}</span>
        </div>
    )
}

export default StatusTag;
