import { Button, Checkbox, Divider, Dropdown, Icon, OverlayPositioned, Radio, Tabs } from '../../components';
import { useEffect, useState, useRef } from 'react';
import './index.sass';

import { kebabCase, cloneDeep, find, findIndex } from 'lodash';

import { deliverables, krs, JTBDs, teams } from './data';
import Deliverable from './Deliverable';
import DeliverableDetails from './DeliverableDetails';
import KRSelector from './KRSelector';
import JobsToBeDone from './JobsToBeDone';
import JTBDSelector from './JTBDSelector';


function Roadmap(props) {

    const [currenDeliverable, setCurrentDeliverable] = useState(null);

    const [vScale, setVScale] = useState(100)
    const [lengths, setLengths] = useState({
        year: 2000,
        month: 2000 / 12,
        day: (2000 / 12) / 30
    });   

    const updateLength = (scale) => {
        setLengths({
            year: scale,
            month: scale / 12,
            day: (scale / 12) / 30
        });
    };

    const getTodayPosition = () => {
        const today = new Date();
        const monthPos = (today.getMonth()) * lengths.month;
        const dayPos = (today.getDate() - 1) * lengths.day;
        return monthPos + dayPos;
    }

    const processForJTBD = (deliverables) => {
        const nJTBD = cloneDeep(JTBDs);
        nJTBD.forEach(j => {
            j.deliverables = [];
        });
        deliverables.filter(filterFunc).forEach((d) => {
            d.jtbd.forEach(jtbdId => {
                const jtbdIndex = findIndex(nJTBD, j => j.id === jtbdId);
                nJTBD[jtbdIndex].deliverables.push(d);
            });
        })
        nJTBD.forEach(j => {
            if (j.deliverables.length === 0) delete j.deliverables
        });
        return nJTBD;
    }

    const processKR = (deliverables) => {
        const nKRs = cloneDeep(krs);
        nKRs.forEach(j => {
            j.deliverables = [];
        });
        deliverables.forEach((d) => {
            d.jtbd.forEach(krId => {
                const krIndex = findIndex(nKRs, k => k.id === krId);
                nKRs[krIndex].deliverables.push(d);
            });
        })
        return nKRs;
    }

    const statusDict = {
        backlog: 'Backlog',
        production: 'Em produção',
        delivered: 'Entregue',
        canceled: 'Cancelado'
    }

    const [currentTab, setCurrentTab] = useState('deliverables');

    const tabOptions = [
        {  key: 'deliverables', name: 'Entregáveis' },
        {  key: 'jtbd', name: 'JObs To Be Done' },
        // {  key: 'kr', name: 'Key Results' },
    ];
    
    const [startedDrag, setStartedDrag] = useState(false);
    const [dragStartScroll, setDragStartScroll] = useState({ x: 0, y: 0 });
    const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });

    const startDrag = (ev) => {
        setDragStartPos({ x: ev.clientX, y: ev.clientY });
        setDragStartScroll({ x: scrollRef.current.scrollLeft, y: scrollRef.current.scrollTop });
        setStartedDrag(true)
    }

    const scrollRef = useRef();

    useEffect(() => {
        const mousemove = (ev) => {
            if (startedDrag) {
                const diffX = dragStartPos.x - ev.clientX;
                const diffY = dragStartPos.y - ev.clientY;
                scrollRef.current.scrollTop = dragStartScroll.y + diffY;
                scrollRef.current.scrollLeft = dragStartScroll.x + diffX;
            }
        };

        const mouseup = (ev) => {
            if (startedDrag) {
                setStartedDrag(false)
            }
        }

        window.addEventListener('mousemove', mousemove);
        window.addEventListener('mouseup', mouseup);

        return () => {
            window.removeEventListener('mousemove', mousemove)
            window.removeEventListener('mouseup', mouseup)
        }

    }, [startedDrag, dragStartPos.x, dragStartPos.y, dragStartScroll.x, dragStartScroll.y]);


    const [filterString, setFilterString] = useState('');
    const [filterTeam, setFilterTeam] = useState(['38y4g132786g981234', '9823457263g598276g5']);
    const [filterStatus, setFilterStatus] = useState(['backlog', 'production', 'delivered', 'canceled']);
    const [filterKRs, setFilterKRs] = useState(krs.filter(k => k.parent).map(k => k.id));
    const [filterJTBDs, setFilterJTBDs] = useState(JTBDs.map(j => j.id));

    const filterFunc = (i) => {
        const matchString = kebabCase(i.name).includes(kebabCase(filterString));
        const matchTeam = filterTeam.indexOf(i.teamId) > -1;
        const matchStatus = filterStatus.indexOf(i.status) > -1;
        const matchKRs = filterKRs.findIndex(krId => i.kr.some(kr => kr === krId)) > -1;
        // const matchJTBDs = filterJTBDs.findIndex(jtbdId => i.jtbd.some(jtbd => jtbd === jtbdId)) > -1;

        if (matchString && matchTeam && matchStatus && matchKRs) {
            return i
        } 
        return null
    }

    const handleFilterTeam = (ev) => {
        const nFilterTeam = cloneDeep(filterTeam);
        const id = ev.target.value;
        const idx = filterTeam.indexOf(id);

        if (idx > -1) {
            nFilterTeam.splice(idx, 1);
        } else {
            nFilterTeam.push(id);
        }

        setFilterTeam(nFilterTeam)
    }

    const handleFilterStatus = (status) => {
        const nFilterStatus = cloneDeep(filterStatus);
        const idx = filterStatus.indexOf(status);

        if (idx > -1) {
            nFilterStatus.splice(idx, 1);
        } else {
            nFilterStatus.push(status);
        }
        setFilterStatus(nFilterStatus)
    }

    const [filtersOpened, setFiltersOpened] = useState(false);
    const filtersRef = useRef();

    return (
        <div className='Roadmap ' ref={scrollRef}>  
            <div className="Controls">
                <div className="group horizontal">
                    <Icon small name="arrow-left-right"/>
                    <Button type="icon" icon="minus" onClick={() => { updateLength(lengths.year - 100) }}/>
                    <Button type="icon" icon="plus" onClick={() => { updateLength(lengths.year + 100) }}/>
                    <div className="value">{Math.ceil((lengths.year/2000) * 100)}%</div>
                </div>
                <div className="group vertical">
                    <Icon small name="arrow-up-down"/>
                    <Button type="icon" icon="minus" onClick={() => { setVScale(vScale - 10) }}/>
                    <Button type="icon" icon="plus" onClick={() => { setVScale(vScale + 10) }}/>
                    <div className="value">{Math.ceil((vScale/100) * 100)}%</div>
                </div>
            </div>
            <div className="Roadmap__Left">
                <div className="Header">
                    <Tabs tabs={tabOptions} current={currentTab} onChangeTab={setCurrentTab}/>
                    <div className="Header__Bottom">
                        <input type="text" value={filterString} placeholder="Pesquisar por nome de entregáveis" onInput={(ev) => {setFilterString(ev.target.value)}}/>
                        <div ref={filtersRef}><Button label="Filtros" onClick={() => { setFiltersOpened(true) }}/></div>
                        {
                            filtersOpened && 
                                <OverlayPositioned element={filtersRef.current} onClose={() => { setFiltersOpened(false) }}>
                                    <div className="FiltersWindow">
                                        <div className="group">
                                            <div className="overline">Times/Jornada</div>
                                            <div className="options horizontal">
                                                {
                                                    teams.map((t, i) => <Checkbox key={'team'+i} label={t.name} name="team" value={t.id} checked={filterTeam.indexOf(t.id) > -1} onChange={(ev) => {handleFilterTeam(ev)}}/>)
                                                }
                                            </div>
                                        </div>
                                        <Divider/>
                                        {
                                            (currentTab === 'deliverables' || currentTab === 'jtbd') &&
                                            [<div className="group">
                                                <div className="overline">KEY RESULTS</div>
                                                <div className="options">
                                                    <KRSelector krs={krs} checked={filterKRs} onChange={(checked) => { setFilterKRs(checked) }}/>
                                                </div>
                                            </div>,
                                            <Divider/>]
                                        }
                                        {
                                            (currentTab === 'deliverables' || currentTab === 'kr') &&
                                            [<div className="group">
                                                <div className="overline">Jobs To Be Done</div>
                                                <div className="options">
                                                    <JTBDSelector jtbds={JTBDs} checked={filterJTBDs} onChange={(checked) => { setFilterJTBDs(checked) }}/>
                                                </div>
                                            </div>,
                                            <Divider/>]
                                        }
                                        <div className="group">
                                            <div className="overline">Status</div>
                                            <div className="options">
                                                {
                                                    Object.keys(statusDict).map((k, i) => <Checkbox key={k+i} label={statusDict[k]} name="team" value={k} checked={filterStatus.indexOf(k) > -1} onChange={() => {handleFilterStatus(k)}}/>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </OverlayPositioned>
                        }
                    </div>
                </div>
                <div className="Items">
                    {
                        currentTab === 'jtbd' && processForJTBD(deliverables).filter(d => d.deliverables).map((item, i) => 
                            <JobsToBeDone vScale={vScale} key={`title${i}`} type="title" data={item} lengths={lengths} other={{JTBDs, krs, teams}}/>
                        )
                    }
                    {
                        currentTab === 'deliverables' && deliverables && deliverables.filter(filterFunc).map((item, i) => 
                            <Deliverable vScale={vScale} key={`title${i}`} type="title" data={item} lengths={lengths} other={{JTBDs, krs, teams}}/>
                        )
                    }
                </div>
            </div>
            <div className="Roadmap__Right">
                <div className="markers" style={{ width: lengths.year }} onMouseDown={startDrag}>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                    <div className="marker"></div>
                </div>
                <div className="Header">
                    <div className="Filters">
                    </div>
                    <div className="quarters" style={{ width: lengths.year }}>
                        <div className="quarter">
                            <div className="label">Q1 2024</div>
                            <div className="months">
                                <div>Jan</div>
                                <div>Fev</div>
                                <div>Mar</div>
                            </div>
                        </div>
                        <div className="quarter">
                            <div className="label">Q2 2024</div>
                            <div className="months">
                                <div>Abr</div>
                                <div>Mai</div>
                                <div>Jun</div>
                            </div>
                        </div>
                        <div className="quarter">
                            <div className="label">Q3 2024</div>
                            <div className="months">
                                <div>Jul</div>
                                <div>Ago</div>
                                <div>Set</div>
                            </div>
                        </div>
                        <div className="quarter">
                            <div className="label">Q4 2024</div>
                            <div className="months">
                                <div>Out</div>
                                <div>Nov</div>
                                <div>Dez</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Items">
                    {
                        currentTab === 'jtbd' && processForJTBD(deliverables).filter(d => d.deliverables).map((item, i) => 
                            <JobsToBeDone vScale={vScale} key={`item${i}`} type="gantt" data={item} lengths={lengths} other={{JTBDs, krs, teams}} onOpen={(deliverable) => {setCurrentDeliverable(deliverable)}} onStartDrag={startDrag}/>
                        )
                    }
                    {
                        currentTab === 'deliverables' && deliverables && deliverables.filter(filterFunc).map((item, i) => 
                            <Deliverable vScale={vScale} key={`item${i}`} type="gantt" data={item} lengths={lengths} other={{JTBDs, krs, teams}} onOpen={() => {setCurrentDeliverable(item)}} onStartDrag={startDrag}/>
                        )
                    }
                    <div className="TodayTracker" style={{ left: getTodayPosition() }}></div>
                </div>
            </div>
            {   currenDeliverable && 
                    <DeliverableDetails data={currenDeliverable} other={{JTBDs, krs, teams}} onClose={ () => { setCurrentDeliverable(null) } }/>
            }
        </div>
    )
}

export default Roadmap;