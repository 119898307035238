import { Button, Icon, Percent } from '../../../../components';
import './Checklist.sass'

import { useState } from 'react';

function Checklist (props) {

    const [opened, setOpened] = useState(false);

    const states = ['', 'ready',]
    const [idx, setIdx] = useState(0);

    const toggleView = (ev) => {
        if (ev.metaKey) {
            setIdx((idx + 1) % states.length)
        } else {
            if (idx === 1) {
                handleClickPreview()
            } else {
                if (!opened) setOpened(true);
            }
        }
    }

    const handleClickItem = (key) => {
        if (props.onClickItem) props.onClickItem(key);
    }

    const handleClickPreview = () => {
        if (props.onClickPreview) props.onClickPreview();
    }

    const getPct = (sections) => {
        return Math.ceil((sections.filter(i => i.done).length/sections.length)*100)
    }

    const ListItem = (props) => {
        return (
            <button className={`${props.item.done ? 'done' : ''}`} onClick={()=>{ if (!props.item.done) handleClickItem(props.item.key) }}>
                <div><Icon small name={props.item.done ? 'check' : 'circle-medium'}/>{props.item.label}</div>
                {! props.item.done && <div className="caption">Adicionar</div> }
            </button>
        )
    }

    return (
        <div className={`Checklist transition-out ${opened ? 'opened' : ''} ${states[idx]}`} onClick={toggleView}>
            <div className="Header">
                <div className="Pct transition-out">
                    <Percent hideLabels={opened} percent={getPct(props.sections)}/>
                </div>
                { opened && 
                    [<div key="1" className="Labels">
                        <div className="overline">Progresso</div>
                        <div className="caption">{getPct(props.sections)}% preenchido</div>
                    </div>,
                    <Button key="2" type="icon" icon="chevron-down" onClick={()=>{setOpened(false)}}/>
                    ]
                }
            </div>
            <div className="List transition-out">
                {
                    props.sections.map(sec => 
                        <ListItem key={sec.key} item={sec} />                    
                    )
                }
            </div>
            {/* <Button label="Conferir detalhes"/> */}
            <div className="Action" >
                <Icon name="file-eye"/>
                <div className="Label">Conferir Detalhes</div>
            </div>
        </div>
    )

}

export default Checklist;