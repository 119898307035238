import { useEffect, useRef, useState } from 'react';
import './statement.sass';
import OFXCard from './components/OFXCard';

import { cloneDeep, lowerCase } from 'lodash';
import SearchBar from '../../components/SearchBar';
import ReconciledRow from './components/ReconciledRow';
import { Divider, ButtonDropdown, Loader } from '../../components';

import { 
    getFromLocalDB, 
    saveToLocalDB, 
    getInitalFiltersForStatement, 
    processDate,
    getTotalValue,
    processStatement,
    handleReconsider
} from './DBFunctions';

function Statement(props) {
    let baseOFX = useRef(null);
    let baseBILLS = useRef(null);

    const [loaded, setLoaded] = useState(false);

    let initialOFX = useRef(null);
    let initialBILLS = useRef(null);
    let initialReconcileTable = useRef([]);
    
    const [statementList, setStatementList] = useState(null);
    const [filters, setFilters] = useState(null);

    // OnMount
    useEffect(() => {
        if (!loaded) {
            getFromLocalDB()
                .then((db) => {
                    setInitials(db);
                })
                .catch();
        }
    }, []);  

    // DB Functions
    const saveData = (bOFX, bBILLS, iOFX, iBILLS, iRTable) => {
        const db = saveToLocalDB(bOFX, bBILLS, iOFX, iBILLS, iRTable);
        if (props.onChangeOFX) props.onChangeOFX(db.initialOFX.length);
    }

    // Initialization
    const setInitials = (db) => {
        if (props.onChangeOFX) props.onChangeOFX(db.initialOFX.length); 
        // Base
        baseOFX.current = db.baseOFX;
        baseBILLS.current = db.baseBILLS;
        //Initials and States
        initialOFX.current = db.initialOFX;
        initialBILLS.current = db.initialBILLS;
        initialReconcileTable.current = db.initialReconcileTable;
        //Handle basic logic
        const stm = processStatement(initialReconcileTable.current, baseOFX.current, baseBILLS.current)
        setStatementList(stm);
        setFilters(getInitalFiltersForStatement(stm))
        applyTagFiltersToList(getInitalFiltersForStatement(stm));        
        // Set Loaded
        setLoaded(true);
    }    

    // Filter Functions
    const handleType = (string) => {
        const nReconTable = cloneDeep(initialReconcileTable.current);        
        const nSTM = processStatement(nReconTable, baseOFX.current, baseBILLS.current);
        const nRet = {};
        Object.keys(nSTM).forEach(date => {
            nRet[date] = nSTM[date].filter(s => lowerCase(JSON.stringify(s)).indexOf(lowerCase(string)) > -1);
            if (nRet[date].length < 1) delete nRet[date];
        });
        setStatementList(nRet);
    }
    const handleChangeFilters = (fltrs) => {
        setFilters(fltrs);
        applyTagFiltersToList(fltrs);
    }    
    const applyTagFiltersToList = (fltrs) => {
        const nReconTable = cloneDeep(initialReconcileTable.current);        
        const nSTM = processStatement(nReconTable, baseOFX.current, baseBILLS.current);
        const f1 = fltrs.tags[0].active; //bank-move
        const f2 = fltrs.tags[1].active // ofx-status
        const nRet = {};

        const dict = {
            true: 'income',
            false: 'expense'
        }
        if (f1 && f1.length) {
            Object.keys(nSTM).forEach(date => {
                nRet[date] = nSTM[date].filter(s => JSON.stringify(f1).indexOf(dict[getTotalValue(s) > 0]) > -1 );
                if (nRet[date].length < 1) delete nRet[date];
            });
        }
        if (f2 && f2.length) {
            Object.keys(nSTM).forEach(date => {
                nRet[date] = nSTM[date].filter(s => JSON.stringify(f2).indexOf(s.status) > -1 );
                if (nRet[date].length < 1) delete nRet[date];
            });
        } 
        if (fltrs.date) {
            const dateMin = new Date(fltrs.date.startDate).getTime();
            const dateMax = new Date(fltrs.date.endDate).getTime();

            Object.keys(nSTM).forEach(date => {
                if (new Date(date).getTime() >= dateMin && new Date(date).getTime() <= dateMax) {
                    nRet[date] = nSTM[date];
                }
            });

        }
        setStatementList(nRet);
    }

    // Selection Functions
    const reconciledOptions = [
        // { key: 'edit', label: 'Editar' },
        { key: 'unreconcile', label: 'Desconciliar' },
    ];
    const ignoredOptions = [
        { key: 'reconsider', label: 'Reconsiderar', icon: 'receipt' },
    ];
    const handleSelectReconciled = (opt, stm) => {
        if (opt.key === 'unreconcile') {
            reconsiderOFX(stm)
        }
    }
    const handleSelectIgnored = (opt, stm) => {
        if (opt.key === 'reconsider') {
            reconsiderOFX(stm)
        }
    }

    // Reconsider Functions
    const reconsiderOFX = (stm) => {
        const recon = handleReconsider(stm, initialOFX.current, initialBILLS.current, initialReconcileTable.current);
        
        initialOFX.current = recon.initialOFX;
        initialBILLS.current = recon.initialBILLS;
        initialReconcileTable.current = recon.initialReconcileTable;
        
        saveData(baseOFX.current, baseBILLS.current, recon.initialOFX, recon.initialBILLS, recon.initialReconcileTable);
        const stmLst = processStatement(recon.initialReconcileTable, baseOFX.current, baseBILLS.current);
        setStatementList(stmLst);
    }

    return (
        <div className='StatementView scrollable'>
            {
                !loaded && <div className='StatementView__Loading'><Loader/></div>
            }
            { loaded && (!initialReconcileTable.current || initialReconcileTable.current.length === 0) && 
                <div className='StatementMessage'>Não há registros</div> 
            }
            { loaded && initialReconcileTable.current && initialReconcileTable.current.length > 0 && 
                <div className='Statement'>                
                    <SearchBar filters={filters} onType={handleType} onChangeFilters={handleChangeFilters} />
                    <div className='content'>
                        { Object.keys(statementList).map((date, i) => {
                            const ret = [];
                            if (i > 0) ret.push(<Divider key={`divider-${date}`}/>);
                            ret.push(
                                <div key={date} className='Statement__Item'>
                                    <div className='Statement__Item__Date'>
                                        <div className='Statement__Item__Date__Floater'>
                                            <div className='weekday'>{ processDate(date).toLocaleString('pt-BR', { weekday: 'long' }) }</div>
                                            <div className='day'>{ processDate(date).getDate() }</div>
                                            <div className='month'>{ processDate(date).toLocaleString('pt-BR', { month: 'long' }) }</div>
                                            <div className='year'>{ processDate(date).getFullYear() }</div>
                                        </div>
                                    </div>
                                    <div className='Statement__Item__Lines'>
                                        {
                                            statementList[date].map(stm => 
                                                <div key={stm.id} className='Statement__Item__Lines__Line'>
                                                    <div className='Statement__Item__Lines__Line__OFX' style={{ opacity: stm.status === 'ignored' ? 0.5 : 1 }}>
                                                        <OFXCard readOnly key={stm.ofx.id} item={stm.ofx}/>
                                                    </div>
                                                    <div className='Statement__Item__Lines__Line__Status'>
                                                        <div className='dashedLine'></div>
                                                        <div className={`Status ${stm.status}`}>
                                                            { stm.status === 'reconciled' && <ButtonDropdown icon='link' options={reconciledOptions} onSelect={(opt) => { handleSelectReconciled(opt, stm) }}/> }    
                                                            { stm.status === 'ignored' && <ButtonDropdown icon='cancel' options={ignoredOptions} onSelect={(opt) => { handleSelectIgnored(opt, stm) }}/> }  
                                                        </div>
                                                        <div className={`dashedLine ${stm.status === 'ignored' ? 'hide' : ''}`}></div>
                                                    </div>
                                                    {
                                                        stm.bills && stm.bills.length > 0 &&
                                                        <div className='Statement__Item__Lines__Line__BILLS'>
                                                        {stm.bills.map((b, i) => {
                                                            const ret = [];
                                                            if (i > 0) ret.push(<Divider key={`divider-${b.id}`}/>);
                                                            ret.push(<ReconciledRow key={b.id} bill={b}/>);
                                                            return ret
                                                        })}
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            );
                            return ret;
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

export default Statement;