import { Button, ButtonDropdown, Divider, Icon, OverlayPositioned, Tabs } from '../../components';
import './style.sass';

function ModuleList(props) {

    const handleClose = () => {
        if (props.onClose) props.onClose();
    }

    const handleInput = (ev) => {
        if (props.onText) props.onText(ev);
    }

    return (
        <OverlayPositioned element={props.elRef} onClose={handleClose}>
            <div className="ModuleList">
                <div className="SearchBox">
                    <Icon name="magnify" small/>
                    <input type="text" placeholder="Pesquisar" onChange={handleInput}/>
                </div>
                <div className="ItemsList scrollable">
                    <div className="Wrapper">{props.children}</div>
                </div>
                {
                    props.new && 
                        [
                            <Divider key="1"/>,
                            <div key="2" className="AddButton transition-out">
                                { props.new.icon && <Icon name={props.new.icon} /> }
                                { props.new.text }
                            </div>
                        ]
                }
            </div>
        </OverlayPositioned>
    )
}

export default ModuleList;
