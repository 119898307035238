import { useRef, useState } from 'react';
import './FilterTag.sass';
import Icon from '../Icon';
import { DropdownItem, OverlayPositioned } from '..';
import { cloneDeep, remove } from 'lodash';

function FilterTag(props) {

    const selfRef = useRef(null);
    const [opened, setOpened] = useState(false);

    const handleOpen = () => {
        setOpened(!opened);
    }

    const handleSelect = (option) => {
        if (option) {

            const nActive = cloneDeep(props.filter.active);

            if (JSON.stringify(nActive).indexOf(option.key) > 1) {
                remove(nActive, o => o.key === option.key)
            } else {
                nActive.push(option);
            }

            if (props.onChangeActive) props.onChangeActive(nActive);
        }    
        //handleOpen();
    }

    const removeFilter = () => {
        if (props.onRemove) props.onRemove(props.filter)
    }

    const handleValueInput = (ev, key) => {
        const nActive = cloneDeep(props.filter.active);
        nActive[0][key] = ev.target.value;
        if (props.onChangeActive) props.onChangeActive(nActive);
    }

    const renderMoney = (value) => {
        return Intl.NumberFormat('pt-BR', {style:'currency', currency:'BRL'}).format(value);
    }

    return (
        <div className='FilterTag' ref={selfRef}>
            { props.filter.icon && <Icon small name={props.filter.icon}/> }

            <div className='FilterTag__Action' onClick={handleOpen}>
                { 
                    props.filter.active.length > 0 && 
                        <div className='FilterTag__Label ellipsis'>
                            { props.filter.type === 'multi' && <span>{props.filter.active.map(f => f.label).join(', ')}</span> }
                            { props.filter.type === 'range' && <span>de {renderMoney(props.filter.active[0].min)} até {renderMoney(props.filter.active[0].max)}</span> }
                        </div>
                }                
                
                { 
                    props.filter.active.length <= 0 && 
                        <div className='FilterTag__Label ellipsis'>
                            { props.filter.type === 'multi' && <span>{props.filter.label}</span>}
                            { props.filter.type === 'range' && <span>de {props.filter.active[0].min } até {props.filter.active[0].max}</span>}
                        </div>
                }

                <Icon small name='menu-down'/>
            </div>
            <div className='FilterTag__Action' onClick={removeFilter}><Icon small name='close-circle'/></div>
            {
                opened && 
                    <OverlayPositioned element={selfRef.current} onClose={handleOpen}>
                        <div className='Card'>
                            {
                                props.filter.type === 'multi' &&
                                props.filter.options && props.filter.options.length > 0 &&
                                props.filter.options.map((option) => 
                                    <DropdownItem check={props.filter.type === 'multi'} checked={JSON.stringify(props.filter.active).indexOf(option.key) > -1} key={option.key} option={option} onSelect={handleSelect} />
                                ) 
                            }                            
                            {
                                props.filter.type === 'range' &&
                                <div className='FilterTag__RangeOptions'>
                                    <div className='FilterTag__RangeOptions__Field'>
                                        <input type='number' min={props.filter.min} max={props.filter.max} step={props.filter.step} value={props.filter.active[0].min} onChange={ (ev) => { handleValueInput(ev, 'min') } }/>
                                    </div>
                                    <Icon name='arrow-right'/>
                                    <div className='FilterTag__RangeOptions__Field'>
                                        <input type='number' min={props.filter.min} max={props.filter.max} step={props.filter.step} value={props.filter.active[0].max} onChange={ (ev) => { handleValueInput(ev, 'max') } }/>
                                    </div>
                                </div>
                            }
                        </div>
                    </OverlayPositioned>
            }
        </div>
    )

}

export default FilterTag;
