import { useRef, useState, useCallback, useEffect } from 'react';
import Button from '../Button';
import Divider from '../Divider';
import Icon from '../Icon';
import './style.sass';
import DatePicker from '../DatePicker';
import { ButtonDropdown, FlashSwitch, Tooltip } from '..';
import { cloneDeep, findIndex, orderBy } from 'lodash';
import FilterTag from './FilterTag';

import { getPlural } from '../../views/OFXConc/DBFunctions';

function SearchBar(props) {

    let initialSize = 48;

    const [isOpened, setIsOpened] = useState(props.startOpened);
    const [size, setSize] = useState(initialSize);

    const topRef = useRef(null);
    const bottomRef = useRef(null);
    

    const setHeight = useCallback(() => {
        const header = topRef?.current?.clientHeight;
        const content = bottomRef?.current?.clientHeight;
        if (!isOpened) {
            setSize(header + 0);
        } else {
            setSize(header + 0 + content);
        }
    }, [isOpened])

    const HandleOpen = () => {
        setHeight();
        setIsOpened(!isOpened);
    }

    useEffect(() => {
        let observer = new ResizeObserver(() => {
            setHeight();
        })

        observer.observe(bottomRef.current);
        setHeight();

        return () => {
            observer.disconnect();
        }

    }, [setHeight])

    const handleChangeSmartSearchActive = (active) => {
        if (props.onChangeSmartFilter) props.onChangeSmartFilter(active)
    }

    const handleTypeFilter = (e) => {
        if (props.onType) {
            props.onType(e.target.value);
        }
    }

    const handleRemoveTagFilter = (key) => {        
        const nFilters = cloneDeep(props.filters);
        const index = findIndex(nFilters.tags, f => f.key === key);
        nFilters.tags[index].active = null;
        nFilters.by = 'user';
        nFilters.tags[index].added = null;
        if (props.onChangeFilters) props.onChangeFilters(nFilters);
    }

    const handleTagFilterSelect = (option) => {
        const filterIndex = findIndex(props.filters.tags, f => f.key === option.key);
        const nFilters = cloneDeep(props.filters);
        if (props.filters.tags[filterIndex].type === 'multi') nFilters.tags[filterIndex].active = [];
        if (props.filters.tags[filterIndex].type === 'range') nFilters.tags[filterIndex].active = [{ min: 0, max: 0 }];
        nFilters.tags[filterIndex].added = new Date().getTime();
        nFilters.by = 'user';
        if (props.onChangeFilters) props.onChangeFilters(nFilters);
    }

    const handleChangeTagActive = (key, active) => {
        const nFilters = cloneDeep(props.filters);
        const index = findIndex(nFilters.tags, f => f.key === key);
        nFilters.tags[index].active = active;
        nFilters.by = 'user';
        if (props.onChangeFilters) props.onChangeFilters(nFilters);
    }

    const handleDateChange = (startDate, endDate) => {
        const nFilters = cloneDeep(props.filters);
        nFilters.date = { startDate, endDate }
        nFilters.by = 'user';
        if (props.onChangeFilters) props.onChangeFilters(nFilters);
    }

    const orderFilters = (tagfilters) => {
        return orderBy(tagfilters, ['added'], ['asc'])
    }

    const getFilterCount = (filters) => {
        return filters.tags.filter(f => f.active !== null).length;
    }

    return (
        <div className={`SearchBar transition-out ${props.smart ? 'smart' : ''}`} style={{height:size}}>
            <div className='SearchBar__Top' ref={topRef}>
                <div className='SearchBar__Top__Field'>
                    <Icon name='magnify'/>
                    <input type='text' placeholder={props.placeholder || 'Buscar'} value={props.textSearch} onChange={handleTypeFilter}/>
                </div>
                { props.hasSmart && 
                    <Tooltip text='Busca automática'>
                        <FlashSwitch active={props.smart} onChangeActive={handleChangeSmartSearchActive}/> 
                    </Tooltip>
                }
                <Divider vertical/>
                <DatePicker view={props.filters.view} custom={props.smart} customDate={props.filters.date} onChangeDate={handleDateChange}/>
                <Divider vertical/>                
                <Button counterType={ props.smart ? '' : 'grey'} type='text-grey' icon={getFilterCount(props.filters) > 0 ? null : 'filter'} count={getFilterCount(props.filters) || null} label={getFilterCount(props.filters) > 0 ? getPlural(getFilterCount(props.filters), 'Filtro', 'Filtros') : 'Filtrar'} onClick={HandleOpen}/>
            </div>
            <div className='SearchBar__Bottom' ref={bottomRef}>
                <Button type='icon' icon="chevron-up" onClick={HandleOpen}/>
                {
                    orderFilters(props.filters.tags).map(filter => 
                        (filter.active && <FilterTag key={filter.key} filter={filter} onChangeActive={ (active) => { handleChangeTagActive(filter.key, active) } } onRemove={ () => { handleRemoveTagFilter(filter.key) }}/>) 
                    )
                } 
                {   
                    props.filters.tags.filter(f => f.active !== null).length < props.filters.tags.length &&            
                    <ButtonDropdown type='text-grey' options={props.filters.tags.filter(f => f.active === null).map(f => ({key: f.key, label: f.label, icon: f.icon}))} label='Adicionar filtro' onSelect={handleTagFilterSelect} />
                }
            </div>
        </div>
    )
}

export default SearchBar;
