import firebaseConfig from './firebaseConfig';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { 
  getAuth, 
  onAuthStateChanged, 
  signOut, 
  GoogleAuthProvider,
  signInWithPopup, 
} from 'firebase/auth';

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider(auth);
provider.setCustomParameters({
  hd: 'aegro.com.br',
});

export const database = () => getFirestore();
export const storage = () => getStorage(firebaseApp);
export const GoogleSignIn = () => signInWithPopup(auth, provider);
export const onAuthChange = (callback) => onAuthStateChanged(auth, callback);
export const isLogged = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const domain = user.email.split('@')[1];
        if (domain === 'aegro.com.br') {
          resolve(user);
        } else {
          signUserOut();
          reject(false);
        }
      } 
      else reject(false);
    });
  });
}

export const signUserOut = () => signOut(auth).catch(console.error);
