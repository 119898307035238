import { useNavigate } from 'react-router-dom';
import './style.sass';
import { Divider, Button } from '../../components';
import items from './items.json';
import { useState, useEffect } from 'react';
import { isLogged } from '../../services/firebase.core';

function Home(props) {
    
    const list = items;
    const navigate = useNavigate();

    const [checkDB, setCheckDB] = useState(true);
    const [logged, setLogged] = useState(false);

    useEffect(() => {
        isLogged()
            .then(response => {
                setLogged(true);
            })
            .catch(err => {
                setLogged(false);
            });
    });

    const Logo = () => {
        return (
            <svg className='Logo' width="247" height="134" viewBox="0 0 247 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.13667 0.578857C4.16611 0.578857 0.136673 4.6084 0.136673 9.57886V124.908C0.136673 129.878 4.16612 133.908 9.13668 133.908H237.939C242.909 133.908 246.939 129.878 246.939 124.908V9.57886C246.939 4.6084 242.909 0.578857 237.939 0.578857H9.13667ZM102.521 107.114H91.9394V39.8938H102.521V107.114ZM156.661 107.114H146.079V101.772C142.451 106.005 136.707 108.323 130.257 108.323C122.396 108.323 113.427 102.881 113.427 92.1982C113.427 80.9109 122.295 76.2751 130.257 76.2751C136.908 76.2751 142.451 78.3914 146.079 82.5234V75.2673C146.079 69.6235 141.342 66.1973 134.691 66.1973C129.249 66.1973 124.412 68.2126 120.28 72.4456L115.845 65.0886C121.489 59.6465 128.543 57.2278 136.303 57.2278C147.188 57.2278 156.661 61.7629 156.661 74.7634V107.114ZM134.489 101.067C139.024 101.067 143.56 99.3535 146.079 96.0278V88.5701C143.56 85.2444 139.024 83.5312 134.489 83.5312C128.543 83.5312 124.008 87.0583 124.008 92.2988C124.008 97.6401 128.543 101.067 134.489 101.067ZM193.557 98.9504C188.518 98.9504 183.378 96.1287 180.96 92.5005V73.1509C183.378 69.5229 188.518 66.6003 193.557 66.6003C201.922 66.6003 207.263 73.3525 207.263 82.7249C207.263 92.1982 201.922 98.9504 193.557 98.9504ZM170.378 107.114H180.96V100.462C184.688 105.3 190.332 108.323 196.58 108.323C209.077 108.323 218.248 98.8496 218.248 82.7249C218.248 67.0034 209.178 57.2278 196.58 57.2278C190.534 57.2278 184.89 59.9487 180.96 65.1895V39.8938H170.378V107.114Z" fill="#00C65E"/>
            </svg>
        )
    }

    const LabItem = (props) => {

        const isDatabasePresent = () => {
            const db = localStorage.getItem(props.item.key);
            return db;
        }

        const resetDatabase = () => {
            setCheckDB(false);
            localStorage.removeItem(props.item.key)
            setTimeout(() => {
                setCheckDB(true);
            }, 0);
        }

        return (
            <div className='LabItem'>
                {/* <div className='LabItem__Image'>
                    <img src={props.item.image} alt=''/>
                </div> */}
                <div className='LabItem__Line'>
                    <div className='h6'>{props.item.name}</div>
                    <div className='description'>
                        { props.item.description && <div className='subtitle2'>{props.item.description}</div> }
                    </div>
                    {   props.item.protected && logged &&
                        <div className='LabItem__Line__Actions'>
                            <Button label="Abrir" onClick={ () => { navigate(props.item.link) } }/>
                            {props.item.db && checkDB && <Button type='icon' icon={ isDatabasePresent() ? 'database-refresh' : 'database-outline' } onClick={resetDatabase}/>}
                        </div>
                    }
                    {   !props.item.protected &&
                        <div className='LabItem__Line__Actions'>
                            <Button label="Abrir" onClick={ () => { navigate(props.item.link) } }/>
                            {props.item.db && checkDB && <Button type='icon' icon={ isDatabasePresent() ? 'database-refresh' : 'database-outline' } onClick={resetDatabase}/>}
                        </div>
                    }
                </div>
            </div>
        )
    }
    
    return (
        <div className='Home'>
            <div>
                <Logo/>
            </div>
            <Divider/>
            <div className='LabItemsList'>
                { list.map(item => 
                    <LabItem key={item.key} item={item}/>
                )}
            </div>
        </div>
    )
}

export default Home;