import { Button, Icon } from '../../components';
import './style.sass';

function ModuleSelected(props) {

    const handleOpen = () => {
        if (props.onOpen) props.onOpen();
    }

    const handleEdit = () => {
        if (props.onEdit) props.onEdit();
    }

    const handleRemove = () => {
        if (props.onRemove) props.onRemove();
    }

    return (
        <div className={`ModuleSelected`}>
            <div className="clickable" onClick={handleOpen}></div>
            <Icon name={props.icon}/>
            <div className="left">
                <div className="body2 title">{props.title}</div>
                <div className="body2 subtitle">{props.subtitle}</div>
            </div>
            <div className="right">                
                <Button small type="icon" icon="pencil" onClick={handleEdit}/>
                <Button small type="icon" icon="close" onClick={handleRemove}/>
            </div>
        </div>
    )
}

export default ModuleSelected;
