import { useState, useRef } from 'react';
import './style.sass';
import ModuleEmptyState from '../ModuleEmptyState';
import ModuleList from '../ModuleList';
import ModuleSelected from '../ModuleSelected';
import ModuleListItem from '../ModuleListItem';

import { find } from 'lodash';

import clients from './clients';

function ClientModule(props) {

    const selfRef = useRef();
    const [opened, setOpened] = useState(false);
    const [searchText, setSearchText] = useState('');

    const type = props.issuer ? 'emitente' : 'destinatário';

    const getSelectedById = (id) => {
        return find(clients, c => c.id === id);
    }

    const handleSelect = (sel) => {
        if (props.onSelect) props.onSelect(sel);
        setOpened(false);
    }
    
    return (
        <div className={`ClientModule`} ref={selfRef}>
            { 
                !props.selected && 
                <ModuleEmptyState text={`Escolher ${type}`} onOpen={()=>{setOpened(true)}}/> 
            }
            { 
                props.selected &&
                <ModuleSelected
                    icon="account"
                    title={getSelectedById(props.selected).fantasyName || 'Nada'}
                    subtitle={getSelectedById(props.selected).doc || 'Nada'}
                    onRemove={()=>{handleSelect(null)}}
                    onOpen={()=>{setOpened(true)}}
                />
            }
            { 
                opened && 
                    <ModuleList 
                        elRef={selfRef.current} 
                        onText={(ev) => {setSearchText(ev.target.value)}}
                        onClose={()=>{setOpened(false)}}
                        new={{ icon: 'plus', text: searchText ? `Adicionar '${searchText}'` : `Adicionar novo ${type}` }}
                    >
                        <div className="overline">{type}</div>
                        {
                            clients.map((client) =>
                                <ModuleListItem key={client.id} title={client.fantasyName} subtitle={client.doc} onClick={()=>{handleSelect(client.id)}}/>
                            )
                        }

                    </ModuleList>
            }
        </div>
    )
}

export default ClientModule;
