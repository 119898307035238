import { useState, useEffect, useRef } from 'react';
import './style.sass';

function ResizableContent(props) {

    let thisEl = useRef(false);
    let isResizing = useRef(false);
    let initialSize = props.min || 0;
    let initialPos = {x:0, y:0};

    const [size, setSize] = useState(initialSize);

    const onDown = (e) => {
        isResizing.current = true;
        initialPos = {x: e.clientX, y: e.clientY};
        initialSize = size;
        e.preventDefault();
    }

    useEffect(() => {
        if (thisEl.current) {;
            setSize(props.min || thisEl.current.getBoundingClientRect().width * 0.5)
        }
        const onMove = (e) => {
            if (isResizing.current) {
                const elWidth = thisEl.current.getBoundingClientRect().width;
                const delta = { x: e.clientX - initialPos.x, y: e.clientY - initialPos.y };
                const nSize = delta.x;
                const clamp = Math.min(Math.max(nSize, props.min || elWidth * 0.1), props.max || elWidth * 0.9);
                setSize(clamp);
            }
        }
    
        const onUp = (e) => {
            isResizing.current = false;
        }
        
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onUp);
        
        return () => {
          window.removeEventListener('mousemove', onMove);
          window.removeEventListener('mouseup', onUp);
        }
    }, [initialPos.x, initialPos.y, initialSize, props.min, props.max]);

    return (
        <div className='ResizableContent' ref={thisEl}>
            <div className='ContentA' style={{width: size}}>{props.a}</div>
            <div className='Handler' onMouseDown={onDown}></div>
            <div className='ContentB'>{props.b}</div>
        </div>
    )
}

export default ResizableContent;