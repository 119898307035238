import { Button, Tabs } from '../../components';
import { find } from 'lodash';
import './Deliverable.sass';


function Deliverable(props) {

    const getTeam = (id) => {
        const team = find(props.other.teams, t => t.id === id);
        return team || { id: '', name: 'team not found' }
    }

    const getKR = (id) => {
        const kr = find(props.other.krs, k => k.id === id);
        return kr || { id: '', description: 'kr not found' }
    }

    const getJTBD = (id) => {
        const JTBD = find(props.other.JTBDs, j => j.id === id);
        return JTBD || { id: '', description: 'JTBD not found' }
    }

    const getPosition = (dateStart) => {
        const start = dateStart.split('-');
        const monthPos = (Number(start[1]) - 1) * props.lengths.month;
        const dayPos = (Number(start[2]) - 1) * props.lengths.day;
        return monthPos + dayPos;
    }

    const getWidth = (dateWindow) => {
        const start = dateWindow.start.split('-');
        const end = dateWindow.end.split('-');

        const monthStart = (Number(start[1]) - 1) * props.lengths.month;
        const dayStart = (Number(start[2]) - 1) * props.lengths.day;
        const monthEnd = (Number(end[1]) - 1) * props.lengths.month;
        const dayEnd = (Number(end[2]) - 1) * props.lengths.day;

        return (monthEnd + dayEnd) - (monthStart + dayStart);
    }

    const handleOpen = () => {
        if (props.onOpen) {
            props.onOpen();
        }
    } 

    const handleClickBg = (ev) => {
        if (props.onStartDrag) {
            props.onStartDrag(ev);
        }
    }

    const hasIntersection = (data) => {
        const up = getPosition(data.upstream.start)+getWidth(data.upstream);
        const down = getPosition(data.downstream.start)+getWidth(data.downstream);
        return down <= up;
    }

    return (
        <div className={`Deliverable ${props.type}`} style={{ height: props.vScale }}>  
            { props.type === 'title' &&
                <div className="Deliverable__Title">
                    <div className="caption">{getTeam(props.data.teamId).name}</div>
                    <div className="subtitle"><b>{props.data.name}</b></div>
                </div>
            }
            { props.type === 'gantt' && 
                <div className={`Deliverable__Gantt ${hasIntersection(props.data) ? 'intersect' : ''}`}>
                    <div className='clickBg' onMouseDown={handleClickBg}></div>
                    {
                        props.data.upstream &&
                            <div className="upstream transition-out" style={{ left: getPosition(props.data.upstream.start), width: getWidth(props.data.upstream) }} onClick={ handleOpen }>
                                <div className={`statusbar ${props.data.status}`}></div>
                                <div className="label ellipsis">{props.data.name}</div>
                                <div className="stage ellipsis">Upstream</div>
                            </div>
                    }
                    {
                        props.data.downstream &&
                            <div className="downstream transition-out" style={{ left: getPosition(props.data.downstream.start), width: getWidth(props.data.downstream) }} onClick={ handleOpen }>
                                <div className={`statusbar ${props.data.status}`}></div>
                                <div className="label ellipsis">{props.data.name}</div>
                                <div className="stage ellipsis">Downstream</div>
                            </div>
                    }
                </div>
            }
        </div>
    )
}

export default Deliverable;