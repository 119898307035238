import originalOFX from './ofx.json';
import originalBILL from './bills.json';
import { getLabData } from '../../services/docFunctions';

import uniqid from 'uniqid';
import { cloneDeep, find, findIndex, groupBy, isArray, orderBy, remove } from 'lodash';

const getFromLocalDB = () => {
    return new Promise((resolve, reject) => {
        const localDB = localStorage.getItem('conciliacao-ofx');      
        if (localDB) {
            resolve(JSON.parse(localDB));
        } else {    
            getLabData('ofx')
                .then((response) => {
                    const db = {
                        baseOFX: cloneDeep(response.ofx),
                        baseBILLS: cloneDeep(response.bills),
                        initialOFX: cloneDeep(response.ofx),
                        initialBILLS: cloneDeep(response.bills),
                        initialReconcileTable: [],
                    }
                    saveToLocalDB(db.baseOFX, db.baseBILLS, db.initialOFX, db.initialBILLS, db.initialReconcileTable);
                    resolve(db);
                })
                .catch((err) => {
                    console.log(err);
                })
            
        }        
    })
}

const saveToLocalDB = (bOFX, bBILLS, iOFX, iBILLS, iRTable) => {
    const db = {
        baseOFX: bOFX,
        baseBILLS: bBILLS,
        initialOFX: iOFX,
        initialBILLS: iBILLS,
        initialReconcileTable: iRTable,
    };
    localStorage.setItem('conciliacao-ofx', JSON.stringify(db));
    return db;
};

const resetLocalDB = () => {
    localStorage.removeItem('conciliacao-ofx');
    return getFromLocalDB();
} 

// OFX
const getSum = (item) => {
    let tot = 0;
    item.bills.map(b => tot += b.value);
    return tot;
}

const getTotalValue = (item) => {
    let total = item.value;
    if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
            total += child.value
        })
    }
    return total;
}

const getValid = (item) => {
    return getTotalValue(item) - getSum(item) === 0
}

const getSmartValueRange = (OFX) => {
    return OFX ? { min: (getTotalValue(OFX) - Math.abs(getTotalValue(OFX)*0.1)).toFixed(2), max: (getTotalValue(OFX) + Math.abs(getTotalValue(OFX)*0.1)).toFixed(2) } : null
}

const getInitalFiltersForOFX = (OFXItem) => {
    const filter = {
        by: 'user',
        view: 'monthly',
        date: {},
        tags: [
            {
                key: 'bank-account',
                label: 'Conta bancária',
                icon: 'bank',
                type: 'multi',
                added: new Date().getTime(),
                options: [
                    { key: 'nubank', label: 'Nubank' },
                    { key: 'bradesco', label: 'Bradesco' },
                ],
                active: [{ key: 'nubank', label: 'Nubank' }]
            },
            {
                key: 'value-range',
                label: 'Valor',
                icon: 'currency',
                type: 'range',
                added: new Date().getTime(),
                min: 0.00,
                max: 10000000.00,
                step: 0.01,
                active: null,
            }
        ]
    }

    if (OFXItem) {
        filter.date = { startDate: OFXItem.date, endDate: OFXItem.date };
        // filter.tags[1].active = [getSmartValueRange(OFXItem)];
    } 

    return filter;
}

const processDate = (isoDate) => {
    const sDate = isoDate.split('-');
    return new Date(sDate[0], sDate[1]-1, sDate[2]);
}

const handleReconciliation = (ignore, OFXItem, conns, bOFX, ofxLIST, iOFX, iBILLs, iRTable) => {
    const returnObject = {
        entry: null,
        OFXItem: null,
        OFXItemToFocus: null,
        connections: null,
        baseOFX: null,
        OFXList: null,
        initialOFX: null,
        initialBILLS: null,
        initialReconcileTable: null,
    }
    let newBaseOFX = cloneDeep(bOFX);
    let newOFXList = cloneDeep(ofxLIST);
    let newInitialOFX = cloneDeep(iOFX);
    let newInitialBILLS = cloneDeep(iBILLs);
    let newConnections = cloneDeep(conns);
    const currentItemIndex = findIndex(newInitialOFX, o => o.id === OFXItem.id);
    const OFXid = OFXItem.id;

    let BILLSids = [];        
    BILLSids = cloneDeep(conns)
        .filter(c => c.ofx === OFXid)
        .map(c => c.bill);

    remove(newConnections, c => c.ofx === OFXid );        

    const entry = {
        id: uniqid(),
        date: OFXItem.date,
        status: ignore ? 'ignored' : 'reconciled',
        ofx: OFXid,
        bills: ignore ? [] : BILLSids,
    }

    const newInitialReconcileTable = cloneDeep(iRTable);
    newInitialReconcileTable.push(entry);

    // Select Next Item
    if (currentItemIndex < newOFXList.length - 1) {
        returnObject.OFXItemToFocus = newOFXList[currentItemIndex + 1];
    } else {
        returnObject.OFXItemToFocus = newOFXList[currentItemIndex - 1];
    }

    if (!ignore) {
        BILLSids.forEach(billId => { 
            remove(newInitialBILLS, b => b.id === billId);
        })
    } 
    
    //Remove bills from BaseOFX
    const indexOfItemOnBase = findIndex(newBaseOFX, o => o.id === OFXid);
    newBaseOFX[indexOfItemOnBase].bills = [];
    
    remove(newInitialOFX, o => o.id === entry.ofx);
    remove(newOFXList, o => o.id === entry.ofx);
    
    returnObject.entry = entry;
    returnObject.connections = newConnections;
    returnObject.baseOFX = newBaseOFX;
    returnObject.OFXList = newOFXList;
    returnObject.initialOFX = newInitialOFX;
    returnObject.initialBILLS = newInitialBILLS;
    returnObject.initialReconcileTable = newInitialReconcileTable;

    return returnObject;
}

const handleJoining = (join, OFXItem, OFXItemTarget, conns, iOFX, bOFX) => {
    const returnObject = {
        OFXItem: null,
        OFXItemTarget: null,
        connections: null,
        baseOFX: null,
        initialOFX: null,
    }
    
    let newInitialOFX = cloneDeep(iOFX);
    let newBaseOFX = cloneDeep(bOFX);
    let nConns = cloneDeep(conns);

    let movingItem = cloneDeep(OFXItem);
    const selectedItem = cloneDeep(OFXItemTarget);

    if(join) {
        //Update Connections
        conns.forEach((c, i) => {
            if ((c.ofx) === movingItem.id) {
                nConns[i].ofx = selectedItem.id;
            }
        })

        selectedItem.bills = [...selectedItem.bills, ...movingItem.bills];
        movingItem.bills = [];

        if (movingItem.children && movingItem.children.length > 0) {
            selectedItem.children = [...selectedItem.children, ...movingItem.children];
            delete movingItem.children;
        }
        
        if (!selectedItem.children) {
            selectedItem.children = [movingItem];
        } else {
            selectedItem.children.push(movingItem);
        }
        
        const nInitialOFXIndex = findIndex(newInitialOFX, o => o.id === selectedItem.id)
        const nBaseOFXIndex = findIndex(newBaseOFX, o => o.id === selectedItem.id)

        //Save join withoutbills
        const nSelectedItem = cloneDeep(selectedItem);
        // nSelectedItem.bills = [];
        newInitialOFX[nInitialOFXIndex] = nSelectedItem;
        newBaseOFX[nBaseOFXIndex] = nSelectedItem;
        remove(newInitialOFX, o => o.id === movingItem.id);        
        remove(newBaseOFX, o => o.id === movingItem.id);
        newInitialOFX = orderBy(newInitialOFX, ['date'], ['asc']);
        newBaseOFX = orderBy(newBaseOFX, ['date'], ['asc']);

    } else {     
        const nInitialOFXIndex = findIndex(newInitialOFX, o => o.id === selectedItem.id)
        const nBaseOFXIndex = findIndex(newBaseOFX, o => o.id === selectedItem.id)
        remove(selectedItem.children, child => child.id === movingItem.id); 
        
        //Save join withoutbills
        const nSelectedItem = cloneDeep(selectedItem);
        // nSelectedItem.bills = [];      
        newInitialOFX[nInitialOFXIndex] = nSelectedItem;
        newBaseOFX[nBaseOFXIndex] = nSelectedItem;

        newInitialOFX.push(movingItem);
        newBaseOFX.push(movingItem);
        newInitialOFX = orderBy(newInitialOFX, ['date'], ['asc']);
        newBaseOFX = orderBy(newBaseOFX, ['date'], ['asc']);     
    }

    returnObject.OFXItem = movingItem;
    returnObject.OFXItemTarget = selectedItem;
    returnObject.connections = nConns;
    returnObject.baseOFX = newBaseOFX;
    returnObject.initialOFX = newInitialOFX;

    return returnObject;
}

// STATEMENT

const processStatement = (reconciled, bOFX, bBILLS) => {
    const newList = [];
    reconciled.forEach(recon => {
        const entry = cloneDeep(recon);
        const nOFX = find(bOFX, o => o.id === entry.ofx);
        const nBILLS = []
        entry.bills.forEach(billid => {
            const billItem = find(bBILLS, b => b.id === billid);
            if (billItem) nBILLS.push(billItem);
        });
        entry.ofx = nOFX;
        entry.bills = nBILLS;
        newList.push(entry);
    });
    const ret = groupBy(orderBy(newList, ['date'], ['desc']), 'date');
    return ret;
}

const getInitalFiltersForStatement = (stm) => {
    
    const fillDate = () => {
        const today = new Date(Date.now()).toISOString().split('T')[0];
        let date = { startDate: today, endDate: today };
        if (stm && Object.keys(stm).length > 0) {
            date = { startDate: Object.keys(stm)[0], endDate: Object.keys(stm)[0] }
        } 
        return date
    }
    
    const filters = {
        by: 'smart',
        view: 'monthly',
        date: fillDate(),
        tags: [
            {
                key: 'bank-move',
                label: 'Movimentação',
                icon: 'swap-horizontal',
                type: 'multi',
                added: null,
                options: [
                    { key: 'expense', label: 'Despesa' },
                    { key: 'income', label: 'Receita' },
                ],
                active: null
            },
            {
                key: 'ofx-status',
                label: 'Status do lançamento',
                icon: 'receipt',
                type: 'multi',
                added: null,
                options: [
                    { key: 'reconciled', label: 'Conciliado' },
                    { key: 'ignored', label: 'Ignorado' },
                ],
                active: null
            }
        ]
    };

    return filters;
};

const handleReconsider = (statement, iOFX, iBILLS, iRTable) => {
    const returnObject = {
        statement: null,
        initialOFX: null,
        initialBILLS: null,
        initialReconcileTable: null,
    }

    let newStatement = cloneDeep(statement);
    let newInitialOFX = cloneDeep(iOFX);
    let newInitialBills = cloneDeep(iBILLS)
    let newInitialReconcileTable = cloneDeep(iRTable)

    newInitialBills = [...newInitialBills, ...newStatement.bills];
    newInitialBills = orderBy(newInitialBills, ['date'], ['asc']);

    newStatement.ofx.bills = [];
    newInitialOFX.push(newStatement.ofx);
    newInitialOFX = orderBy(newInitialOFX, ['date'], ['asc']);
    remove(newInitialReconcileTable, r => r.id === newStatement.id);
    
    returnObject.statement = newStatement;
    returnObject.initialOFX = newInitialOFX;
    returnObject.initialBILLS = newInitialBills;
    returnObject.initialReconcileTable = newInitialReconcileTable;

    return returnObject;
}

const getPlural = (count, single, plural) => {
    return count > 1 ? plural : single;
}

export {
    getFromLocalDB,
    saveToLocalDB,
    resetLocalDB,
    getSum,
    getTotalValue,
    getValid,
    getSmartValueRange,
    getInitalFiltersForOFX,
    processDate,
    handleReconciliation,
    handleJoining,
    processStatement,
    getInitalFiltersForStatement,
    handleReconsider,
    getPlural
};