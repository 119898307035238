import './style.sass';

import { Button, Divider, Dropdown, HStack, Icon, InputCurrency, InputDate, InputText, OverlayPositioned, Radio, StructurePane, VStack } from "../../../../components"
import { ClientModule, OperationModule } from "../../../../composed_components"
import { useState, useEffect } from 'react';
import { cloneDeep, findIndex, set, get } from 'lodash';
import NFeEdits from './NFeEdits';
import Checklist from './Checklist';
import { NFeSection } from './components';
import ViewNfe from '../ViewNFe';



function NewNfe(props) {

    const [openPane, setOpenPane] = useState(false);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [previewNFe, setPreviewNFe] = useState(false);

    const handleClose = () => {
        if (props.onClose) props.onClose();
    }

    const updateNfeData = (path, val) => {
        const newNfeData = cloneDeep(props.nfeData);
        set(newNfeData, path, val);
        if (props.onChange) props.onChange(newNfeData);
        updateSections(newNfeData);
        passNfeData(newNfeData);
    }

    const passNfeData = (newNfeData) => {
        updateSections(newNfeData);
        if (props.onChange) props.onChange(newNfeData);
    }

    const initialSections = [
        { 
            key: 'issuer',
            label: 'Emitente',
            done: false,
            validations: []
        },
        { 
            key: 'recipient',
            label: 'Destinatário',
            done: false,
            validations: []
        },
        { 
            key: 'operation',
            label: 'Informações da Nota',
            done: false,
            validations: []
        },
        { 
            key: 'products',
            label: 'Produtos',
            done: false,
            validations: []
        },
        { 
            key: 'transport',
            label: 'Transporte',
            done: false,
            validations: ['method']
        },
        { 
            key: 'payment',
            label: 'Pagamento',
            done: false,
            validations: []
        },
        { 
            key: 'aditional',
            label: 'Informações Adicionais',
            done: false,
            validations: ['fixed']
        },
    ];

    const [sections, setSections] = useState(initialSections);

    const updateSections = (data) => {
        const nSections = cloneDeep(sections);
        Object.keys(data).forEach(k => {
            const idx = findIndex(nSections, i => i.key === k);
            const allKeysValidation = [];
            if (idx > -1) {
                if (nSections[idx].validations.length) {
                    nSections[idx].validations.forEach(subkey => {
                        allKeysValidation.push(!!data[k][subkey])
                    });    
                    nSections[idx].done = allKeysValidation.indexOf(false) === -1
                } else {
                    nSections[idx].done = !!data[k];
                }
            } 
        });
        setSections(nSections);
    }

    const getSectionDone = (key) => {
        const idx = findIndex(sections, i => i.key === key);
        return idx > -1 ? sections[idx].done : false;
    }

    const itemClicked = (key) => {
        setOpenPane(true);
        setCurrentEdit(key)
    }

    useEffect(() => {
        updateSections(props.nfeData);
    }, [props.nfeData]);

    const transportOptions = [
        {  key: 'none', label: 'Sem ocorrência de transporte'},
        {  key: 'cif', label: 'Contratação do frete por conta do emitente (CIF)'},
        {  key: 'fob', label: 'Contratação do frete por conta do destinatário (FOB)'},
        {  key: 'terc', label: 'Contratação do frente por conta de terceiros'},
        {  key: 'emit', label: 'Transporte próprio por conta do emitente'},
    ];

    const handleExpand = (section) => {
        setOpenPane(true);
        setCurrentEdit(section);
    }

    return (
        <StructurePane 
            pane={<NFeEdits nfeData={props.nfeData} current={currentEdit} onChange={passNfeData}/>}
            position="right" 
            opened={openPane} 
            onClose={()=>{setOpenPane(false)}}
            afterClose={()=>{setCurrentEdit(null)}}>
            <div className="NewNFe">
                <div className="Header">
                    <div className="h5">Nova emissão de NFe</div>
                    <Button type="icon" icon="close" onClick={handleClose}/>
                </div>
                <Divider/>
                <div className="NewNFe__Content scrollable">
                    <HStack spacing="spaced" style={{ maxWidth: 1024, margin: '0 auto' }}>
                        <NFeSection title="Emitente" icon="briefcase" edit="issuer" valid={getSectionDone('issuer')}>
                            <ClientModule issuer selected={props.nfeData.issuer} onSelect={(id)=>{updateNfeData('issuer', id)}}/>
                        </NFeSection>
                        <NFeSection title="Destinatário" icon="factory" edit="recipient" valid={getSectionDone('recipient')}>
                            <ClientModule selected={props.nfeData.recipient} onSelect={(id)=>{updateNfeData('recipient', id)}}/>
                        </NFeSection>                            
                    </HStack>

                    <NFeSection key="heyhy" title="Informações da Nota" icon="file-document" edit="info" expandable onExpand={handleExpand} valid={getSectionDone('info')}>
                        <VStack fillWidth spacing="spaced">
                            <HStack fillWidth spacing="spaced">
                                <OperationModule selected={props.nfeData.info.operation} onSelect={(id)=>{updateNfeData('info.operation', id)}}/>
                                <div></div>
                            </HStack>
                            <HStack fillWidth spacing="spaced">
                                <InputDate look="outline" label="Data da emissão" date={props.nfeData.info.date1} onChangeValue={(ev) => { updateNfeData('info.date1', ev.target.value) }}/>
                                <InputDate look="outline" label="Data de saída" date={props.nfeData.info.date2} onChangeValue={(ev) => { updateNfeData('info.date2', ev.target.value) }}/>

                            </HStack>
                        </VStack>
                    </NFeSection>

                    <NFeSection title="Produtos" icon="package-variant-closed" edit="products" expandable onExpand={handleExpand}>

                            <div className="NFeProductRow">
                                <div className="NFeProductRow__Fields">
                                    <Icon name="check-circle"/>
                                    <ClientModule/>
                                    <InputCurrency look="outline" label="Quantidade" value={0}/>
                                    <InputCurrency look="outline" label="Valor un. (R$)" value={0}/>
                                </div>
                            </div>
                            <div className="NFeProductRow">
                                <div className="NFeProductRow__Fields">
                                    <Icon name="check-circle"/>
                                    <ClientModule/>
                                    <InputCurrency look="outline" label="Quantidade" value={0}/>
                                    <InputCurrency look="outline" label="Valor un. (R$)" value={0}/>
                                </div>
                            </div>

                            <Button type="text" icon="plus" label="Adicionar outro produto"/>
                    </NFeSection>
                    
                    <NFeSection title="Transporte" icon="truck" edit="transport" expandable onExpand={handleExpand}>
                        <Dropdown look="outline" options={transportOptions} label="Tipo de frete" selected={props.nfeData.transport.method}/>
                    </NFeSection>
                    <NFeSection title="Pagamento" icon="credit-card-outline" edit="payment" expandable onExpand={handleExpand} valid={getSectionDone('payment')}>
                        <Radio name="pagamento" label="A vista" value="cash" checked={props.nfeData.payment.type === 'cash'} />
                        <Radio name="pagamento" label="A prazo" value="term" checked={props.nfeData.payment.type === 'term'} />
                    </NFeSection>
                    <NFeSection title="Informações Adicionais" icon="information-outline" edit="aditional" expandable onExpand={handleExpand} valid={getSectionDone('aditional')}>
                        <div>{ props.nfeData.aditional?.fixed }</div>
                        <div>{ props.nfeData.aditional?.user }</div>
                    </NFeSection>
                </div>
                <Checklist sections={sections} onClickItem={itemClicked} onClickPreview={()=>{setPreviewNFe(true)}}/>
                {
                    previewNFe && 
                        <OverlayPositioned blur>
                            <div className="OverView">
                                <ViewNfe popup nfeData={props.nfeData} onChange={passNfeData} onClose={()=>{setPreviewNFe(false)}}/>
                            </div>
                        </OverlayPositioned>
                }
            </div>
        </StructurePane>
    )
}

export default NewNfe;